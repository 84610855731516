import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateExternalSiteMutation, CreateExternalSiteMutationVariables, ExternalSiteCreateInput } from '../generated/graphql';
import { externalSiteFieldsFragment } from '../models/ExternalSite';

/**
 * Get a callback to create a Profile in the store.
 */
export function useCreateExternalSiteMutation(): [(model: ExternalSiteCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateExternalSiteMutation, CreateExternalSiteMutationVariables>(
        gql`
            mutation createExternalSiteMutation ($model: ExternalSiteCreateInput!) {
                createExternalSite(model: $model) {
                    ...externalSiteFields
                }
            }

            ${externalSiteFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ExternalSiteCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
