import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AudienceLinkFieldsFragment } from "../generated/graphql";

export const audienceLinkFieldsFragment = gql`
    fragment audienceLinkFields on AudienceLink {
        id
        audienceId
        targetId
        targetType
    }
`;


export type AudienceLink = Omit<AudienceLinkFieldsFragment, '__typename' | 'audienceLink'>;

/**
 * Default values.
 */
export const audienceLinkDefaultValues = (): Partial<AudienceLink> => ({
    id: Guid.newGuid()
});