import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { ViewDocumentViewModelQuery, ViewDocumentViewModelQueryVariables } from '../../generated/graphql';
import { documentFieldsFragment } from "../../models/Document";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { documentPartFieldsFragment } from '../../models/DocumentPart';

/**
 * Load all data needed for editing of a DocumentItem.
 * 
 * Data that is not id specific should be loaded in useEditDocumentItemSupportData instead.
 */
export function useViewDocumentViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ViewDocumentViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ViewDocumentViewModelQuery, ViewDocumentViewModelQueryVariables>(
        gql`
        query viewDocumentViewModelQuery ($id: ID) {
            model: document (id: $id) {
                ...documentFields

                blobReference {
                    id
                    url
                }

                contentsBlobReference {
                    id
                    url
                    filename
                }
            }

            documentParts: documentParts(documentId: $id) {
                ...documentPartFields
            }
        }

        ${documentFieldsFragment}
        ${documentPartFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}