import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { LinksViewModelQuery, LinksViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { tagFieldsFragment } from '../../models/Tag';
import { Guid } from 'guid-string';
import { externalSiteFieldsFragment } from '../../models/ExternalSite';

/**
 * Get a list of published news items for a particular user based on their audiences.
 * @param userId
 * @param options
 */
export function useLinksViewModel(userId: string | undefined | null, options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<LinksViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<LinksViewModelQuery, LinksViewModelQueryVariables>(
        gql`
        query linksViewModelQuery ($userId: ID!, $offset: Int, $first: Int) {
            items: externalSitesForUser (userId: $userId, offset: $offset, first: $first) {
                ...externalSiteFields

                blobReference {
                    id
                    url
                }

                tagLinks {
                    id
                    tagId
                }
            }

            tags {
                ...tagFields
            }
        }

        ${externalSiteFieldsFragment}
        ${tagFieldsFragment}
        `,
        {

            variables: {
                userId: userId ?? Guid.empty,
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<LinksViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            tags: newResults?.tags ?? [],

        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
