import { Library, library } from "@fortawesome/fontawesome-svg-core";
import { faClock as farClock, faFrown as farFrown } from "@fortawesome/free-regular-svg-icons";
import { faLink, faMasksTheater, faFile, faTags, faCheck, faNewspaper, faAngleLeft, faAngleRight, faBars, faCaretLeft, faCaretRight, faCogs, faEdit, faEnvelope, faHome, faLock, faPlus, faSave, faSearch, faSpinner, faThLarge, faThList, faTrash, faUser, faUsers, faFolderOpen, faBriefcase, faFolderTree, faKeyboard, faBus, faRoute, faPeopleRoof, faFileLines, faPerson, faBusSimple, faCircleInfo, faUserTie, faBarsProgress, faPencil, faCubes, faMagnifyingGlass, faVideo, faArrowsSpin, faAnglesUp, faCheckDouble, faLockOpen, faUpload, faDownload, faTimesCircle, faCaretDown, faEye, faCaretUp, faPhotoVideo, faChevronRight, faClipboardUser, faDesktop, faFilter, faGauge } from "@fortawesome/free-solid-svg-icons";


// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faMasksTheater,
        faCheck,
        faFile,
        faLink,
        faNewspaper,
        faTags,
        faSpinner,
        faBars,
        faCaretLeft,
        faCaretRight,
        faCaretDown,
        faCaretUp,
        faHome,
        faCogs,
        faUser,
        faUserTie,
        faUsers,
        faAngleLeft,
        faAngleRight,
        faSearch,
        faPlus,
        faThList,
        faThLarge,
        faLock,
        faEdit,
        faTrash,
        faSave,
        faEnvelope,
        faFolderOpen,
        faBriefcase,
        faFolderTree,
        faKeyboard,
        faBus,
        faRoute,
        faPeopleRoof,
        faFileLines, 
        faPerson,
        faBusSimple,
        faCircleInfo,
        faBarsProgress,
        faPencil,
        faCubes,
        faMagnifyingGlass,
        faVideo,
        faArrowsSpin,
        faAnglesUp,
        faEye,
        faCheckDouble,
        faLockOpen,
        faUpload,
        faDownload,
        faTimesCircle,
        faPhotoVideo,
        faChevronRight,
        faClipboardUser,
        faDesktop,
        faFilter,
        faGauge,
        
        // regular
        farFrown,
        farClock,
    );
};
