import * as React from 'react';
import gql from "graphql-tag";
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../shared/abstractStore";
import { FormQuery, FormQueryVariables } from "../generated/graphql";
import { formFieldsFragment } from "../models/Form";
import { useLazyQuery } from '@apollo/client';

export function useForm(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<FormQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<FormQuery, FormQueryVariables>(
        gql`
        query formQuery ($id: ID) {
            model: form(id: $id) {
                ...formFields
            }
        }

        ${formFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}