import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateAudienceLinkMutation, CreateAudienceLinkMutationVariables, AudienceLinkCreateInput } from '../generated/graphql';
import { audienceLinkFieldsFragment } from '../models/AudienceLink';

/**
 * Get a callback to create a Profile in the store.
 */
export function useCreateAudienceLinkMutation(): [(model: AudienceLinkCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateAudienceLinkMutation, CreateAudienceLinkMutationVariables>(
        gql`
            mutation createAudienceLinkMutation ($model: AudienceLinkCreateInput!) {
                createAudienceLink(model: $model) {
                    ...audienceLinkFields
                }
            }

            ${audienceLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: AudienceLinkCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
