import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { EditDocumentViewModelQuery, EditDocumentViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { documentFieldsFragment } from '../../models/Document';
import { documentPartFieldsFragment } from '../../models/DocumentPart'
import { Guid } from 'guid-string';
import { audienceLinkFieldsFragment } from '../../models/AudienceLink';
import { tagLinkFieldsFragment } from '../../models/TagLink';

/**
 * Get a document and its parts from the store
 * @param id
 * @param options
 */
export function useEditDocumentViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditDocumentViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch}] = useLazyQuery<EditDocumentViewModelQuery, EditDocumentViewModelQueryVariables>(
        gql`
        query editDocumentViewModelQuery ($id: ID!) {
            model: document (id: $id) {
                ...documentFields
            }

            documentParts: documentParts(documentId: $id) {
                ...documentPartFields
            }

            audienceLinks (targetId: $id) {
                ...audienceLinkFields
            }

            tagLinks (targetId: $id) {
                ...tagLinkFields
            }
        }

        ${documentFieldsFragment}
        ${documentPartFieldsFragment}
        ${audienceLinkFieldsFragment}
        ${tagLinkFieldsFragment}
        `,
        {

            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
