import { ScrollTo } from "@scottbamford/react-scrollto";
import { useMemo } from "react";
import { DocumentPart } from "../../../api/main/models/DocumentPart";
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface DocumentPartSectionProps {
    model: DocumentPart,
    allDocumentParts: Array<DocumentPart>,
    currentDepth?: number,
    scrollToDocumentPartId?: string,
}

/**
 * Component for displaying a section within a document, made up from a DocumentPart that is passed as our model.
 * 
 * NOTE This is not a recursive component, it does not display children of the document part.
 */
export const DocumentPartSection = (props: DocumentPartSectionProps) => {
    const {
        model,
        allDocumentParts,
        currentDepth = 0,
        scrollToDocumentPartId,
    } = props;

    // Get my children.
    const myChildParts = useMemo(() => allDocumentParts.filter(it => it.parentDocumentPartId === model.id), [model, allDocumentParts]);

    // Get the right header level for our depth.
    // NOTE we max out at h6.
    const heading = useMemo(() => {
        // If we have no heading text, return nothing.
        if (!model.heading) {
            return null;
        }

        // Return the best <h2> to <h6> tag with the heading for the current depth.
        switch (currentDepth) {
            case 0:
                return (<h2>{model.heading}</h2>);
            case 1:
                return (<h3>{model.heading}</h3>);
            case 2:
                return (<h4>{model.heading}</h4>);
            case 3:
                return (<h5>{model.heading}</h5>);
            case 4:
            default:
                return (<h6>{model.heading}</h6>);
        }
    }, [model.heading, currentDepth]);

    return (
        <ScrollTo shouldScrollTo={model.id === scrollToDocumentPartId} scrollMargin={60}>
            <div>
                {heading}
                <HtmlDisplay html={model.contentsHtml} />

                {/* Render recursivly */}
                {
                    myChildParts.map((item => (
                        <DocumentPartSection
                            key={item.id}
                            model={item}
                            allDocumentParts={allDocumentParts}
                            currentDepth={currentDepth + 1}
                            scrollToDocumentPartId={scrollToDocumentPartId}
                            />
                        )))
                }
            </div>
        </ScrollTo>
    );
};

export interface TableOfContentsEntryProps {
    model: DocumentPart,
    allDocumentParts: Array<DocumentPart>,
    currentDepth: number,
    maxDepth: number,
}
