import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateDocumentMutation } from './useUpdateDocumentMutation';
import { Document } from '../models/Document';
import { useCreateDocumentMutation } from './useCreateDocumentMutation';
import { DocumentCreateInput, DocumentUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Document in the store using either a create or update.
 */
export function useSaveDocumentMutation(options: SaveInStoreOptions<Document, string> = {}) {
    const [_create, createStatus] = useCreateDocumentMutation();
    const create = React.useCallback((model: Partial<Document>) => _create(model as DocumentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateDocumentMutation();
    const update = React.useCallback((id: string, changes: Partial<Document>) => _update(id, changes as DocumentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
