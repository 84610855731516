import { RouteEntry } from "../../shared/routes";
import { Login } from "./Login";
import { Register } from "./Register";
import { ExternalLoginCallback } from "./ExternalLoginCallback";
import { ConfirmEmail } from "./ConfirmEmail";
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from "./ResetPassword";
import { ConfirmEmailChange } from "./ConfirmEmailChange";
import { ConfirmInvite } from "./ConfirmInvite";
import { Saml2Login } from "./saml2/Saml2Login";
import { Saml2Complete } from "./saml2/Saml2Complete";

export const accountRoutes: Array<RouteEntry> = [
    { path: '/account/login', element: <Login /> },
    { path: '/account/register', element: <Register /> },
    { path: '/account/externalLogin', element: <ExternalLoginCallback /> },
    { path: '/account/forgotPassword', element: <ForgotPassword />},
    { path: '/account/resetPassword', element: <ResetPassword /> },
    { path: '/account/confirmEmail', element: <ConfirmEmail /> },
    { path: '/account/confirmEmailChange', element: <ConfirmEmailChange /> },
    { path: '/account/confirmInvite', element: <ConfirmInvite /> },
    { path: '/account/saml2-login', element: <Saml2Login /> },
    { path: '/account/saml2-complete', element: <Saml2Complete /> },
];
