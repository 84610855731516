/**
 * Generate a pretty URL for content URLS.
 * 
 * These are basicly in the format: [prefix]/[friendly-name]/id
 * For example: news/the-frog-fell-off-a-log/[guid]
 * The routing understands these URLs and ignores the friendly-name element but it looks better to the user and for any
 * SSO.
 */
export function generatePrettyUrl(prefix: string, model: { id: string, name: string }): string {
    // Generate a friendly name using only A-z and 0-9, replacing everything else with a '-'.
    let friendlyName = model.name.replace(/[^A-z0-9]/g, '-');
    if (!friendlyName) {
        friendlyName = '-';
    }

    // Lets always user lowercase in our friendly names.
    friendlyName = friendlyName.toLowerCase();

    const ret = `${prefix}${friendlyName}/${model.id}`;
    return ret;
}