import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useExternalSite } from "../../api/main/externalSites/useExternalSite";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import "./viewIFrameLink.scss";

/**
 * Page for viewing an external link in an embedded iFrame.
 */
export const ViewIFrameLink = () => {
    const { id } = useParams<{ id: string | undefined }>();

    // Load all data.
    const {
        data: {
            model,
        },
        errors: loadErrors,
    } = useExternalSite(id);

    // Full URL.
    const fullUrl = useMemo(() => {
        const url = model?.url;

        if (!url) {
            return '';
        }

        if (url.indexOf('://') !== -1) {
            return url;
        }

        return `http://${url}`;
    }, [model?.url]);

    // If we don't have a URL yet, just show a loading dot.
    if (!fullUrl) {
        return (
            <>
                <AlertOnErrors errors={[loadErrors,]} />
                <LoadingIndicator fullWidth />
            </>
        );
    }

    // Return a full screen iframe.
    return (
        <div className="view-iframe-link">
            <AlertOnErrors errors={[loadErrors,]} />
            <iframe src={fullUrl} width="100%" height="100%" title={model?.name ?? ''} ></iframe>
        </div>
    );
};