import * as React from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, ButtonGroup, FormText, InputGroup, Input } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useEditFormViewModel } from '../../../api/main/forms/viewModels/useEditFormViewModel';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { Form as FormModel, formDefaultValues } from '../../../api/main/models/Form';
import { useSaveFormMutation } from '../../../api/main/forms/useSaveFormMutation';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { TagLink } from '../../../api/main/models/TagLink';
import { useCreateTagLinkMutation } from '../../../api/main/tagLinks/useCreateTagLinkMutation';
import { useDeleteTagLinkMutation } from '../../../api/main/tagLinks/useDeleteTagLinkMutation';
import { AudienceLink } from '../../../api/main/models/AudienceLink';
import { useCreateAudienceLinkMutation } from '../../../api/main/audienceLinks/useCreateAudienceLinkMutation';
import { useDeleteAudienceLinkMutation } from '../../../api/main/audienceLinks/useDeleteAudienceLinkMutation';
import { PublishDateHandlerComponent } from '../../shared/publishDateHandler/PublishDateHandlerComponent';
import { useEditFormSupportingData } from '../../../api/main/forms/viewModels/useEditFormSupportingData';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { AudienceTagSelector } from '../../audiences/AudienceTagSelector';
import { Guid } from 'guid-string';
import { TagTagSelector } from '../../tags/TagTagSelector';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { DisplayOrderGroups, displayOrderGroupsDisplayName } from '../../../api/main/models/codeOnly/DisplayOrderGroups';
import './editForm.scss';
export interface EditFormProps {
	isCreate?: boolean,
	onCreateDefaultValues?: () => Partial<FormModel>

}

/**
 * Component to create an Form.
 */
export const CreateForm = (props: EditFormProps) => (<EditForm isCreate={true} {...props} />);

/**
 * Component to edit an Form.
 */
export const EditForm = (props: EditFormProps) => {
	const {
		isCreate,
		onCreateDefaultValues,
	} = props

	const { t } = useTranslation();
	const { id } = useParams<{ id: string | undefined }>();

	// Load all data.
	const {
		data: {
			model: storeModel,
			audienceLinks,
			tagLinks,
		},
		errors: loadErrors, isLoading: _isLoading,
	} = useEditFormViewModel(id, {
		onCompleted: ((data: any) => {
			const categories: string = data?.model?.categories ?? '';
			const newCategoryArray = categories
				.split(';')
				.map(x => x.trim())
				.filter(x => !!x);
			setCategoryArray(newCategoryArray);
		})
	});
	const {
		data: {
			audiences,
			tags,
		},
		errors: supportingDataErrors, isLoading: isLoadingSupportingData,
	} = useEditFormSupportingData();


	// Model (Form)
	const isLoading = _isLoading || isLoadingSupportingData;
	const { model, change, changes } = useChanges(storeModel, isCreate ? { ...formDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
	const [saveForm, { errors: saveErrors }] = useSaveFormMutation();

	// Tags.
	const tagLinksManager = useChangesArray<TagLink, string>(tagLinks, i => i.id);
	const [createTagLink, { errors: saveTagLinkErrors, }] = useCreateTagLinkMutation();
	const [deleteTagLink, { errors: deleteTagLinkErrors, }] = useDeleteTagLinkMutation();

	// Audiences.
	const audienceLinksManager = useChangesArray<AudienceLink, string>(audienceLinks, item => item.id);
	const [createAudienceLink, { errors: saveAudienceLinkErrors, }] = useCreateAudienceLinkMutation();
	const [deleteAudienceLink, { errors: deleteAudienceLinkErrors, }] = useDeleteAudienceLinkMutation();

	const navigate = useNavigate();

	//Flag to indicate we are adding a new category
	const [isCreatingCategory, setIsCreatingCategory] = React.useState<boolean>(false);
	const [newCategoryString, setNewCategoryString] = React.useState<string>("");
	const [categoryArray, setCategoryArray] = React.useState<Array<string>>([]);

	const addNewCategory = React.useCallback((str: string) => {
		setNewCategoryString('');
		setIsCreatingCategory(false);

		if (!str) {
			return;
		}
		let newCategoryArray = [...categoryArray, str];
		setCategoryArray(newCategoryArray);

	}, [setNewCategoryString, setIsCreatingCategory, categoryArray, setCategoryArray]);

	const removeCategory = React.useCallback((index: number) => {
		let results = categoryArray.filter((item, i) => i !== index);
		setCategoryArray(results);
	}, [categoryArray, setCategoryArray]);

	// Image uploading.
	const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.blobReferenceId);
	const [onUpload, { errors: imageUploadErrors, isExecuting: isUploadingImage, }] = useAsyncCallback(async (files: FileList | null) => {
		if (!files) {
			return;
		}

		let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
		let result = await uploadService.upload(files);

		if (!!result) {
			change({ blobReferenceId: result.id });
		}
	}, [change]);
	const [clearImage, { isExecuting: isClearingImage, }] = useAsyncCallback(async () => {
		change({ blobReferenceId: null });
	}, [change]);

	// Main model validation.
	const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			name: () => !model?.name ? t('editForm.errors.name', 'Name cannot be empty') : '',
		};
		validation.checkRules(rules, fieldsToCheck);
	}, [model]);

	// Save everything.
	const [saveFormCallback, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
		if (!model) {
			return;
		}
		if (!validate()) {
			return;
		}

		// Save the main model.
		const categoryString = categoryArray.length > 0 ? categoryArray.reduce((previous, current) => `${previous}; ${current}`) : '';
		await saveForm(model.id, { ...changes, categories: categoryString }, isCreate ?? false);

		for (let tagLink of tagLinksManager.added) createTagLink(tagLink);
		for (let tagLink of tagLinksManager.removed) deleteTagLink(tagLink.id);
		tagLinksManager.markAsSaved();

		for (let audienceLink of audienceLinksManager.removed) deleteAudienceLink(audienceLink.id);
		for (let audienceLink of audienceLinksManager.added) createAudienceLink(audienceLink);
		audienceLinksManager.markAsSaved();

		navigate(-1)

	}, [validate, saveForm, model, changes, isCreate, id, tagLinksManager, createTagLink, deleteTagLink, audienceLinksManager, createAudienceLink, deleteAudienceLink, categoryArray]);

	return (
		<>
			<Banner fluid>
				<StickyToolbar>
					<Row>
						<Col>
							<h1>
								{
									isCreate ? t('editForm.createHeading.default', 'Add form')
										: t('editForm.editHeading.default', 'Edit form')
								}
							</h1>
							<h6>{model?.name}</h6>
						</Col>
						<ConditionalFragment showIf={isLoading}>
							<Col xs="auto">
								<LoadingIndicator size="sm" />
							</Col>
						</ConditionalFragment>
						<Col xs="auto">
							<FormButtons top>
								<ConditionalFragment showIf={!isLoading}>
									<ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveFormCallback()}
										executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
										<FontAwesomeIcon icon="save" />
										<> {t('common.save', 'Save')}</>
									</ButtonAsync>
								</ConditionalFragment>

								<Button type="button" color="primary" outline onClick={e => navigate(-1)}>
									{t('common.cancel', 'Cancel')}
								</Button>
							</FormButtons>
						</Col>
					</Row>
				</StickyToolbar>
			</Banner>
			<MainContainer fluid>
				<AlertOnErrors errors={[
					loadErrors, supportingDataErrors,
					saveFormErrors, saveErrors,
					saveAudienceLinkErrors, deleteAudienceLinkErrors,
					saveTagLinkErrors, deleteTagLinkErrors,
					imageUploadErrors, imageLoadErrors,
				]} />

				<Form onSubmit={e => { e.preventDefault(); saveFormCallback(); }}>
					<Row>
						<Col>
							<FormGroup>
								<Label for="name">{t('editForm.name.label', 'Name')}</Label>
								<ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
							</FormGroup>
						</Col>
						<Col xs={12} md="auto">
							<PublishDateHandlerComponent
								model={model}
								change={change}
							/>
						</Col>
					</Row>

					<Row>

						<Col>
							<FormGroup>
								<Label>{t('editForm.audiencesLabel', 'Available for audiences')}</Label>
								<AudienceTagSelector
									input
									tags={audiences}
									isSelected={tag => !!audienceLinksManager.model.find(it => it.audienceId === tag.id)}
									toggle={tag => {
										let existing = audienceLinksManager.model.find(item => item.audienceId === tag.id);
										if (existing) {
											audienceLinksManager.removeFor(existing.id);
										} else {
											audienceLinksManager.addFor({ id: Guid.newGuid(), audienceId: tag.id, targetId: model.id, targetType: 'Form', });
										}
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>{t('editForm.tagsLabel', 'Tags')}</Label>
								<TagTagSelector
									input
									tags={tags}
									isSelected={tag => !!tagLinksManager.model.find(it => it.tagId === tag.id)}
									toggle={tag => {
										let existing = tagLinksManager.model.find(item => item.tagId === tag.id);
										if (existing) {
											tagLinksManager.removeFor(existing.id);
										} else {
											tagLinksManager.addFor({ id: Guid.newGuid(), tagId: tag.id, targetId: model.id, targetType: 'Form', });
										}
									}}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<FormGroup>
								<Label>{t('editForm.categories.label', 'Categories')}</Label>
								{model?.categories === undefined ? null :
									<>
										<Row>
											<Col>
												<ConditionalFragment showIf={!!categoryArray && categoryArray.length > 0}>
													<div className="categories">
														{
															categoryArray
																.map((item, index) => {
																	return (
																		<Button className="category-item" size="sm" color="success" type="button" style={{ position: "relative" }} onClick={(e) => removeCategory(index)}>
																			{item}
																			<div style={{ position: 'absolute', right: 0, top: 0, fontSize: 10 }}>
																				<FontAwesomeIcon icon="trash" />
																			</div>
																		</Button>
																	);
																})
														}
													</div>
												</ConditionalFragment>
											</Col>
										</Row>

										{
											!isCreatingCategory ?
												<Row>
													<Col>
														<Button type="button" color="success" onClick={(e) => setIsCreatingCategory(true)}>
															<FontAwesomeIcon icon="plus" />
														</Button>
													</Col>

												</Row> :
												<Row>
													<Col>
														<InputGroup>
															<Input autoFocus={true} value={newCategoryString} onChange={(e) => setNewCategoryString(e.currentTarget.value)} onKeyPress={(e) => { if (e.key === "Enter") addNewCategory(newCategoryString); }} />
															<Button color="success" type="button" onClick={(e) => addNewCategory(newCategoryString)}>
																<FontAwesomeIcon icon="check" />
															</Button>
														</InputGroup>
													</Col>
													<Col></Col>
												</Row>
										}
									</>
								}
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} md={4}>
							<FormGroup>
								<Label>{t('editForm.emailAddressLabel1', 'Send emails to')}</Label>
								<Input value={model?.emailAddress1 ?? ''} onChange={(e => change({ emailAddress1: e.currentTarget.value }))} />
							</FormGroup>
						</Col>
						<Col xs={12} sm={12} md={4}>
							<FormGroup>
								<Label>{t('editForm.emailAddressLabel2', 'Additional email 1')}</Label>
								<Input value={model?.emailAddress2 ?? ''} onChange={(e => change({ emailAddress2: e.currentTarget.value }))} />
							</FormGroup>
						</Col>
						<Col xs={12} sm={12} md={4}>
							<FormGroup>
								<Label>{t('editForm.emailAddressLabel3', 'Additional email 2')}</Label>
								<Input value={model?.emailAddress3 ?? ''} type="email" onChange={(e => change({ emailAddress3: e.currentTarget.value }))} />
							</FormGroup>
						</Col>
					</Row>

					{/*<FormGroup>*/}
					{/*    <Label for="url">{t('editForm.url.label', 'URL')}</Label>*/}
					{/*    <ValidatedInput name="url" type="url"*/}
					{/*        placeholder={t('common.urlPlaceholder', 'https://')}*/}
					{/*        value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} onBlur={e => validate('url')} validationErrors={validationErrors['url']} />*/}
					{/*</FormGroup>*/}

					<Row>

						<Col>
							<FormGroup>
								<Label for="displayOrder">{t('editForm.displayOrder.label', 'Priority group')}</Label>
								<ValidatedInput name="displayOrder" type="select" value={model?.displayOrder ?? ""} onChange={e => change({ displayOrder: parseInt(e.currentTarget.value) })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']}>
									{
										Object.keys(DisplayOrderGroups).map(key => {
											const value = DisplayOrderGroups[key as keyof typeof DisplayOrderGroups]
											return (
												<option key={key} value={value}>
													{displayOrderGroupsDisplayName(value, t)}
												</option>
											);
										})
									}
								</ValidatedInput>
							</FormGroup>
						</Col>
					</Row>

					<FormGroup>
						<Label for="name">{t('editForm.image.label', 'Thumbnail image')}</Label>
						<UploadedImagePreview size="lg" src={image?.url ?? ''} />
						<Row>
							<Col>
								<ButtonGroup>
									<FileUploadButton
										color={`primary`}
										isExecuting={isUploadingImage}
										executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}
										onUpload={onUpload}
										outline={false}>
										{t('editForm.uploadButtonText', 'Upload an image')}
									</FileUploadButton>
									<ButtonAsync color="primary"
										outline
										isExecuting={isClearingImage}
										type="button"
										onClick={clearImage}
										executingChildren={<><Spinner size="sm" /> {t('editForm.clearingImage', 'Clearing image...')}</>}>
										{t('editForm.clearImageButton', 'Clear image')}
									</ButtonAsync>
								</ButtonGroup>
							</Col>
						</Row>
					</FormGroup>

					<FormGroup>
						<Label for="content">{t('editForm.descriptionHtml.label', 'Thumbnail summary')}</Label>
						<HtmlEditor
							size="sm"
							value={model?.descriptionHtml ?? ''}
							onChange={(value: string) => change({ descriptionHtml: value })}
						/>
						<FormText>
							{t('editForm.descriptionHtml.hint', 'This is a short description of the purpose of the form.')}
						</FormText>
					</FormGroup>
				</Form>
			</MainContainer>
		</>
	);
}
