import { RouteEntry } from "../../shared/routes";
import { News } from "./News";
import { ViewNews } from "./ViewNews";

export const newsItemsRoutes: Array<RouteEntry> = [
    { path: '/news', element: <News />, authorize: true },

    // View news by tag.
    { path: '/news/category/:ssoFriendly/:tagId', element: <News />, authorize: true },
    { path: '/news/category/:tagId', element: <News />, authorize: true },

    // View news with a basic, and more SSO friendly url.
    { path: '/news/:ssoFriendly/:id', element: <ViewNews />, authorize: true },
    { path: '/news/:id', element: <ViewNews />, authorize: true },
];
