import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { NewsItemFieldsFragment } from '../generated/graphql';
import { ContentsType } from './codeOnly/ContentsType';

export const newsItemFieldsFragment = gql`
    fragment newsItemFields on NewsItem {
        id
        name
        originalSourceUrl
        contentsHtml
        blobReferenceId
        publishDate
        archived
        searchText        
        descriptionHtml
        showOnHome
        contentsType
        contentsBlobReferenceId
    }
`;

export type NewsItem = Omit<NewsItemFieldsFragment, '__typename' | 'newsItem'>;

export const newsItemDefaultValues = (): Partial<NewsItem> => (
    {
        id: Guid.newGuid(),
        name: '',
        archived: false,
        contentsHtml: '',
        searchText: '',
        blobReferenceId: undefined,
        originalSourceUrl: '',
        publishDate: null,
        showOnHome: false,
        descriptionHtml: '',
        contentsType: ContentsType.Html,
        contentsBlobReferenceId: null,
    });