import { TagSelector, TagSelectorProps } from "../../shared/tagSelector/TagSelector";

/**
 * Component for selecting and deselecting Tags.
 * 
 * @note Yeah the name is a bit confusing, but I don't want two "TagSelector" components and don't want a "Base" on the other component's name so
 * I've gone with this.
 */
export const TagTagSelector = (props: TagSelectorProps) => {
    return (
        <TagSelector {...props} color="info" />
        );
};