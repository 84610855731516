import './userNav.scss';

import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, NavItem, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthenticatedState } from '../../../api/api-authorization/useAuthenticatedState';
import { useTranslation } from 'react-i18next';
import { useToggleState } from 'use-toggle-state';
import { useMemo } from 'react';
import { useCurrentUserProfile } from '../../../api/main/profiles/useCurrentUserProfile';

/**
 * Navbar area that is for the current user. 
 */
export const UserNav = () => {
    const { isAuthenticated, user } = useAuthenticatedState({ includeUser: true });
    const [dropdownOpen, toggleDropdown] = useToggleState();
    const { data: { model: profile } } = useCurrentUserProfile();

    const { t } = useTranslation();

    // Work out what to show in the circle for logged in users.
    const userInitials = useMemo(() => {
        let name = profile?.firstName?.trimStart();
        if (!name) {
            name = profile?.lastName?.trimStart();
            if (!name) {
                name = user?.name?.trimStart();
            }
        }

        if (!name) {
            return '';
        }

        return name.substring(0, 1).toLocaleUpperCase();
    }, [profile, user]);

    // Render the UI
    //

    // If the user is not logged in, then give them the login and register options.
    if (!isAuthenticated) {
        return (
            <>
                {/*<NavItem>
                    <LinkContainer className="user-nav-register-button" to="/account/register">
                        <Button color="primary">
                            {t('userNav.register', 'Sign up for free')}
                        </Button>
                    </LinkContainer>
                </NavItem>*/}
                <NavItem>
                    <LinkContainer to="/authentication/login">
                        <Button className="user-nav-login-button" color="primary">
                            {t('userNav.signIn', 'Sign in')}
                        </Button>
                    </LinkContainer>
                </NavItem>
            </>
        );
    }

    // User is logged in so show their options.
    return (
        <Dropdown direction="down" isOpen={dropdownOpen} toggle={() => toggleDropdown()} nav>
            <DropdownToggle nav>
                <div className="user-nav-circle">
                    {userInitials}
                </div>
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem header tag="div" className="text-center"><strong>{t('userNav.account', 'Account')}</strong></DropdownItem>
                <LinkContainer to={'/profile/details'}>
                    <DropdownItem>
                        <FontAwesomeIcon icon="user" />
                        <> {t('userNav.profile', 'Profile')}</>
                    </DropdownItem>
                </LinkContainer>
                <DropdownItem divider />
                <LinkContainer to="/authentication/logout" state={{ local: true}}>
                    <DropdownItem>
                        <FontAwesomeIcon icon="lock" />
                        <> {t('userNav.signOut', 'Sign out')}</>
                    </DropdownItem>
                </LinkContainer>
            </DropdownMenu>
        </Dropdown>
    );
};
