import * as React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import { useSamlConfiguration } from "../../../api/account/useSamlConfiguration";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";

/**
 * Initiate the SAML2 login process.
 */
export const Saml2Login = () => {
    const { t } = useTranslation();

    const { data: { samlConfiguration }, isLoading, errors: loadErrors } = useSamlConfiguration();

    // If we have a signOnUrl, do a full page redirect for the user to that URL.
    React.useEffect(() => {
        if (!samlConfiguration || !samlConfiguration.enabled) {
            return;
        }

        window.location.href = samlConfiguration.signOnRedirectUrl;
    }, [samlConfiguration]);
 
    // Render the UI.
    //
    return (
        <>
            <MainContainer color="transparent">
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={!!samlConfiguration && !samlConfiguration?.enabled}>
                    <Alert color="danger">
                        {t('saml2Login.notAvailableError', 'SAML single sign on is not available for this application.')}
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
