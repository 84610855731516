import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { TagFieldsFragment } from '../generated/graphql';

export const tagFieldsFragment = gql`
    fragment tagFields on Tag {
        id
        name
        archived
    }
`;

export type Tag = Omit<TagFieldsFragment, '__typename' | 'tag'>;

/**
 * Default values.
 */
export const tagDefaultValues = (): Partial<Tag> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
});