import { TFunction } from "i18next";

/**
 * Options for opening a link for an ExternalSite.
 */
export enum LinkOpenType {
    NewWindow = "NewWindow",
    CurrentWindow = "CurrentWindow",
    IFrameNewWindow = "iFrameNewWindow",
    IFrame = "iFrame",
}

/**
 * Return the display text for a LinkOpenType.
 * @param t
 */
export function linkOpenTypeDisplayName(type: LinkOpenType, t: TFunction): string {
    switch (type) {
        case LinkOpenType.CurrentWindow: return t('linkOpenType.CurrentWindow', 'Current window/tab');
        case LinkOpenType.NewWindow: return t('linkOpenType.NewWindow', 'New window/tab');
        case LinkOpenType.IFrameNewWindow: return t('linkOpenType.IFrameNewWindow', 'Embedded (iFrame) in new window/tab');
        case LinkOpenType.IFrame: return t('linkOpenType.IFrame', 'Embedded (iFrame) in current window/tab');
    }
}
