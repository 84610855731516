import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateAudienceMutation, CreateAudienceMutationVariables, CreateAudienceInput } from '../generated/graphql';
import { audienceFieldsFragment } from '../models/Audience';

/**
 * Get a callback to create a Profile in the store.
 */
export function useCreateAudienceMutation(): [(model: CreateAudienceInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateAudienceMutation, CreateAudienceMutationVariables>(
        gql`
            mutation createAudienceMutation ($model: CreateAudienceInput!) {
                createAudience(model: $model) {
                    ...audienceFields
                }
            }

            ${audienceFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CreateAudienceInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
