import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useViewFormViewModel } from "../../../api/main/formSubmissions/viewModels/useViewFormViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay, HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { BodyBackground } from "../../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { formSubmissionDefaultValues } from '../../../api/main/models/FormSubmission';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useValidatorCallback } from 'pojo-validator-react';
import { useSaveFormSubmissionMutation } from '../../../api/main/formSubmissions/useSaveFormSubmissionMutation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useCurrentUser } from '../../../api/main/users/useCurrentUser';
import '../completeForm.scss';
import { createServiceProvider } from '../../../configure/configureServices';
import { CompleteFormProps } from '../CompleteForm';
import { useJsonObject } from '../../../shared/useJsonObject';

/**
 * Page for completing a form.
 */

//export interface CompleteFormProps {
//	isCreate?: boolean,
//	onCreateDefaultValues?: () => Partial<FormSubmission>
//}

interface JsonProperties {
    driverName: string,
    payrollNumber: string,
    date: string,
    bus: string,
    service: string,
    time: string,
    directionOfTravel: string,
    passengerAction: string,
    incidentDetails: string,
    passengerName: string,
    cardNumber: string
}

export const CompletePermitMisuseForm = (props: CompleteFormProps) => {
    const { id, formId } = useParams<{ id: string | undefined, formId: string | undefined }>();

    const { t } = useTranslation();

    const { isCreate, onCreateDefaultValues } = props;

    const currentUser = useCurrentUser();

    const navigator = useNavigate();

    // Load all data.
    const {
        data: {
            model: storeModel, form
        },
        errors: loadErrors, isLoading,
    } = useViewFormViewModel(props.id ?? id, props.formId ?? formId);

    const [saveFormSubmission, { errors: savingErrors, isExecuting: isSaving }] = useSaveFormSubmissionMutation();

    const { model, change, changes } = useChanges(
        storeModel,
        isCreate ?
            { ...formSubmissionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}), formId: props.formId ?? formId } :
            undefined
    );

    const [isReadOnly] = React.useState<boolean>(props.readonly ?? false);

    React.useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (currentUser.id === model?.userId) {
            return;
        }

        let email = currentUser.email;

        if (email.endsWith(".nctx")) {
            email = '';
        }

        change({ userId: currentUser.id, responseEmail: email });
    }, [currentUser, change, model]);

    const [formProperties, setFormPropertyValue] = useJsonObject<JsonProperties>(!!model && model.jsonValuesString ? model.jsonValuesString : "{}", (json) => change({ jsonValuesString: json }));


    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            responseEmail: () => !model?.responseEmail ? t('formSubmission.errors.responseEmail', 'Response email cannot be empty') : '',
            driverName: () => !formProperties.driverName ? t('permitMisuseForm.errors.driverName', 'You must include your name') : '',
            payrollNumber: () => !formProperties.payrollNumber ? t('permitMisuseForm.errors.payrollNumber', 'You must include your payroll number') : '',
            passengerAction: () => !formProperties.passengerAction ? t('permitMisuseForm.errors.passengerAction', 'You must select an action') : '',
            passengerName: () => !formProperties.passengerName ? t('permitMisuseForm.errors.passengerName', 'You must include the name on the card') : '',
            cardNumber: () => !formProperties.cardNumber ? t('permitMisuseForm.errors.cardNumber', 'You must include the card number') : ''
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, formProperties, form]);

    const save = React.useCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        await saveFormSubmission(model.id, changes, isCreate ?? false);

        //Send the submission to the email addresses provided by the form object
        const serviceProvider = createServiceProvider();
        const services = serviceProvider.services();

        await services.apiFetch().post<boolean>(`/api/forms/sendnewsubmissionemails?id=${encodeURI(model.id)}`);

        navigator("/forms/thankyou");
    }, [model, changes, validate, isCreate, saveFormSubmission, navigator]);

    const toggleButtonChanged = React.useCallback((value: string) => {
        if (isReadOnly) {
            return;
        }

        setFormPropertyValue({ ...formProperties, passengerAction: value });
    }, [formProperties, setFormPropertyValue, isReadOnly]);

    return (
        <>
            <BodyBackground background="forms" />

            <MainContainer className="complete-form">
                {/* Title - note this is not within a banner. */}
                <Row>
                    <Col>
                        <div className="complete-form-title">
                            <h1>
                                {form?.name ?? ''}
                            </h1>
                        </div>

                        {
                            model ? (
                                <div className="complete-form-date">
                                    <FontAwesomeIcon icon={['far', 'clock']} />
                                    <> </>
                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: !!model.dateCreated ? moment(model.dateCreated) : moment() })}
                                </div>
                            ) : null
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <AlertOnErrors errors={[
                    loadErrors, savingErrors
                ]} />

                {/*Image if we have one */}
                {
                    form?.blobReference?.url ? (
                        <Row className="view-news-image-container">

                            <Col xs="auto">
                                <img className="img-fluid complete-form-image" src={form?.blobReference?.url ?? ''} alt={t('formSubmission.image.altText', 'Picture to support the form')} />
                            </Col>
                        </Row>
                    ) : null
                }

                {/* Main body text */}
                <HtmlDisplay html={form?.descriptionHtml ?? ''} />

                <Form onSubmit={(e) => { e.preventDefault(); save(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>
                                    {t('formSubmission.responseEmail.label', 'Your email address')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="responseEmail" type="email" value={model?.responseEmail ?? ''} onChange={e => change({ responseEmail: e.currentTarget.value })} onBlur={e => validate('responseEmail')} validationErrors={validationErrors['responseEmail']} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>{t('permitMisuseForm.driverDetails.subHeading', 'Driver details')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.driverDetails.nameLabel', 'Name')}
                                </Label>

                                <ValidatedInput disabled={isReadOnly} name="driverName" type="text" value={formProperties.driverName} onChange={e => setFormPropertyValue({ ...formProperties, driverName: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.driverDetails.payrollNumberLabel', 'Payroll number')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="payrollNumber" type="text" value={formProperties.payrollNumber} onChange={e => setFormPropertyValue({ ...formProperties, payrollNumber: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/*Service details*/}
                    <Row>
                        <Col>
                            <h3>{t('permitMisuseForm.serviceDetails.subHeading', 'Service details')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.serviceDetails.dateLabel', 'Date')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="date" type="date" value={formProperties.date} onChange={e => setFormPropertyValue({ ...formProperties, date: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.serviceDetails.bus', 'Bus')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="bus" type="text" value={formProperties.bus} onChange={e => setFormPropertyValue({ ...formProperties, bus: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={3}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.serviceDetails.service', 'Service')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="service" type="text" value={formProperties.service} onChange={e => setFormPropertyValue({ ...formProperties, service: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={3}>
                            <FormGroup>
                                <Label>
                                    {t('permitMisuseForm.serviceDetails.time', 'Time')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="time" type="time" value={formProperties.time} onChange={e => setFormPropertyValue({ ...formProperties, time: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{t('permitMisuseForm.serviceDetails.directionOfTravel', 'Direction of travel')}</Label>
                                <ValidatedInput disabled={isReadOnly} name="directionOfTravel" type="text" value={formProperties.directionOfTravel} onChange={e => setFormPropertyValue({ ...formProperties, directionOfTravel: e.currentTarget.value })} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={!!validationErrors.passengerAction}>
                        <Row>
                            <Col>
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {validationErrors.passengerAction}
                                </div>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    <Row>
                        <Col><h3>{ t('permitMisuseForm.permitDetails.subHeading', 'Permit / Passenger details') }</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>{t('permitMisuseForm.passengerName.label', 'Passenger name on card')}</Label>
                            <ValidatedInput name="passengerName" value={formProperties.passengerName} onChange={(e) => setFormPropertyValue({ ...formProperties, passengerName: e.currentTarget.value })} validationErrors={validationErrors} onBlur={ () => validate() } />
                        </Col>
                        <Col>
                            <Label>{t('permitMisuseForm.cardNumber.label', 'Card number')}</Label>
                            <ValidatedInput name="cardNumber" value={formProperties.cardNumber} onChange={(e) => setFormPropertyValue({ ...formProperties, cardNumber: e.currentTarget.value })} validationErrors={validationErrors} onBlur={() => validate()} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{t('permitMisuseForm.passengerAction.label', 'After permit was withdrawn, did passenger:')}</Label>
                                <div>
                                    <ButtonGroup>
                                        <Button color="primary" outline onClick={() => toggleButtonChanged("alight")} active={!!formProperties && formProperties.passengerAction === 'alight'}>{t('permitMisuseForm.alightChoice', 'Alight')}</Button>
                                        <Button color="primary" outline onClick={() => toggleButtonChanged('payed')} active={!!formProperties && formProperties.passengerAction === 'payed'}>{t('permitMisuseForm.payChoice', 'Pay and travel')}</Button>
                                    </ButtonGroup>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{t('permitMisuseForm.incidentDetails.label', 'Brief details of incident:')}</Label>
                                {isReadOnly ? <HtmlDisplay html={formProperties.incidentDetails} /> :
                                    <HtmlEditor value={formProperties.incidentDetails} onChange={(value) => setFormPropertyValue({ ...formProperties, incidentDetails: value })} size="sm" />
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    {isReadOnly ? <></> :
                        <Row>
                            <Col>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => save()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Submit')}</>
                                </ButtonAsync>
                            </Col>
                        </Row>
                    }
                </Form>

            </MainContainer>
        </>
    );
};