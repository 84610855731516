import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { DeleteTagMutation, DeleteTagMutationVariables } from '../generated/graphql';
import { tagLinkFieldsFragment } from '../models/TagLink';

export function useDeleteTagLinkMutation(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
        gql`
            mutation deleteTagLinkMutation ($id: ID!) {
                deleteTagLink(id: $id) {
                    ...tagLinkFields
                }
            }

            ${tagLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
