import { RouteEntry } from "../../../shared/routes";
import { DocumentsList } from "./DocumentsList";
import { DeleteDocument } from "./DeleteDocument";
import { CreateDocument, EditDocument } from "./EditDocument";



export const documentAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/documents/add', element: <CreateDocument />, authorize: true },
    { path: '/administration/documents/edit/:id', element: <EditDocument />, authorize: true },
    { path: '/administration/documents/delete/:id', element: <DeleteDocument />, authorize: true },
    { path: '/administration/documents', element: <DocumentsList />, authorize: true },
];
