import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateNewsItemMutation } from './useUpdateNewsItemMutation';
import { Profile } from '../models/Profile';
import { useCreateNewsItemMutation } from './useCreateNewsItemMutation';
import { NewsItemCreateInput, NewsItemUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Profile in the store using either a create or update.
 */
export function useSaveNewsItemMutation(options: SaveInStoreOptions<Profile, string> = {}) {
    const [_create, createStatus] = useCreateNewsItemMutation();
    const create = React.useCallback((model: Partial<Profile>) => _create(model as NewsItemCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateNewsItemMutation();
    const update = React.useCallback((id: string, changes: Partial<Profile>) => _update(id, changes as NewsItemUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
