import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult } from '../../../../shared/abstractStore';
import { EditProfileViewModelQuery, EditProfileViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { profileFieldsFragment } from '../../models/Profile';
import { userAudienceFieldsFragment } from '../../models/UserAudience';
import { ProfileOptions } from '../useProfile';
import { Guid } from 'guid-string';

/**
 * Get a list of profiles from the store.
 * @param id
 * @param options
 */
export function useEditProfileViewModel(id: string | undefined | null, options: ProfileOptions = {}): AsyncLoadResult<EditProfileViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditProfileViewModelQuery, EditProfileViewModelQueryVariables>(
        gql`
        query editProfileViewModelQuery ($id: ID, $userId: ID) {
            model: profile (id: $id, userId: $userId) {
                ...profileFields

                userAudiences {
                    ...userAudienceFields
                }
            }

        }

        ${profileFieldsFragment}
        ${userAudienceFieldsFragment}
        `,
        {

            variables: {
                id: !options.isUserId ? id ?? Guid.empty : null,
                userId: options.isUserId ? id ?? Guid.empty : null
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
