import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { FormSubmissionsReport } from "./FormSubmissionsReport";

/**
 * Routes for the FormSubmissionsReport component
 */
export const formSubmissionsReportRoutes: Array<RouteEntry> = [
    { path: '/administration/form-submissions-report', element: <FormSubmissionsReport />, authorize: true, requireRole: IdentityRoles.Administration },
];
