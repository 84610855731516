import moment from "moment";
import React, { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import {  Button, Col, Input, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { useFormSubmissions } from "../../api/main/formSubmissions/useFormSubmissions";
import { useProfiles } from "../../api/main/profiles/useProfiles";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { CompleteForm } from "../forms/CompleteForm";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * Component for FormSubmissions Report -  Readyonly view of submitted forms for admins
 */
export const FormSubmissionsReport = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [formSubmissionDetails, setFormSubmissionDetails] = React.useState<{ formId: string, id: string }>({formId: '', id: ''});

    // Load the data
    const {
        data: {
            items: _allItems,
            forms
        }, isLoading, errors: loadErrors
    } = useFormSubmissions();

    // Load the supporting data
    const {
        data: {
            items: userProfiles
        }
    } = useProfiles();

    // Combine the data from all the tables into one model.
    const allItems = useMemo(() => _allItems?.map(item => {
        const userProfile = userProfiles?.find(it => it.userId === item.userId);
        const form = forms?.find(it => it.id === item.formId);

        // Return the combined item
        return {
            ...item,
            userProfile,
            form
        };
    }), [_allItems, userProfiles, forms]);

    // Filter FormTypes
    const [_filterFormType, setFilterFormType] = useState<string | null | undefined>('all');
    const filterFormType = _filterFormType || forms?.find(it => true)?.id;

    // Filtering and Sorting 
    const items = useMemo(() => {
        let ret = [...(allItems ?? [])];

        // Filtering 
        if (filterFormType !== 'all') {
            ret = ret.filter(it => it.formId === filterFormType);
        }

        // Search filtering - filterting for every table heading
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item => (item.form?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateCreated) }) ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.category ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.responseEmail ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.details ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.userProfile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.userProfile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.userProfile?.firstName, lastName: item.userProfile?.lastName }) ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.form?.emailAddress1 ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.form?.emailAddress2 ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.form?.emailAddress3 ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0);
        }

        // Sorting by date created 
        ret.sort((a, b) => {
            if (a.dateCreated === b.dateCreated) {
                return 0;
            } else if (a.dateCreated > b.dateCreated) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret;
    }, [allItems, filterFormType, search]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('formSubmissionsReport.title', 'Form submissions report')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    
                        <Col xs={12} sm="">
                            <Input name="classTypeId" type="select" value={filterFormType} onChange={(e) => setFilterFormType(e.currentTarget.value)}>
                                <option value="all">{t('formSubmissionsReport.formName.all', '(All forms)')}</option>
                                {
                                    forms?.map(form => (
                                        <option key={form.id} value={form.id}>{form.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors
                    ]}
                />

                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('formSubmissionsReport.name', 'Name'),
                        t('formSubmissionsReport.createdDate', 'Created date'),
                        t('formSubmissionsReport.category', 'Category'),
                        t('formSubmissionsReport.responseEmail', 'Response email'),
                        t('formSubmissionsReport.content', 'Content'),
                        t('formSubmissionsReport.usersName', 'User\'s name'),
                        t('formSubmissionsReport.sentTo', 'Sent to email(s)'),
                        ''
                    ]}
                    columns={[

                        // Name of form
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.form?.name;
                        },

                        // Created date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }
               
                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateCreated) });
                        },

                        // Category
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.category;
                        },

                        // Response email
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.responseEmail;
                        },

                        // Content
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.details} />;
                        },

                        // User's name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.userProfile?.firstName, lastName: item.userProfile?.lastName });
                        },

                        // Sent to email(s)
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <>
                                    <p>
                                        {item.form?.emailAddress1}
                                    </p>

                                    <ConditionalFragment showIf={!!item.form?.emailAddress2}>
                                        <p>
                                            {item.form?.emailAddress2}
                                        </p>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!item.form?.emailAddress3}>
                                        <p>
                                            {item.form?.emailAddress3}
                                        </p>
                                    </ConditionalFragment>
                                </>
                            );
                        },

                        (item, view) => (
                            <Button color="primary" onClick={(e) => { setFormSubmissionDetails({ id: item.id, formId: item.formId }); setModalOpen(true); }}>
                                {t('common.show', 'Show')}
                            </Button>
                        )
                    ]}
                />
            </MainContainer>
            <Modal isOpen={modalOpen} size="xl">
                <ModalBody>
                    <CompleteForm formId={formSubmissionDetails.formId} id={formSubmissionDetails.id} isCreate={false} readonly={true} />
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => setModalOpen(false)}>
                                {t('common.close', 'Close')}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
};