import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateAudienceLinkMutation } from './useUpdateAudienceLinkMutation';
import { AudienceLink } from '../models/AudienceLink';
import { useCreateAudienceLinkMutation } from './useCreateAudienceLinkMutation';
import { AudienceLinkCreateInput, AudienceLinkUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a AudienceLink in the store using either a create or update.
 */
export function useSaveAudienceLinkMutation(options: SaveInStoreOptions<AudienceLink, string> = {}) {
    const [_create, createStatus] = useCreateAudienceLinkMutation();
    const create = React.useCallback((model: Partial<AudienceLink>) => _create(model as AudienceLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAudienceLinkMutation();
    const update = React.useCallback((id: string, changes: Partial<AudienceLink>) => _update(id, changes as AudienceLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
