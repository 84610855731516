import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ExternalSiteFieldsFragment } from '../generated/graphql';
import { AuthorizationType } from './codeOnly/AuthorizationType';
import { DisplayOrderGroups } from './codeOnly/DisplayOrderGroups';
import { LinkOpenType } from './codeOnly/LinkOpenType';

export const externalSiteFieldsFragment = gql`
    fragment externalSiteFields on ExternalSite {
        id
        name
        url
        authorizationType
        descriptionHtml
        displayOrder
        publishDate
        blobReferenceId
        archived
        searchText
        showOnHome
        linkOpenType
    }
`;

export type ExternalSite = Omit<ExternalSiteFieldsFragment, '__typename' | 'externalSite'>;

export const externalSiteDefaultValues = (): Partial<ExternalSite> => ({
    id: Guid.newGuid(),
    url: '',
    authorizationType: AuthorizationType.NoIntegration,
    descriptionHtml: '',
    blobReferenceId: null,
    publishDate: null,
    displayOrder: parseInt(DisplayOrderGroups.StandardGroup),
    archived: false,
    searchText: '',
    showOnHome: false,
    linkOpenType: LinkOpenType.NewWindow,
});