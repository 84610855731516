import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from 'react-use-async-callback';
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAudience } from "../../api/main/audiences/useAudience";
import { useSaveAudienceMutation } from '../../api/main/audiences/useSaveAudienceMutation';
import { Audience, audienceDefaultValues } from "../../api/main/models/Audience";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from '../../shared/useChanges';
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from '../shared/formButtons/FormButtons';
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { AudienceJobTitles } from './AudienceJobTitles';

export interface EditAudienceProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Audience>
}

export const CreateAudience = (props: EditAudienceProps) => (<EditAudience isCreate={true} { ...props }></EditAudience>)

export const EditAudience = (props: EditAudienceProps) => {

    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();

    const { isCreate, onCreateDefaultValues } = props;
    const { t } = useTranslation();
    const { data: { model: storeModel }, isLoading, errors } = useAudience(id);

    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...audienceDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);

    const [save, { errors: saveErrors }] = useSaveAudienceMutation();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('audienceEdit.nameRequired', 'Name is required') : '',
            jobTitles: () => !model?.jobTitles ? t('audienceEdit.jobTitlesRequired', 'One or more job titles must be added') : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        save(id ?? '', { ...changes }, isCreate ?? false);
        navigate(-1);
    }, [model, save]);


    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editAudience.createHeading.default', 'Add audience')
                                    : t('editAudience.editHeading.default', 'Edit audience')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[errors, saveFormErrors, saveErrors, ]} />
                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editAudience.name', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editAudience.jobTitles', 'Job titles')}</Label>
                                {/*<ValidatedInput name="jobTitles" type="text" value={model?.jobTitles ?? ''} onChange={e => change({ jobTitles: e.currentTarget.value })} onBlur={e => validate('jobTitles')} validationErrors={validationErrors['jobTitles']} />*/}
                                <ConditionalFragment showIf={model?.jobTitles !== undefined}>
                                    <AudienceJobTitles jobTitlesString={model.jobTitles} changeJobTitleString={(val) => change({ jobTitles: val })} />
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                    </Row>



                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}