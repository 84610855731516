import { RouteEntry } from "../../../shared/routes";
import { ExternalSitesList } from "./ExternalSitesList";
import { DeleteExternalSite } from "./DeleteExternalSite";
import { CreateExternalSite, EditExternalSite } from "./EditExternalSite";

export const externalSiteAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/external-sites/add', element: <CreateExternalSite />, authorize: true },
    { path: '/administration/external-sites/edit/:id', element: <EditExternalSite />, authorize: true },
    { path: '/administration/external-sites/delete/:id', element: <DeleteExternalSite />, authorize: true },
    { path: '/administration/external-sites', element: <ExternalSitesList />, authorize: true },
];
