import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useViewFormViewModel } from "../../../api/main/formSubmissions/viewModels/useViewFormViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { BodyBackground } from "../../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { formSubmissionDefaultValues } from '../../../api/main/models/FormSubmission';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useValidatorCallback } from 'pojo-validator-react';
import { useSaveFormSubmissionMutation } from '../../../api/main/formSubmissions/useSaveFormSubmissionMutation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useCurrentUser } from '../../../api/main/users/useCurrentUser';
import '../completeForm.scss';
import { createServiceProvider } from '../../../configure/configureServices';
import { CompleteFormProps } from '../CompleteForm';
import { useJsonObject } from '../../../shared/useJsonObject';

/**
 * Page for completing a form.
 */

interface JsonProperties {
    name: string,
    payNo: string,
    date: string,
    reason: string,
    fromHrs: string,
    toHrs: string,
}

export const CompleteFailureToCompleteDutyMisuseForm = (props: CompleteFormProps) => {
    const { id, formId } = useParams<{ id: string | undefined, formId: string | undefined }>();

    const { t } = useTranslation();

    const { isCreate, onCreateDefaultValues } = props;

    const currentUser = useCurrentUser();

    const navigator = useNavigate();

    const [isReadOnly] = React.useState<boolean>(props.readonly ?? false);

    // Load all data.
    const {
        data: {
            model: storeModel, form
        },
        errors: loadErrors, isLoading,
    } = useViewFormViewModel(props.id ?? id, props.formId ?? formId);

    const [saveFormSubmission, { errors: savingErrors, isExecuting: isSaving }] = useSaveFormSubmissionMutation();

    const { model, change, changes } = useChanges(
        storeModel,
        isCreate ?
            { ...formSubmissionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}), formId: formId } :
            undefined
    );

    React.useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (currentUser.id === model?.userId) {
            return;
        }

        let email = currentUser.email;

        if (email.endsWith(".nctx")) {
            email = '';
        }

        change({ userId: currentUser.id, responseEmail: email });
    }, [currentUser, change, model]);

    const [formProperties, setFormPropertyValue] = useJsonObject<JsonProperties>(!!model && model.jsonValuesString ? model.jsonValuesString : "{}", (json) => change({ jsonValuesString: json }));


    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            responseEmail: () => !model?.responseEmail ? t('formSubmission.errors.responseEmail', 'Response email cannot be empty') : '',
            name: () => !formProperties.name ? t('failureTocompleteDutyForm.errors.name', 'You must include your name') : '',
            payNo: () => !formProperties.payNo ? t('failureTocompleteDutyForm.errors.payNo', 'You must include your payroll number') : '',
            date: () => !formProperties.date ? t('failureTocompleteDutyForm.errors.date', 'You must include the date') : '',
            reason: () => !formProperties.reason ? t('failureToCompleteDutyForm.errors.reason', 'Please provide a reason') : '',
            fromHrs: () => !formProperties.fromHrs ? t('failureToCompleteDutyForm.errors.fromHrs', 'Please provide a start time') : '',
            toHrs: () => !formProperties.toHrs ? t('failureToCompleteDutyForm.errors.toHrs', 'Please provide an end time') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, formProperties, form]);

    const onChanges = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFormPropertyValue({ ...formProperties, [e.currentTarget.name]: e.currentTarget.value });
    }, [formProperties, setFormPropertyValue]);

    const date = React.useMemo(() => {
        if (!formProperties.date) {
            return '';
        }
        const mome: Moment = moment(formProperties.date);

        return mome.format("L");
    }, [formProperties.date]);

    const save = React.useCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        await saveFormSubmission(model.id, changes, isCreate ?? false);

        //Send the submission to the email addresses provided by the form object
        const serviceProvider = createServiceProvider();
        const services = serviceProvider.services();

        await services.apiFetch().post<boolean>(`/api/forms/sendnewsubmissionemails?id=${encodeURI(model.id)}`);

        navigator("/forms/thankyou");
    }, [model, changes, validate, isCreate, saveFormSubmission, navigator]);

    return (
        <>
            <BodyBackground background="forms" />

            <MainContainer className="complete-form">
                {/* Title - note this is not within a banner. */}
                <Row>
                    <Col>
                        <div className="complete-form-title">
                            <h1>
                                {form?.name ?? ''}
                            </h1>
                        </div>

                        {
                            model ? (
                                <div className="complete-form-date">
                                    <FontAwesomeIcon icon={['far', 'clock']} />
                                    <> </>
                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: !!model.dateCreated ? moment(model.dateCreated) : moment() })}
                                </div>
                            ) : null
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <AlertOnErrors errors={[
                    loadErrors, savingErrors
                ]} />

                {/*Image if we have one */}
                {
                    form?.blobReference?.url ? (
                        <Row className="view-news-image-container">

                            <Col xs="auto">
                                <img className="img-fluid complete-form-image" src={form?.blobReference?.url ?? ''} alt={t('formSubmission.image.altText', 'Picture to support the form')} />
                            </Col>
                        </Row>
                    ) : null
                }

                {/* Main body text */}
                <HtmlDisplay html={form?.descriptionHtml ?? ''} />

                <Form onSubmit={(e) => { e.preventDefault(); save(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>
                                    {t('formSubmission.responseEmail.label', 'Your email address')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="responseEmail" type="email" value={model?.responseEmail ?? ''} onChange={e => change({ responseEmail: e.currentTarget.value })} onBlur={e => validate('responseEmail')} validationErrors={validationErrors['responseEmail']} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} md={4}>
                            <FormGroup>
                                <Label>
                                    {t('failureTocompleteDutyForm.driverDetails.nameLabel', 'Name')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="name" type="text" value={formProperties.name} onChange={onChanges} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    
                        <Col xs={12} sm={6} md={4}>
                            <FormGroup>
                                <Label>
                                    {t('failureTocompleteDutyForm.driverDetails.payNo', 'Payroll number')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="payNo" type="text" value={formProperties.payNo} onChange={onChanges} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <FormGroup>
                                <Label>
                                    {t('failureTocompleteDutyForm.driverDetails.date', 'Date')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="date" type="date" value={formProperties.date} onChange={onChanges} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <p>
                                {t('failureToCompleteDutyForm.reason.label', 'On {{date}} I was unable to complete my duty because of...', { date: !!date ? date : '__________' })}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ValidatedInput disabled={isReadOnly} name="reason" value={formProperties.reason} onBlur={e => validate(e.currentTarget.name)} validationErrors={validationErrors} onChange={onChanges} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>{t('failureToCompleteDutyForm.fromHrs.label', 'and I wish to claim payment for the portion of my duty not worked, which was from:')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <FormGroup>
                                <ValidatedInput disabled={isReadOnly} type="time" name="fromHrs" onChange={onChanges} value={formProperties.fromHrs} onBlur={e => validate(e.currentTarget.name)} validationErrors={validationErrors} />
                            </FormGroup>
                        </Col>
                        <Col xs="auto">
                            <span>{t('failureToCompleteDutyForm.toHrs.label', 'to')}</span>
                        </Col>
                        <Col xs="auto">
                            <FormGroup>
                                <ValidatedInput disabled={isReadOnly} type="time" name="toHrs" onChange={onChanges} value={formProperties.toHrs} onBlur={e => validate(e.currentTarget.name)} validationErrors={validationErrors} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={!isReadOnly}>
                        <Row>
                            <Col>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => save()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Submit')}</>
                                </ButtonAsync>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </Form>
            </MainContainer>
        </>
    );
};