import { Profile } from "../../api/main/generated/graphql";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";

/**
 * Hook that returns true if the profile cannot be edited by the user themselves.
 * 
 * This gets set to true when the profile is linked to an external ID (e.g. an SSO login or auto managed logins such as drivers).
 */
export function useIsProfileLockedFromSelfEditing() {
    const { data: { model} } = useCurrentUserProfile();
    if (!model) {
        return true;
    }

    return !!model.registeredForExternalID;
}