import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { TagLinkFieldsFragment } from '../generated/graphql';

export const tagLinkFieldsFragment = gql`
    fragment tagLinkFields on TagLink {
        id
        tagId
        targetId
        targetType
    }
`;

export type TagLink = Omit<TagLinkFieldsFragment, '__typename' | 'tagLink'>;

/**
 * Default values.
 */
export const tagLinkDefaultValues = (): Partial<TagLink> => ({
    id: Guid.newGuid()
});