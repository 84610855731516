import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { DeleteUserAudienceMutation, DeleteUserAudienceMutationVariables } from "../generated/graphql";
import { userAudienceFieldsFragment } from "../models/UserAudience";

export function useDeleteUserAudienceMutation(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<DeleteUserAudienceMutation, DeleteUserAudienceMutationVariables>(
        gql`
            mutation deleteUserAudienceMutation ($id: ID!) {
                deleteUserAudience(id: $id) {
                    ...userAudienceFields
                }
            }

            ${userAudienceFieldsFragment}
`   

    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id} });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}