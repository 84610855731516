import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useViewNewsViewModel } from "../../api/main/newsItems/viewModels/useViewNewsViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import "./viewNews.scss";

/**
 * Page for viewing a peice of news.
 */
export const ViewNews = () => {
    const { id } = useParams<{ id: string | undefined }>();

    const { t } = useTranslation();

    // Load all data.
    const {
        data: {
            model,
        },
        errors: loadErrors, isLoading,
    } = useViewNewsViewModel(id);

    // Full URL.
    const fullUrl = useMemo(() => {
        const url = model?.originalSourceUrl;

        if (!url) {
            return '';
        }

        if (url.indexOf('://') !== -1) {
            return url;
        }

        return `http://${url}`;
    }, [model?.originalSourceUrl]);

    return (
        <>
            <BodyBackground background="news" />

            <MainContainer className="view-news">
                {/* Title - note this is not within a banner. */}
                <Row>
                    <Col>
                        <div className="view-news-title">
                            <h1>
                                {model?.name}
                            </h1>
                        </div>

                        {
                            model?.publishDate ? (
                                <div className="view-news-date">
                                    <FontAwesomeIcon icon={['far', 'clock']} />
                                    <> </>
                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.publishDate) })}
                                </div>
                            ): null
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <AlertOnErrors errors={[
                    loadErrors,
                ]} />

                {/* Image if we have one */}
                {
                    model?.blobReference?.url ? (
                        <Row className="view-news-image-container">
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <img className="img-fluid view-news-image" src={model?.blobReference?.url ?? ''} alt={t('viewNews.image.altText', 'Picture to support the news article')} />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    ): null
                }

                {/* Main body text */}
                <HtmlDisplay html={model?.contentsHtml ?? ''} />

                {/* View document link, if we have one */}
                {
                    model?.contentsBlobReference?.url ? (
                        <div className="view-news-link">
                            {t('viewNews.filePrefix', 'View related document: ')}
                            <a href={model?.contentsBlobReference?.url} target="_blank" rel="noreferrer">
                                {model?.contentsBlobReference?.filename}
                            </a>
                            {t('viewNews.filePostfix', '') /* For languages where this is needed alongside/instead of a prefix. */}
                        </div>
                    ) : null
                }

                {/* View more link, if we have one */}
                {
                    fullUrl ? (
                        <div className="view-news-link">
                            {t('viewNews.linkPrefix', 'Learn more about this at: ')}
                            <a href={fullUrl} target="_blank" rel="noreferrer">
                                {fullUrl}
                            </a>
                            {t('viewNews.linkPostfix', '') /* For languages where this is needed alongside/instead of a prefix. */} 
                        </div>
                    ): null
                }
            </MainContainer>
        </>
    );
};