import { RouteEntry } from "../../shared/routes";
import { Links } from "./Links";
import { ViewIFrameLink } from "./ViewIFrameLink";

export const externalSitesRoutes: Array<RouteEntry> = [
    { path: '/links', element: <Links />, authorize: true },

    // View links by tag.
    { path: '/links/category/:ssoFriendly/:tagId', element: <Links />, authorize: true },
    { path: '/links/category/:tagId', element: <Links />, authorize: true },

    // View sites that are embedded with routes for a basic, and more SSO friendly url.
    { path: '/links/:ssoFriendly/:id', element: <ViewIFrameLink />, authorize: true },
    { path: '/links/:id', element: <ViewIFrameLink />, authorize: true },
];
