import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { DocumentFieldsFragment } from '../generated/graphql';
import { ContentsType } from './codeOnly/ContentsType';
import { DisplayOrderGroups } from './codeOnly/DisplayOrderGroups';

export const documentFieldsFragment = gql`
    fragment documentFields on Document {
        id
        originalDocumentId
        name
        version
        publishDate
        originalSourceUrl
        descriptionHtml
        versionChangesHtml
        tableOfContentLevels
        blobReferenceId
        displayOrder
        searchText
        archived    
        showOnHome
        contentsType
        contentsBlobReferenceId
    }
`;

export type Document = Omit<DocumentFieldsFragment, '__typename' | 'document'>;

/**
 * Default values.
 */
export const documentDefaultValues = (): Partial<Document> => ({
    id: Guid.newGuid(),
    originalDocumentId: Guid.empty,
    name: '',
    version: '1.0',
    publishDate: null,
    originalSourceUrl: '',
    descriptionHtml: '',
    versionChangesHtml: '',
    tableOfContentLevels: 3, // Default to 3 levels, in line with MS Word's built in default which we can assume is somewhat sensible.
    blobReferenceId: null,
    displayOrder: parseInt(DisplayOrderGroups.StandardGroup),
    archived: false,
    searchText: '',
    showOnHome: false,
    contentsType: ContentsType.Html,
    contentsBlobReferenceId: null,
});