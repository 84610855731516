import { TFunction } from "i18next";

/**
 * Options for doing automated authorization for an ExternalSite.
 */
export enum AuthorizationType {
    NoIntegration = "NoIntegration",
}

/**
 * Return the display text for a AuthorizationType.
 * @param t
 */
export function authorizationTypeDisplayName(type: AuthorizationType, t: TFunction): string {
    switch (type) {
        case AuthorizationType.NoIntegration: return t('authorizationType.noIntegration', 'No authorization or SSO integration');
    }
}
