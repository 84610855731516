import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, FormText, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { TagLink } from "../../../api/main/generated/graphql";
import { newsItemDefaultValues } from "../../../api/main/models/NewsItem";
import { useSaveNewsItemMutation } from "../../../api/main/newsItems/useSaveNewsItemMutation";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { useDeleteTagLinkMutation } from '../../../api/main/tagLinks/useDeleteTagLinkMutation';
import { AudienceLink } from '../../../api/main/models/AudienceLink';
import { useDeleteAudienceLinkMutation } from '../../../api/main/audienceLinks/useDeleteAudienceLinkMutation';
import { PublishDateHandlerComponent } from '../../shared/publishDateHandler/PublishDateHandlerComponent';
import { useEditNewsItemViewModel } from '../../../api/main/newsItems/viewModels/useEditNewsItemViewModel';
import { useEditNewsItemSupportingData } from '../../../api/main/newsItems/viewModels/useEditNewsItemSupportingData';
import { useSaveAudienceLinkMutation } from '../../../api/main/audienceLinks/useSaveAudienceLinkMutation';
import { useSaveTagLinkMutation } from '../../../api/main/tagLinks/useSaveTagLinkMutation';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';
import { Guid } from 'guid-string';
import { AudienceTagSelector } from '../../audiences/AudienceTagSelector';
import { TagTagSelector } from '../../tags/TagTagSelector';
import { ContentsType, contentsTypeDisplayName } from "../../../api/main/models/codeOnly/ContentsType";
import { UploadedFilePreview } from "../../shared/uploadedFilePreview/UploadedFilePreview";

export interface EditNewsItemProps {
    isCreate?: boolean,
}

/**
 * Create a news item to be published.
 */
export const CreateNewsItem = (props: EditNewsItemProps) => (<EditNewsItem isCreate={true} {...props}></EditNewsItem>)

/**
 * Edit a News item to be published to an audience.
 */
export const EditNewsItem = (props: EditNewsItemProps) => {

    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isCreate } = props;

    // Load all data.
    const {
        data: {
            model: storeModel,
            audienceLinks,
            tagLinks,
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useEditNewsItemViewModel(id);
    const {
        data: {
            audiences,
            tags,
        },
        errors: supportingDataErrors, isLoading: isLoadingSupportingData,
    } = useEditNewsItemSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // model management.
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...newsItemDefaultValues() } : undefined);
    const [saveNewsItem, { errors: saveErrors }] = useSaveNewsItemMutation();

    // AudienceLink management.
    const audienceLinksManager = useChangesArray<AudienceLink, string>(audienceLinks, item => item.id);
    const [saveAudienceLink, { errors: saveAudienceLinkErrors }] = useSaveAudienceLinkMutation();
    const [deleteAudienceLink, { errors: deleteAudienceLinkErrors }] = useDeleteAudienceLinkMutation();

    // TagLink management.
    const tagLinksManager = useChangesArray<TagLink, string>(tagLinks, item => item.id);
    const [saveTagLink, { errors: saveTagLinkErrors }] = useSaveTagLinkMutation();
    const [deleteTagLink, { errors: deleteTagLinkErrors }] = useDeleteTagLinkMutation();

    // Image uploading.
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    const [clearImage, { isExecuting: isClearingImage, }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Contents file uploading.
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.contentsBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ contentsBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ contentsBlobReferenceId: null });
    }, [change]);


    // Validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('newsItemEdit.name.validation.required', 'Headline is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything in the form.
    const [saveForm, { errors: saveFormErrors, isExecuting: isSaving }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the model.
        saveNewsItem(model.id, { ...changes }, isCreate ?? false);

        // Save changes to the audienceLinks.
        for (let audienceLink of audienceLinksManager.added) {
            await saveAudienceLink(audienceLink.id, audienceLink, true);
        }
        for (let audienceLink of audienceLinksManager.updated) {
            await saveAudienceLink(audienceLink.id, audienceLink, false);
        }
        for (let audienceLink of audienceLinksManager.removed) {
            await deleteAudienceLink(audienceLink.id);
        }
        audienceLinksManager.markAsSaved();

        // Save changes to the tagLinks.
        for (let tagLink of tagLinksManager.added) {
            await saveTagLink(tagLink.id, tagLink, true);
        }
        for (let tagLink of tagLinksManager.updated) {
            await saveTagLink(tagLink.id, tagLink, false);
        }
        for (let tagLink of tagLinksManager.removed) {
            await deleteTagLink(tagLink.id);
        }
        tagLinksManager.markAsSaved();

        // Navigate back.
        navigate(-1);
    }, [
        model, validate, changes, isCreate, saveNewsItem,
        audienceLinksManager, saveAudienceLink, deleteAudienceLink,
        tagLinksManager, saveTagLink, deleteTagLink,
    ]
    );

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {isCreate ? t('editNewsItem.createHeading.default', 'Add news item')
                                    : t('editNewsItem.editHeading.default', 'Edit news item')}
                            </h1>
                            <h6>{model?.name}</h6>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <FormButtons top>
                                <ConditionalFragment showIf={!isLoading}>
                                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                        <FontAwesomeIcon icon="save" />
                                        <> {t('common.save', 'Save')}</>
                                    </ButtonAsync>
                                </ConditionalFragment>

                                <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                                    {t('common.cancel', 'Cancel')}
                                </Button>
                            </FormButtons>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors, supportingDataErrors,
                    saveFormErrors, saveErrors,
                    saveAudienceLinkErrors, deleteAudienceLinkErrors,
                    saveTagLinkErrors, deleteTagLinkErrors,
                    imageUploadErrors, imageLoadErrors,
                ]} />
                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{t('editNewsItem.name.label', 'Headline')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md="auto">
                            <PublishDateHandlerComponent
                                model={model}
                                change={change}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="auto">
                            <FormGroup>
                                <Label htmlFor="published">{t('editNewsItem.showOnHome.label', 'Show on home page?')}</Label>
                                <TwoValueSwitch
                                    leftLabel={t('common.no', 'No')}
                                    rightLabel={t('common.yes', 'Yes')}
                                    checked={model.showOnHome}
                                    onChange={checked => change({ showOnHome: checked })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editNewsItem.audiencesLabel', 'Available for audiences')}</Label>
                                <AudienceTagSelector
                                    input
                                    tags={audiences}
                                    isSelected={tag => !!audienceLinksManager.model.find(it => it.audienceId === tag.id)}
                                    toggle={tag => {
                                        let existing = audienceLinksManager.model.find(item => item.audienceId === tag.id);
                                        if (existing) {
                                            audienceLinksManager.removeFor(existing.id);
                                        } else {
                                            audienceLinksManager.addFor({ id: Guid.newGuid(), audienceId: tag.id, targetId: model.id, targetType: 'NewsItem', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editNewsItem.tagsLabel', 'Tags')}</Label>
                                <TagTagSelector
                                    input
                                    tags={tags}
                                    isSelected={tag => !!tagLinksManager.model.find(it => it.tagId === tag.id)}
                                    toggle={tag => {
                                        let existing = tagLinksManager.model.find(item => item.tagId === tag.id);
                                        if (existing) {
                                            tagLinksManager.removeFor(existing.id);
                                        } else {
                                            tagLinksManager.addFor({ id: Guid.newGuid(), tagId: tag.id, targetId: model.id, targetType: 'NewsItem', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="name">{t('editNewsItem.image.label', 'Thumbnail image')}</Label>
                        <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                        <Row>
                            <Col>
                                <ButtonGroup>
                                    <FileUploadButton
                                        color={`primary`}
                                        isExecuting={isUploadingImage}
                                        executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                        onUpload={onUploadImage}
                                        outline={false}>
                                        {t('editNewsItem.uploadButtonText', 'Upload an image')}
                                    </FileUploadButton>
                                    <ButtonAsync color="primary"
                                        outline
                                        isExecuting={isClearingImage}
                                        type="button"
                                        onClick={clearImage}
                                        executingChildren={<><Spinner size="sm" /> {t('editNewsItem.clearingImage', 'Clearing image...')}</>}>
                                        {t('editNewsItem.clearImageButton', 'Clear image')}
                                    </ButtonAsync>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Label for="content">{t('editNewsItem.descriptionHtml.label', 'Thumbnail summary')}</Label>
                        <HtmlEditor
                            size="sm"
                            value={model?.descriptionHtml ?? ''}
                            onChange={(value: string) => change({ descriptionHtml: value })}
                        />
                        <FormText>
                            {t('editNewsItem.descriptionHtml.hint', 'This is a short summary to be shown on the news page along with the headline and thumbnail image.  The full contents of the article should be written in the Article body.')}
                        </FormText>
                    </FormGroup>

                    <FormGroup>
                        <Label for="content">{t('editNewsItem.contentsType.label', 'Type of news article')}</Label>
                        <ValidatedInput name="contentsType" type="select" value={model?.contentsType ?? ''} onChange={e => change({ contentsType: e.currentTarget.value })} onBlur={e => validate('contentsType')} validationErrors={validationErrors['contentsType']}>
                            {
                                Object.keys(ContentsType).map(item => (
                                    <option key={item} value={item}>{contentsTypeDisplayName(item as ContentsType, t)}</option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>

                    {
                        model?.contentsType === ContentsType.Html ? (
                            <>
                                <FormGroup>
                                    <Label for="content">{t('editNewsItem.contentHtml.label', 'Article body')}</Label>
                                    <HtmlEditor
                                        size="xl"
                                        value={model?.contentsHtml ?? ''}
                                        onChange={(value: string) => change({ contentsHtml: value })}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="name">{t('editNewsItem.contentsBlob.label.link', 'Link to document or download')}</Label>
                                    <UploadedFilePreview blobReference={contentsFile} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editNewsItem.uploadFileButtonText', 'Upload a document or file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editNewsItem.deletingFile', 'Deleting...')}</>}>
                                                    {t('editNewsItem.clearFileButton', 'Delete document or file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <FormText>
                                        {t('editNewsItem.contentsBlob.hint.link', 'You can upload and link a document or file here if you would like to give the reader a chance to view to the original source or a related download.')}
                                    </FormText>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="originalSourceUrl">{t('editNewsItem.originalSourceUrl.label', 'Link to external URL')}</Label>
                                    <ValidatedInput name="originalSourceUrl" type="url"
                                        placeholder={t('common.urlPlaceholder', 'https://')}
                                        value={model?.originalSourceUrl ?? ''} onChange={e => change({ originalSourceUrl: e.currentTarget.value })} onBlur={e => validate('originalSourceUrl')} validationErrors={validationErrors['originalSourceUrl']} />
                                    <FormText>
                                        {t('editNewsItem.originalSourceUrl.hint', 'You can link an external URL here if you would like to give the reader a chance to go to the original source or somewhere to find out more information.')}
                                    </FormText>
                                </FormGroup>
                            </>
                        )
                            : model?.contentsType === ContentsType.Document ? (
                                <>
                                    <FormGroup>
                                        <Label for="name">{t('editNewsItem.contentsBlob.label.main', 'Document or file with article contents')}</Label>
                                        <UploadedFilePreview blobReference={contentsFile} />
                                        <Row>
                                            <Col>
                                                <ButtonGroup>
                                                    <FileUploadButton
                                                        color={`primary`}
                                                        isExecuting={isUploadingContentsFile}
                                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Uploading...')}</>}
                                                        onUpload={onUploadContentsFile}
                                                        outline={false}>
                                                        {t('editNewsItem.uploadFileButtonText', 'Upload a document or file')}
                                                    </FileUploadButton>
                                                    <ButtonAsync color="primary"
                                                        outline
                                                        isExecuting={isClearingContentsFile}
                                                        type="button"
                                                        onClick={clearContentsFile}
                                                        executingChildren={<><Spinner size="sm" /> {t('editNewsItem.deletingFile', 'Deleting...')}</>}>
                                                        {t('editNewsItem.clearFileButton', 'Delete document or file')}
                                                    </ButtonAsync>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormText>
                                        {t('editNewsItem.contentsBlob.hint.main', 'You can upload and link document or file here in its original format (e.g. a PDF document) which will open directly when the user clicks on the news article.')}
                                    </FormText>
                                </>
                            )
                                : null
                    }



                </Form>
            </MainContainer>
        </>
    );
};