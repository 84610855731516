import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateNewsItemMutation, CreateNewsItemMutationVariables, NewsItemCreateInput } from '../generated/graphql';
import { newsItemFieldsFragment } from '../models/NewsItem';

/**
 * Get a callback to create a Profile in the store.
 */
export function useCreateNewsItemMutation(): [(model: NewsItemCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateNewsItemMutation, CreateNewsItemMutationVariables>(
        gql`
            mutation createNewsItemMutation ($model: NewsItemCreateInput!) {
                createNewsItem(model: $model) {
                    ...newsItemFields
                }
            }

            ${newsItemFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: NewsItemCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
