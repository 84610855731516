import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useViewDocumentViewModel } from "../../../api/main/documents/viewModels/useViewDocumentViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { generatePrettyUrl } from "../../../utilities/generatePrettyContentUrl";
import { BodyBackground } from "../../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { DocumentPartSection } from "./DocumentPartSection";
import { TableOfContents } from "./TableOfContents";
import "./viewDocument.scss";

/**
 * Page for viewing a Document.
 */
export const ViewDocument = () => {
    const {
        id,
        documentPartId: scrollToDocumentPartId,
    } = useParams<{ id: string | undefined, documentPartId: string | undefined, }>();

    const { t } = useTranslation();

    // Load all data.
    const {
        data: {
            model,
            documentParts,
        },
        errors: loadErrors, isLoading,
    } = useViewDocumentViewModel(id);

    // Get all the parts that should be displayed at the top level.
    const topLevelParts = useMemo(() => documentParts?.filter(item => !item.parentDocumentPartId || item.parentDocumentPartId === Guid.empty), [documentParts]);

    // Get the base route path, so the Table of Contents can generate child links.
    const baseRoutePath = useMemo(() =>
        !!model ? generatePrettyUrl(`/documents/`, model) : ''
        , [model]);


    // Full URL for viewing the original document.
    const fullDownloadUrl = useMemo(() => {
        const url = model?.originalSourceUrl;

        if (!url) {
            return '';
        }

        if (url.indexOf('://') !== -1) {
            return url;
        }

        return `http://${url}`;
    }, [model?.originalSourceUrl]);

    // Allow the toggling of the showing of a changelog or not.
    const [isShowingChangeLog, toggleShowChangeLog] = useToggleState(false);

    // Render the UI.
    //
    return (
        <>
            <BodyBackground background="documents" />

            <MainContainer className="view-document">
                {/* Title - note this is not within a banner. */}
                <Row>
                    <Col>
                        <div className="view-document-title">
                            <h1>
                                {model?.name}
                            </h1>
                        </div>

                        {
                            model?.publishDate ? (
                                <div className="view-document-date">
                                    <Row>
                                        <Col>
                                            {t('documentCard.versionNumber', 'Version {{version}}', { version: model.version, })}
                                            <> </>
                                            <Button color="link" size="sm" style={{ fontSize: '90%',}} onClick={e => { e.preventDefault(); toggleShowChangeLog(); }}>
                                                {t('viewDocument.viewVersionHistory', 'View summary of changes')}
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={['far', 'clock']} />
                                            <> </>
                                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model.publishDate) })}
                                        </Col>
                                    </Row>
                                </div>
                            ): null
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <AlertOnErrors errors={[
                    loadErrors,
                ]} />

                {/* Include the changelog if it has been turned on */}
                {
                    isShowingChangeLog ? (
                        <Card body className="mb-2">
                            <div>
                                <strong>
                                    {t('viewDocuments.changeLogTitle', 'Changes for version {{version}}:', { version: model?.version })}
                                </strong>
                            </div>
                            <HtmlDisplay html={model?.versionChangesHtml ?? ''} />
                        </Card>
                    ): null
                }

                <Card body className="mb-3">
                    <Row>
                        {/* Image if we have one */}
                        {
                            model?.blobReference?.url ? (
                                <Col>
                                    <Row className="view-document-image-container">
                                        <Col></Col>
                                        <Col xs="auto">
                                            {/* Cover image */}
                                            <img className="img-fluid view-document-image" src={model?.blobReference?.url ?? ''} alt={t('viewDocument.image.altText', 'Cover image')} />
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Col>
                            ) : null
                        }

                        {/* Table of contents and download link */}
                        {
                            (model?.tableOfContentLevels ?? 0) > 0 || !!fullDownloadUrl ? (
                                <Col xs={12} lg={6}>
                                    <TableOfContents
                                        documentParts={documentParts ?? []}
                                        maxDepth={model?.tableOfContentLevels ?? 1}
                                        documentRoutePath={baseRoutePath}
                                    />

                                    {/* View document link, if we have one */}
                                    {
                                        model?.contentsBlobReference?.url ? (
                                            <div className="view-news-link">
                                                <a href={model?.contentsBlobReference?.url} target="_blank" rel="noreferrer">
                                                    {t('viewDocument.downloadLinkText', 'You can also download a copy of "{{ name }}" in its original format.', {name: model?.name, })}
                                                </a>
                                            </div>
                                        ) : null
                                    }

                                    {/* Link to download original document if available. */}
                                    {
                                        !!fullDownloadUrl ? (
                                            <div className="mt-2 mb-2">
                                                <small>
                                                    <a href={fullDownloadUrl} download>{t('viewDocument.downloadLinkText', 'You can also download a copy of "{{name}}" in its original format.', { name: model?.name, })}</a>
                                                </small>
                                            </div>
                                        ) : null
                                    }
                                </Col>
                                ): null
                        }
                    </Row>
                </Card>
                
                

                {/* Actual contents of the document (rendered recursivly) */}
                {
                    topLevelParts?.map((item) => (
                        <DocumentPartSection
                            key={item.id}
                            model={item}
                            allDocumentParts={documentParts ?? []}
                            scrollToDocumentPartId={scrollToDocumentPartId}
                        />
                    ))
                }
            </MainContainer>
        </>
    );
};