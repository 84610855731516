import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useCurrentUserId } from "../../api/account";
import { useLinksViewModel } from "../../api/main/externalSites/viewModels/useLinksViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { TagFilterAndSearch } from "../tags/tagFilterAndSearch/TagFilterAndSearch";
import { ExternalSiteCard } from "./ExternalSiteCard";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";

/**
 * Main Links page.
 */
export const Links = () => {
    // Get the tag to filter from off the url.
    const {
        tagId: filterByTagId
    } = useParams<{ tagId: string | undefined }>();

    const { t } = useTranslation();

    const currentUserId = useCurrentUserId();

    // Load data for this user.
    const {
        data: {
            items: allItems,
            tags: allTags,
        },
        errors: loadErrors,
        isLoading,
    } = useLinksViewModel(currentUserId);

    // Searching and filtering
    //
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Manage the URL to match the search.
    useReplaceSearchParamsEffect({ search: search });

    // Filter items by the topic and search results.
    const filteredItems = useMemo(() => {
        let ret = allItems ?? [];

        // Filter by the selected tag (from the route).
        if (filterByTagId) {
            ret = ret.filter(site => {
                const match = site.tagLinks.find(link => link.tagId === filterByTagId);
                return !!match;
            });
        }

        // Filter by the user's search input.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.url.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.searchText.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, filterByTagId, search]);


    // For filtering, we only want to show the tags that we actually have content for.
    const avaialbleTags = useMemo(() => {
        return allTags?.filter(tag => {
            return !!allItems.find(newsItem => !!newsItem.tagLinks.find(link => link.tagId === tag.id));
        });
    }, [allItems, allTags]);

    return (
        <>
            <BodyBackground background="links" />

            {/* NOTE we have decided on no banners for this main pages so its not a mistake they are missing. */}
            <MainContainer color="transparent">
                <TagFilterAndSearch
                    staticLinksBefore={[
                        { text: t('links.allLinks', 'All links'), url: '/links' }
                    ]}
                    tagLinkPrefix={'/links/category/'}
                    tags={avaialbleTags ?? []}
                    searchText={search}
                    setSearchText={setSearch}
                />

                <AlertOnErrors errors={[loadErrors]} />

                <Row>
                    {
                        filteredItems?.map((item) => (
                            <Col key={item.id} xs={12} lg={6} className="mb-2">
                                <ExternalSiteCard model={item} />
                            </Col>
                        ))
                    }
                </Row>

                {/* Show a flashing dot at the bottom if we are loading. */}
                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator size="sm" fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};