import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap";
import { ContentsType } from "../../api/main/models/codeOnly/ContentsType";
import { Document } from "../../api/main/models/Document";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { generatePrettyUrl } from "../../utilities/generatePrettyContentUrl";
import "./documentCard.scss";

/**
 * DocumentItem model with the extras we need to render a card.
 */
export interface DocumentForDocumentCard extends Document {
    blobReference?: {
        id: string,
        url: string,
    } | null | undefined,

    contentsBlobReference?: {
        id: string,
        url: string,
    } | null | undefined,
}

export interface DocumentCardProps {
    model: DocumentForDocumentCard,
}

/**
 * Card for showing a Document article.
 */
export const DocumentCard = (props: DocumentCardProps) => {
    const {
        model,
    } = props;

    const { t } = useTranslation();

    const url = useMemo(() => generatePrettyUrl('/documents/', model), [model]);

    // Link Wapper is either using routing or an a link.
    const LinkWrapper = useCallback((props: { children: React.ReactNode }) => {
        if (model.contentsType === ContentsType.Document && !!model.contentsBlobReference?.url) {
            return (
                <a href={model.contentsBlobReference?.url} target="_blank" rel="noreferrer" className="news-card-a-no-style">
                    {props.children}
                </a>
            );
        }

        // Otherwise render the article as an app route.
        return (
            <LinkContainer to={url}>
                {props.children}
            </LinkContainer>
        );
    }, [model, url]);

    return (
        <LinkWrapper>
            <Card className="document-card">
                <div className="document-card-name-container">
                    <CardTitle className="document-card-name">
                        <h3>{model.name}</h3>
                    </CardTitle>
                </div>
                <CardImg src={model.blobReference?.url} className="document-card-img" />
                <CardBody className="document-card-body">
                    <div className="document-card-summary">
                        <HtmlDisplay html={model.descriptionHtml} />
                    </div>
                    <div className="document-card-date">
                        <Row>
                            <Col>
                                {t('documentCard.versionNumber', 'Version {{version}}', { version: model.version, })}
                            </Col>
                            <Col xs="auto">
                                <FontAwesomeIcon icon={['far', 'clock']} />
                                <> </>
                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model.publishDate) })}
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </LinkWrapper>
    );
};