import { useAuthenticatedState } from "../../api-authorization/useAuthenticatedState";
import { useUser } from "./useUser";
import { User } from "../models/User";
import { Guid } from "guid-string";

/**
 * Hook to get the current user.
 * 
 * NOTE this is a wrapper around useAuthenticationState() for the commmon use case of just wanting the current user.
 */
export function useCurrentUser(): User | null | undefined {
    const { user: authenticationUserProfile } = useAuthenticatedState({ includeUser: true });
    const { data: { model: user } } = useUser(authenticationUserProfile?.sub ?? Guid.empty, { fetchPolicy: 'cache-first' });
    return user;
}
