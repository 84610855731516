import gql from "graphql-tag";
import { Guid } from "guid-string";
import { FormFieldsFragment } from '../generated/graphql';
import { DisplayOrderGroups } from "./codeOnly/DisplayOrderGroups";

export const formFieldsFragment = gql`
    fragment formFields on Form {
        id
        name
        emailAddress1
        emailAddress2
        emailAddress3
        categories
        descriptionHtml
        displayOrder
        publishDate
        searchText
        archived
        blobReferenceId
    }
`;


export type Form = Omit<FormFieldsFragment, '__typename' | 'form'>;

/**
 * Default values.
 */
export const formDefaultValues = (): Partial<Form> => ({
    id: Guid.newGuid(),
    name: '',
    emailAddress1: '',
    emailAddress2: '',
    emailAddress3: '',
    categories: '',
    archived: false,
    displayOrder: parseInt(DisplayOrderGroups.StandardGroup),
    searchText: ''
});