import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { EditNewsItemViewModelQuery, EditNewsItemViewModelQueryVariables } from '../../generated/graphql';
import { newsItemFieldsFragment } from "../../models/NewsItem";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { audienceLinkFieldsFragment } from '../../models/AudienceLink';
import { tagLinkFieldsFragment } from '../../models/TagLink';

/**
 * Load all data needed for editing of a NewsItem.
 * 
 * Data that is not id specific should be loaded in useEditNewsItemSupportData instead.
 */
export function useEditNewsItemViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditNewsItemViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditNewsItemViewModelQuery, EditNewsItemViewModelQueryVariables>(
        gql`
        query editNewsItemViewModelQuery ($id: ID) {
            model: newsItem (id: $id) {
                ...newsItemFields
            }

            audienceLinks (targetId: $id) {
                ...audienceLinkFields
            }

            tagLinks (targetId: $id) {
                ...tagLinkFields
            }
        }

        ${newsItemFieldsFragment}
        ${audienceLinkFieldsFragment}
        ${tagLinkFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}