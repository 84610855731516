import './header.scss';
import { Nav, Navbar } from 'reactstrap';
import { UserNav } from '../userNav/UserNav';
import { HeaderNavigation } from './HeaderNavigation';
import { AppNavbarBrand } from './appNavbarBrand/AppNavbarBrand';
import { SidebarToggle } from '../sidebar/sidebarToggle/SidebarToggle';
import { HeaderNav } from './headerNav/HeaderNav';

export const Header = () => {
    return (
        <Navbar className="header" container="fluid" fixed="top">
            <SidebarToggle className="header-sidebar-toggle-before-brand" />
            <AppNavbarBrand />
            <SidebarToggle className="header-sidebar-toggle-after-brand" />

            <HeaderNav className="header-menu-nav">
                <HeaderNavigation />
            </HeaderNav>     
            
            <Nav className="header-signin-nav">
                <UserNav />
            </Nav>
            {/*<div className="header-ribbon">*/}
            {/*</div>*/}

            {/*<Nav className="header-language-nav">*/}
            {/*    <NavItem>*/}
            {/*        <LanguageSelect />*/}
            {/*    </NavItem>*/}
            {/*</Nav>*/}
        </Navbar>
    );
};
