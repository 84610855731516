import { TFunction } from "i18next";

/**
 * Content types supported by various content in the site.
 */
export enum ContentsType {
    Html = "Html",
    Document = "Document",
}

/**
 * Return the display text for a ContentsType.
 * @param t
 */
export function contentsTypeDisplayName(type: ContentsType, t: TFunction): string {
    switch (type) {
        case ContentsType.Html: return t('contentsType.html', 'Written within the app');
        case ContentsType.Document: return t('contentsType.document', 'Uploaded document or file');
    }
}
