import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, Form, FormGroup, Label, Row, Spinner, Table } from "reactstrap";
import { useViewFormViewModel } from "../../../api/main/formSubmissions/viewModels/useViewFormViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay, HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { BodyBackground } from "../../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { formSubmissionDefaultValues } from '../../../api/main/models/FormSubmission';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useValidatorCallback } from 'pojo-validator-react';
import { useSaveFormSubmissionMutation } from '../../../api/main/formSubmissions/useSaveFormSubmissionMutation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useCurrentUser } from '../../../api/main/users/useCurrentUser';
import '../completeForm.scss';
import { createServiceProvider } from '../../../configure/configureServices';
import { CompleteFormProps } from '../CompleteForm';
import { useJsonObject } from '../../../shared/useJsonObject';
import { IncidentTypeItem } from './IncidentTypeItem';

/**
 * Page for completing a form.
 */

//export interface CompleteFormProps {
//	isCreate?: boolean,
//	onCreateDefaultValues?: () => Partial<FormSubmission>
//}

interface JsonProperties {
    driverName: string,
    clockNo: string,
    garage: string,
    date: string,
    time: string,
    duty: string,
    route: string,
    direction: string,
    location: string,
    vehicleNumber: string,

    accident: boolean,
    accidentDetails?: string,
    injury: boolean,
    injuryDetails?: string,

    physicalAssault: boolean,
    physicalAssaultDetails?: string,

    missilesThrown: boolean,
    missilesThrownDetails?: string,

    damagedSeat: boolean,
    damagedSeatDetails?: string,

    other: boolean,
    otherDetails?: string,

    policeCalled: boolean,
    policeCalledDetails?: string,

    includesBriefDescription: boolean,
    briefDescription: string,

    includesWitnessInformation: boolean,
    witnessInformation: string,
}


export const IncidentReportForm = (props: CompleteFormProps) => {
    const { id, formId } = useParams<{ id: string | undefined, formId: string | undefined }>();

    const { t } = useTranslation();

    const { isCreate, onCreateDefaultValues } = props;

    const currentUser = useCurrentUser();

    const navigator = useNavigate();

    const [isReadOnly] = React.useState<boolean>(props.readonly ?? false);

    // Load all data.
    const {
        data: {
            model: storeModel, form
        },
        errors: loadErrors, isLoading,
    } = useViewFormViewModel(props.id ?? id, props.formId ?? formId);

    const [saveFormSubmission, { errors: savingErrors, isExecuting: isSaving }] = useSaveFormSubmissionMutation();

    const { model, change, changes } = useChanges(
        storeModel,
        isCreate ?
            { ...formSubmissionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}), formId: props.formId ?? formId } :
            undefined
    );

    React.useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (currentUser.id === model?.userId) {
            return;
        }

        let email = currentUser.email;

        if (email.endsWith(".nctx")) {
            email = '';
        }

        change({ userId: currentUser.id, responseEmail: email });
    }, [currentUser, change, model]);

    const [formProperties, setFormPropertyValue] = useJsonObject<JsonProperties>(!!model && model.jsonValuesString ? model.jsonValuesString : "{}", (json) => change({ jsonValuesString: json }));


    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            responseEmail: () => !model?.responseEmail ? t('formSubmission.errors.responseEmail', 'Response email cannot be empty') : '',
            driverName: () => !formProperties.driverName ? t('incidentReportForm.errors.driverName', 'You must include your name') : '',

        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, formProperties, form]);

    const save = React.useCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        await saveFormSubmission(model.id, changes, isCreate ?? false);

        //Send the submission to the email addresses provided by the form object
        const serviceProvider = createServiceProvider();
        const services = serviceProvider.services();

        await services.apiFetch().post<boolean>(`/api/forms/sendnewsubmissionemails?id=${encodeURI(model.id)}`);

        navigator("/forms/thankyou");
    }, [model, changes, validate, isCreate, saveFormSubmission, navigator]);

    const onChanges = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFormPropertyValue({ ...formProperties, [e.currentTarget.name]: e.currentTarget.value });
    }, [formProperties, setFormPropertyValue]);

    const toggleButtonWrapper = React.useCallback((callback: () => void) => {
        if (isReadOnly) {
            return;
        }
        callback();
    }, [isReadOnly]);

    return (
        <>
            <BodyBackground background="forms" />

            <MainContainer className="complete-form">
                {/* Title - note this is not within a banner. */}
                <Row>
                    <Col>
                        <div className="complete-form-title">
                            <h1>
                                {form?.name ?? ''}
                            </h1>
                        </div>

                        {
                            model ? (
                                <div className="complete-form-date">
                                    <FontAwesomeIcon icon={['far', 'clock']} />
                                    <> </>
                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: !!model.dateCreated ? moment(model.dateCreated) : moment() })}
                                </div>
                            ) : null
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <AlertOnErrors errors={[
                    loadErrors, savingErrors
                ]} />

                {/*Image if we have one */}
                {
                    form?.blobReference?.url ? (
                        <Row className="view-news-image-container">

                            <Col xs="auto">
                                <img className="img-fluid complete-form-image" src={form?.blobReference?.url ?? ''} alt={t('formSubmission.image.altText', 'Picture to support the form')} />
                            </Col>
                        </Row>
                    ) : null
                }

                {/* Main body text */}
                <HtmlDisplay html={form?.descriptionHtml ?? ''} />

                <Form onSubmit={(e) => { e.preventDefault(); save(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>
                                    {t('formSubmission.responseEmail.label', 'Your email address')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="responseEmail" type="email" value={model?.responseEmail ?? ''} onChange={e => change({ responseEmail: e.currentTarget.value })} onBlur={e => validate('responseEmail')} validationErrors={validationErrors['responseEmail']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label>
                                    {t('incidentReportForm.driverDetails.nameLabel', 'Driver name')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="driverName" type="text" value={formProperties.driverName} onChange={onChanges} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label>
                                    {t('incidentReportForm.driverDetails.clockNumberLabel', 'Clock number')}
                                </Label>
                                <ValidatedInput disabled={isReadOnly} name="clockNo" type="text" value={formProperties.clockNo} onChange={onChanges} validationErrors={validationErrors}
                                    onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>



                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{t('incidentReportForm.garage.label', 'Garage')}</Label>
                                <ValidatedInput disabled={isReadOnly} name="garage" type="text" value={formProperties.garage} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>

                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('incidentReportForm.date.label', 'Date')}</Label>
                                <ValidatedInput disabled={isReadOnly} name="date" type="date" value={formProperties.date} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('incidentReportForm.time.label', 'Time')}</Label>
                                <ValidatedInput disabled={isReadOnly} name="time" type="time" value={formProperties.time} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('incidentReportForm.duty.label', 'Duty')}</Label>
                                <ValidatedInput disabled={isReadOnly} name="duty" type="text" value={formProperties.duty} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Label>{t('incidentReportForm.route.label', 'Route')}</Label>
                            <ValidatedInput disabled={isReadOnly} name="route" type="text" value={formProperties.route} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                        </Col>
                        <Col>
                            <Label>{t('incidentReportForm.direction.label', 'Direction')}</Label>
                            <ValidatedInput disabled={isReadOnly} name="direction" type="text" value={formProperties.direction} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                        </Col>
                        <Col>
                            <Label>{t('incidentReportForm.location.label', 'Location')}</Label>
                            <ValidatedInput disabled={isReadOnly} name="location" type="text" value={formProperties.location} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                        </Col>
                        <Col>
                            <Label>{t('incidentReportForm.vehicleNumber.label', 'Vehicle number')}</Label>
                            <ValidatedInput disabled={isReadOnly} name="vehicleNumber" type="text" value={formProperties.vehicleNumber} onChange={onChanges} validationErrors={validationErrors} onBlur={(e) => validate(e.currentTarget.name)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Row>
                                    <Col>
                                        {t('incidentReportForm.incidentType.tableHeading', 'Incident type')}
                                    </Col>
                                </Row>

                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.accident.label', 'Accident')}
                                    isOn={formProperties.accident} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, accident: val }))}
                                    details={formProperties.accidentDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, accidentDetails: val })}
                                    key={1}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.injury.label', 'Injury')}
                                    isOn={formProperties.injury} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, injury: val }))}
                                    details={formProperties.injuryDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, injuryDetails: val })}
                                    key={2}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.physicalAssault.label', 'Physical assault')}
                                    isOn={formProperties.physicalAssault} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, physicalAssault: val }))}
                                    details={formProperties.physicalAssaultDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, physicalAssaultDetails: val })}
                                    key={3}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.missilesThrown.label', 'Missiles thrown')}
                                    isOn={formProperties.missilesThrown} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, missilesThrown: val }))}
                                    details={formProperties.missilesThrownDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, missilesThrownDetails: val })}
                                    key={4}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.damagedSeat.label', 'Damaged seat')}
                                    isOn={formProperties.damagedSeat} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, damagedSeat: val }))}
                                    details={formProperties.damagedSeatDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, damagedSeatDetails: val })}
                                    key={5}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.other.label', 'Other')}
                                    isOn={formProperties.other} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, other: val }))}
                                    details={formProperties.otherDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, otherDetails: val })}
                                    key={6}
                                />
                                <IncidentTypeItem readonly={isReadOnly} name={t('incidentReportForm.policeCalled.label', 'Police called?')}
                                    isOn={formProperties.policeCalled} toggle={(val) => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, policeCalled: val }))}
                                    details={formProperties.policeCalledDetails} changeDetails={(val) => setFormPropertyValue({ ...formProperties, policeCalledDetails: val })}
                                    key={7}
                                />

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>
                                    {t('incidentReportForm.description.label', 'Can a description be given of the person causing the damage')}
                                </Label>
                                <div>
                                    <ButtonGroup>
                                        <Button color="primary" outline active={formProperties.includesBriefDescription} onClick={() => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, includesBriefDescription: true }))}>{t('common.Yes', 'Yes')}</Button>
                                        <Button color="primary" outline active={!formProperties.includesBriefDescription} onClick={() => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, includesBriefDescription: false }))}>{t('common.No', 'No')}</Button>
                                    </ButtonGroup>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={formProperties.includesBriefDescription}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{t('incidentReportForm.details.label', 'Description of person causing damage')}</Label>
                                    {!isReadOnly ?
                                        <HtmlEditor value={formProperties.briefDescription} onChange={(val) => setFormPropertyValue({ ...formProperties, briefDescription: val })} />
                                        : <HtmlDisplay html={formProperties.briefDescription} />
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>
                                    {t('incidentReportForm.witnessInformationToggle.label', 'Were any witnesses obtained')}
                                </Label>
                                <div>
                                    <ButtonGroup>
                                        <Button color="primary" outline active={formProperties.includesWitnessInformation} onClick={() => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, includesWitnessInformation: true }))}>{t('common.Yes', 'yes')}</Button>
                                        <Button color="primary" outline active={!formProperties.includesWitnessInformation} onClick={() => toggleButtonWrapper(() => setFormPropertyValue({ ...formProperties, includesWitnessInformation: false }))}>{t('common.No', 'No')}</Button>
                                    </ButtonGroup>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={formProperties.includesWitnessInformation}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{t('incidentReportForm.witnessInformationDetails.label', 'Details of witnesses:')}</Label>
                                    {!isReadOnly ?
                                        <HtmlEditor value={formProperties.witnessInformation} onChange={(val) => setFormPropertyValue({ ...formProperties, witnessInformation: val })} />
                                        : <HtmlDisplay html={formProperties.witnessInformation} />
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!isReadOnly}>
                        <Row>
                            <Col>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => save()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Submit')}</>
                                </ButtonAsync>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </Form>

            </MainContainer>
        </>
    );
};