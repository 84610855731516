import { useTranslation } from "react-i18next";
import { NavItem } from "reactstrap";
import { NavLinkTrackActive } from "../shared/navLinkTrackActive/NavLinkTrackActive";
import { useIsProfileLockedFromSelfEditing } from "./useIsProfileLockedFromSelfEditing";

/**
 * Navigation under the Profile area.
 */
export const ProfileNavigation = () => {
    const { t } = useTranslation();

    // Some users are not able to change their own details, check if we are one of them.
    const isLockedFromSelfEdit = useIsProfileLockedFromSelfEditing();

    return (
        <>
            <NavItem>
                <NavLinkTrackActive to="details">
                    {t('profile.profile', 'Profile')}
                </NavLinkTrackActive>
            </NavItem>
            {
                !isLockedFromSelfEdit ? (
                    <>
                        <NavItem>
                            <NavLinkTrackActive to="change-email">
                                {t('profile.changeEmail', 'Email')}
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="change-password">
                                {t('profile.changePassword', 'Change Password')}
                            </NavLinkTrackActive>
                        </NavItem>
                    </>
                ): null
            }
        </>
    );
};
