import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, Row, Col, Card, CardHeader, CardBody, Input, Button, FormText } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Link, useNavigate } from "react-router-dom";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { LoadingIndicator } from "../../components/shared/loadingIndicator/LoadingIndicator";
import { useExternalAuthenticationSchemes, useLoginWithPasswordCallback, useStartExternalLoginCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { Login as LoginModel } from '../../api/account/models/Login';
import { useTranslation } from "react-i18next";
import { ExternalLoginButton } from "./ExternalLoginButton";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DriverLogin } from "../../api/account/models/DriverLogin";
import { useLoginAsDriverCallback } from "../../api/account/useLoginAsDriverCallback";
import { LinkContainer } from "react-router-bootstrap";
import { useLoginAsEngineerCallback } from "../../api/account/useLoginAsEngineerCallback";
import { ConditionalFragment } from "react-conditionalfragment";

/**
 * Login screen.
 */
export const Login = () => {
	const { t } = useTranslation();
	const { data: { externalAuthenticationSchemes }, isLoading, errors: loadErrors } = useExternalAuthenticationSchemes();
	const [login, { isExecuting: _isLoggingIn, errors: loginErrors }] = useLoginWithPasswordCallback();
	const [loginAsDriver, { isExecuting: _isLoggingInAsDriver, errors: driverLoginErrors }] = useLoginAsDriverCallback();
	const [loginAsEngineer, { isExecuting: _isLoggingInAsEngineer, errors: engineerLoginErrors }] = useLoginAsEngineerCallback();
	const isLoggingIn = _isLoggingIn || _isLoggingInAsDriver;
	const [startExternalLogin] = useStartExternalLoginCallback();
	const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();
	const [hasSentConfirmationEmail, setHasSentConfirmationEmail] = React.useState<boolean>(false);

	const { model, change } = useChanges<LoginModel>({ email: '', password: '', rememberMe: true });
	const { model: driverModel, change: changeDriverModel } = useChanges<DriverLogin>({ surname: '', secret: '', secret2: '', rememberMe: true });

	// Need the URL to pass on to registration or 2faif we redirect to it.
	const params = new URLSearchParams(window.location.search);
	const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';

	/**
	 * Validate the model before trying to use it.
	 */
	const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			email: () => !model.email ? t('login.emailRequired', 'Email is required') : '',
			password: () => !model.password ? t('login.passwordRequired', 'Password is required') : '',
		};

		validation.checkRules(rules, fieldsToCheck);
	}, [model]);

	/**
	 * Perform a login by and handle the result.
	 */
	const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
	const performEmailLogin = React.useCallback(async (): Promise<void> => {
		if (!validate()) {
			return;
		}

		let result = await login(model);

		if (result) {
			setIsPendingEmailConfirmation(result.requiresEmailConfirmation);

			if (result.requiresTwoFactor) {
				navigate(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
			}

			// Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
			if (result.succeeded) {
				// Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
				if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
					setIsDoingFullPageRedirect(true);
					window.location.href = result.returnUrl;
				}
			}
		}
	}, [login, model, setIsPendingEmailConfirmation, validate, setIsDoingFullPageRedirect, navigate]);

	// Validate a driver.
	const [validateDriver, driverValidationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			surname: () => !driverModel.surname ? t('driverModel.validation.surname.required', 'Surname is required') : '',
			secret: () => !driverModel.secret ? t('driverModel.validation.secret.required', 'Personal reference number is required') : '',
			secret2: () => !driverModel.secret2 ? t('driverModel.validation.secret2.required', 'Ticket machine PIN is required') : '',
		};

		validation.checkRules(rules, fieldsToCheck);
	}, [driverModel]);

	const [validateEngineer, engineerValidationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			surname: () => !driverModel.surname ? t('driverModel.validation.surname.required', 'Surname is required') : '',
			secret: () => !driverModel.secret ? t('driverModel.validation.secret.required', 'Personal reference number is required') : '',
			secret2: () => !driverModel.secret2 ? t('driverModel.validation.secret2.required', 'PIN is required') : '',
		};

		validation.checkRules(rules, fieldsToCheck);
	}, [driverModel]);

	/**
	 * Perform a driver login.
	 */
	const performDriverLogin = React.useCallback(async (): Promise<void> => {
		if (!validateDriver()) {
			return;
		}

		let result = await loginAsDriver(driverModel);

		if (result) {
			if (result.requiresTwoFactor) {
				navigate(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
			}

			// Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
			if (result.succeeded) {
				// Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
				if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
					setIsDoingFullPageRedirect(true);
					window.location.href = result.returnUrl;
				}
			}
		}
	}, [loginAsDriver, driverModel, validateDriver, navigate, setIsDoingFullPageRedirect,]);

	/*
	* Perform an enginner login
	*/
	const performEngineerLogin = React.useCallback(async (): Promise<void> => {
		if (!validateEngineer()) {
			return;
		}

		let result = await loginAsEngineer(driverModel);

		if (result) {
			if (result.requiresTwoFactor) {
				navigate(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
			}

			// Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
			if (result.succeeded) {
				// Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
				if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
					setIsDoingFullPageRedirect(true);
					window.location.href = result.returnUrl;
				}
			}
		}
	}, [loginAsEngineer, driverModel, validateEngineer, navigate, setIsDoingFullPageRedirect,]);

	// Sign in style being used.
	const [signInStyle, setSignInStyle] = React.useState<'driver' | 'email' | 'engineer' | null>(null);
	// Allow the toggling of signInStyle, i.e. clicking the button twice will clear the style back to null.
	const toggleSignInStyle = React.useCallback((style: 'driver' | 'email' | 'engineer' | null) => {
		setSignInStyle(prevState => {
			if (prevState === style) {
				return null;
			}

			return style;
		});
	}, [setSignInStyle]);

	// Login using the right style.
	const performLogin = React.useCallback(async () => {
		switch (signInStyle) {
			case 'driver':
				await performDriverLogin();
				break;
			case 'engineer':
				await performEngineerLogin();
				break;
			case 'email':
			default:
				await performEmailLogin();
				break;
		}
	}, [signInStyle, performDriverLogin, performEngineerLogin, performEmailLogin]);

	// Render the UI.
	return (
		<MainContainer color="transparent" centerChildren="vertically">
			<Card color="">
				<CardHeader>
					<h1>
						{t('login.signInHeading', 'Sign in')}
					</h1>
				</CardHeader>
				<CardBody>
					{/* Login type choice buttons */}
					<div className="mb-2">
						<LinkContainer to="/account/saml2-login" style={{ width: '100%' }}>
							<Button outline className="mb-1" style={{ width: '100%' }}>
								<FontAwesomeIcon icon="desktop" />
								<> </>
								{t('login.signInOptions.microsoftAccount.main', 'Sign in with your nctx.co.uk email')}
								<div className="text-muted" style={{ fontSize: '0.7rem' }}>
									{t('login.signInOptions.microsoftAccount.small', 'For use by all staff who have an @nctx.co.uk email or account')}
								</div>
							</Button>
						</LinkContainer>

						<Button outline={signInStyle !== 'driver'} className="mb-1" style={{ width: '100%' }} onClick={() => toggleSignInStyle('driver')}>
							<FontAwesomeIcon icon="bus" />
							<> </>
							{t('login.signInOptions.driver.main', 'Sign in as a driver')}
							<div className="text-muted" style={{ fontSize: '0.7rem' }}>
								{t('login.signInOptions.driver.small', 'For use by all drivers')}
							</div>
						</Button>

						<Button outline={signInStyle !== 'engineer'} className="mb-1" style={{ width: '100%' }} onClick={() => toggleSignInStyle('engineer')}>
							<FontAwesomeIcon icon="clipboard-user" />
							<> </>
							{t('login.signInOptions.engineer.main', 'Sign in as engineering staff')}
							<div className="text-muted" style={{ fontSize: '0.7rem' }}>
								{t('login.signInOptions.engineer.small', 'For use by engineering staff who do not have an @nctx.co.uk email')}
							</div>
						</Button>

						<Button outline={signInStyle !== 'email'} className="mb-1" style={{ width: '100%' }} onClick={() => toggleSignInStyle('email')}>
							<FontAwesomeIcon icon="envelope" />
							<> </>
							{t('login.signInOptions.email', 'Sign in with your email')}
							<div className="text-muted" style={{ fontSize: '0.7rem' }}>
								{t('login.signInOptions.email.small', 'For invited users without @nctx.co.uk emails only')}
							</div>
						</Button>

					</div>

					{/* Main login forms */}
					<AlertOnErrors simple errors={[loadErrors, loginErrors, driverLoginErrors, engineerLoginErrors, resendConfirmationEmailErrors]} />
					{
						/* No chosen sign in method yet */
						!signInStyle ? null
							/* Email login */
							: signInStyle === 'email' ? (
								<Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
									{
										isPendingEmailConfirmation ?
											hasSentConfirmationEmail ? (
												<Alert color="success" >
													<>{t('login.confirmationEmailHasBeenResent', 'Confirmation link to verify the email for this account has been resent.  Please check your email to confirm.')} </>
													<ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
														isExecuting={isResendingConfirmationEmail}
														executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
														{t('common.resendEmail', 'Resend email')}
													</ButtonAsync>
												</Alert>
											) : (
												<Alert color="success">
													<>{t('login.mustConfirmEmailBeforeLogin', 'You need to confirm your account before you can sign in.  Please check your email.')} </>
													<ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
														isExecuting={isResendingConfirmationEmail}
														executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
														{t('common.resendEmail', 'Resend email')}
													</ButtonAsync>
												</Alert>
											) : null
									}
									<FormGroup>
										<Label htmlFor="email">{t('login.email', 'Email')}</Label>
										<ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
									</FormGroup>
									<FormGroup>
										<Label htmlFor="password">{t('login.password', 'Password')}</Label>
										<ValidatedInput type="password" name="password" autoComplete="current-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
									</FormGroup>
									<FormGroup switch>
										<Input id="rememberMe" name="rememberMe" type="switch" checked={model.rememberMe} onChange={e => change({ rememberMe: e.currentTarget.checked })} />
										<> </>
										{t('login.rememberMe', 'Remember me on this device?')}
									</FormGroup>

									<FormButtons>
										<Row>
											<Col xs="auto">
												<Link to={'/account/forgotPassword'}>
													{t('login.forgotYourPassword', 'Forgotten your password?')}
												</Link>
											</Col>
											<Col>
												<ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
													executingChildren={<><Spinner size="sm" /> {t('login.loggingIn', 'Signing in...')}</>}>
													{t('login.signIn', 'Sign in')}
												</ButtonAsync>
											</Col>
										</Row>

									</FormButtons>

									<div>
										{
											isLoading ? (
												<LoadingIndicator />
											) : (
												<>
													<div>
														{
															!externalAuthenticationSchemes ? null
																: externalAuthenticationSchemes.length !== 0 ? (
																	<>
																		<h6>{t('login.useExternalService', 'Or sign in using an identity provider')}</h6>
																		{
																			externalAuthenticationSchemes.map((item) => (
																				<ExternalLoginButton key={item.name} type="button" provider={item.name} providerDisplayName={item.displayName} onClick={() => startExternalLogin(item.name, returnUrl)} />
																			))
																		}
																	</>
																)
																	: null
														}
													</div>
												</>
											)
										}
									</div>
								</Form>
							)
								/* Driver login */
								: (
									<Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
										<AlertOnErrors simple errors={[loadErrors, loginErrors, resendConfirmationEmailErrors]} />

										<FormGroup>
											<Label htmlFor="email">{t('login.driver.surname.label', 'Surname')}</Label>
											<ConditionalFragment showIf={signInStyle === 'driver'}>
												<ValidatedInput type="text" name="surname" autoComplete="" value={driverModel.surname} onChange={e => changeDriverModel({ surname: e.currentTarget.value })} onBlur={e => validateDriver('surname')} validationErrors={driverValidationErrors['surname']} />
											</ConditionalFragment>
											<ConditionalFragment showIf={signInStyle === 'engineer'}>
												<ValidatedInput type="text" name="surname" autoComplete="" value={driverModel.surname} onChange={e => changeDriverModel({ surname: e.currentTarget.value })} onBlur={e => validateEngineer('surname')} validationErrors={engineerValidationErrors['surname']} />
											</ConditionalFragment>
										</FormGroup>
										<FormGroup>
											<Label htmlFor="secret">{t('login.driver.personalReferenceNumber.label', 'Clock number / Personal reference number')}</Label>
											<ConditionalFragment showIf={signInStyle === 'driver'}>
												<ValidatedInput type="text" name="secret" autoComplete="" value={driverModel.secret} onChange={e => changeDriverModel({ secret: e.currentTarget.value })} onBlur={e => validateDriver('secret')} validationErrors={driverValidationErrors['secret']} />
											</ConditionalFragment>
											<ConditionalFragment showIf={signInStyle === 'engineer'}>
												<ValidatedInput type="text" name="secret" autoComplete="" value={driverModel.secret} onChange={e => changeDriverModel({ secret: e.currentTarget.value })} onBlur={e => validateEngineer('secret')} validationErrors={engineerValidationErrors['secret']} />
											</ConditionalFragment>
											<FormText>
												{t('login.driver.personalReferenceNumber.hintText', 'This information can be found on your payslip and is the same as you use to login in for your duties at the terminal.')}
											</FormText>
										</FormGroup>
										<FormGroup>
											<Label htmlFor="secret">{signInStyle === 'driver' ? t('login.driver.ticketMachinePIN.label', 'Ticket machine PIN') : t('login.engineer.pin.label', 'Your PIN number')}</Label>
											<ConditionalFragment showIf={signInStyle === 'driver'}>
												<ValidatedInput type="text" name="secret2" autoComplete="" value={driverModel.secret2} onChange={e => changeDriverModel({ secret2: e.currentTarget.value })} onBlur={e => validateDriver('secret2')} validationErrors={driverValidationErrors['secret2']} />
											</ConditionalFragment>

											<ConditionalFragment showIf={signInStyle === 'engineer'}>
												<ValidatedInput type="text" name="secret2" autoComplete="" value={driverModel.secret2} onChange={e => changeDriverModel({ secret2: e.currentTarget.value })} onBlur={e => validateEngineer('secret2')} validationErrors={engineerValidationErrors['secret2']} />
											</ConditionalFragment>

											<FormText>
												{signInStyle === 'driver' ?
													t('login.driver.ticketMachinePIN.hintText', 'This is the PIN you enter in to your ticket machines to log in.') :
													t('login.driver.PIN.hintText', 'This is the PIN chose when you first logged in. If this is the first time logging in, then please choose a PIN number and remember it for future logins.')
												} { }
											</FormText>
										</FormGroup>
										<FormGroup switch>
											<Input id="rememberMe" name="rememberMe" type="switch" checked={model.rememberMe} onChange={e => change({ rememberMe: e.currentTarget.checked })} />
											<> </>
											{t('login.driver.rememberMe.label', 'Remember me on this device?')}
										</FormGroup>

										<FormGroup className="mt-2">
											<FormText>
												{t('login.driver.troubleLoggingIn.before', 'Having trouble logging in?  Contact ')}
												<a href="mailto:marketing@nctx.co.uk&subject=Go2%20driver%20login%20help">marketing@nctx.co.uk</a>
												{t('login.driver.troubleLoggingIn.after', ' for help.')}
											</FormText>
										</FormGroup>

										<FormButtons>
											<Row>
												<Col>
													<ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
														executingChildren={<><Spinner size="sm" /> {t('login.loggingIn', 'Signing in...')}</>}>
														{t('login.signIn', 'Sign in')}
													</ButtonAsync>
												</Col>
											</Row>
										</FormButtons>
									</Form>
								)
					}

				</CardBody>
			</Card>
		</MainContainer>
	);
};
