import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { EditExternalSiteSupportingDataQuery } from '../../generated/graphql';
import { tagFieldsFragment } from '../../models/Tag';
import { audienceFieldsFragment } from '../../models/Audience';

/**
 * Get a the supporting data needed to interactivly edit an ExternalSite.
 * 
 * This is seperate to useEditExternalSiteViewModel as the data returned here can be shared across all ExternalSites and is not Id specific.
 */
export function useEditExternalSiteSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<EditExternalSiteSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditExternalSiteSupportingDataQuery>(
        gql`
        query editExternalSiteSupportingDataQuery {
            audiences {
                ...audienceFields
            },

            tags {
                ...tagFields
            }
        }
        ${audienceFieldsFragment}
        ${tagFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
