import { RouteEntry } from "../../shared/routes";
import { Forms } from "./Forms";
import { CompleteForm } from './CompleteForm';
import { Thankyou } from "./Thankyou";
//import { ViewIFrameLink } from "./ViewIFrameLink";

export const formsRoutes: Array<RouteEntry> = [
    { path: '/forms', element: <Forms />, authorize: true },

    // View forms by tag.
    { path: '/forms/category/:ssoFriendly/:tagId', element: <Forms />, authorize: true },
    { path: '/forms/category/:tagId', element: <Forms />, authorize: true },

    // Complete or view forms
    { path: '/forms/:ssoFriendly/:formId', element: <CompleteForm isCreate={true} />, authorize: true },
    { path: '/forms/:ssoFriendly/:formId/:id', element: <CompleteForm />, authorize: true },
    { path: '/forms/thankyou', element: <Thankyou />, authorize: true },
];
