import { NavItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavLinkTrackActive } from '../../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Footer navigation menu for the app. 
 */
export const FooterNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <a className="nav-link" href="https://www.nctx.co.uk/" target="_blank" rel="noreferrer">
                    <div className="footer-logo"></div>
                </a>
            </NavItem>
            {/*<NavItem>
                <NavLinkTrackActive to="/documents/terms-and-conditions">
                    {t('footerNavigation.termsAndConditions', 'Terms and conditions')}
                </NavLinkTrackActive>
            </NavItem>
            <NavItem>
                <NavLinkTrackActive to="/documents/privacy-policy">
                    {t('footerNavigation.privacyPolicy', 'Privacy policy')}
                </NavLinkTrackActive>
            </NavItem>*/}
        </>
    );
};
