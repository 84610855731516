import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";
import { MainContainer } from "../shared/mainContainer/MainContainer";
/**
 * Page for completing a form.
 */

export const Thankyou = () => {


	const navigator = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			<BodyBackground background="forms" />

			<MainContainer className="view-news">
				{/* Title - note this is not within a banner. */}
				<Row>
					<Col>
						<div className="view-news-title">
							<h1>
								{t('formThankyou.title', 'Form completed')}
							</h1>
						</div>
					</Col>
				</Row>

				<div>
					<p>
						{t('formThankyou.message', 'Thank you for completing the form. It has been submitted to the department with responsibility for your message and they will be in contact with you shortly.')}
					</p>
				</div>
			</MainContainer>
		</>
	);
};