import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, Button, FormText, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useResetPasswordCallback } from "../../api/account";
import { ResetPassword as ResetPasswordModel } from '../../api/account/models/ResetPassword';
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { LinkContainer } from "react-router-bootstrap";
import { usePasswordValidation } from "../../shared/passwordValidation";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Reset the password (after following a unique link from an email).
 */
export const ResetPassword = () => {
    const { t } = useTranslation();
    const [hasResetPassword, setHasResetPassword] = React.useState<boolean>(false);
    const [resetPassword, { isExecuting: isResettingPassword, errors: resetPasswordErrors }] = useResetPasswordCallback();
    const { checkPassword, passwordRequirements } = usePasswordValidation();

    const { model, change } = useChanges<ResetPasswordModel>({ password: '', confirmPassword: '' });
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            password: () => !model.password ? t('resetPassword.passwordRequired', 'Password is required')
                : checkPassword(model.password).errorDescription,
            confirmPassword: () => model.confirmPassword !== model.password ? t('register.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);
 
    // Render the UI.
    //

    // If we're not ready, show the loading indicator.
    if (!model) {
        return (<LoadingIndicator />);
    }

    return (
        <MainContainer color="transparent" centerChildren="vertically">
            <Card color="">
                <CardHeader>
                    <h1>{t('resetPassword.resetPasswordHeading', 'Reset Password')}</h1>
                </CardHeader>
                <CardBody>
                    <p>
                        {t('resetPassword.thanksForConfirmingEmail', 'Thanks for confirming your email.  You can now set a new password.')}
                    </p>

                    <Form onSubmit={async e => {
                        e.preventDefault();
                        if (!validate()) {
                            return;
                        }

                        const ok = await resetPassword(model.password);
                        setHasResetPassword(ok);
                    }}>
                        {
                            resetPasswordErrors ? (
                                <Alert color="danger">
                                    {resetPasswordErrors}
                                </Alert>
                            ) : null
                        }
                        {
                            hasResetPassword ? (
                                <Alert color="success">
                                    <Row>
                                        <Col>
                                            {t('resetPassword.passwordHasBeenReset', 'Your password has been reset.')}
                                        </Col>
                                        <Col xs="auto">
                                            <LinkContainer to="/authentication/login">
                                                <Button color="success">
                                                    {t('confirmEmail.signInToContinue', 'Please sign in to continue')}
                                                </Button>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                </Alert>
                            ) : null
                        }

                        <FormGroup>
                            <Label htmlFor="password">{t('resetPassword.password', 'Password')}</Label>
                            <ValidatedInput type="password" name="password" autoComplete="new-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
                            <FormText color="white">
                                {passwordRequirements}
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="confirmPassword">{t('resetPassword.confirmPassword', 'Confirm password')}</Label>
                            <ValidatedInput type="password" name="confirmPassword" autoComplete="new-password" value={model.confirmPassword} onChange={e => change({ confirmPassword: e.currentTarget.value })} onBlur={e => validate('confirmPassword')} validationErrors={validationErrors['confirmPassword']} />
                        </FormGroup>
                        <FormGroup>
                            <FormButtons>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col xs="auto">

                                        <ButtonAsync type="submit" color="primary" isExecuting={isResettingPassword}
                                            executingChildren={<><Spinner size="sm" /> {t('resetPassword.resetting', 'Resetting password...')}</>}>
                                            {t('resetPassword.reset', 'Reset password')}
                                        </ButtonAsync>
                                    </Col>
                                </Row>
                            </FormButtons>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </MainContainer>
    );
};
