import * as React from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TwoValueSwitch } from '../twoValueSwitch/TwoValueSwitch';
import moment from 'moment';
import { ISODateTimeInput } from '../isoDateTimeInput/ISODateTimeInput';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface PublishDateHandlerComponentProps {
    model: any,
    change: (changes: Partial<any>) => void,

}

/**
 * A component for handling the publish date and the state of items.
 * @param props
 */
export const PublishDateHandlerComponent = (props: PublishDateHandlerComponentProps) => {
    const {
        model,
        change,

    } = props

    const { t } = useTranslation();

    const hasPublishDate = React.useMemo(() => !!model?.publishDate, [model]);

    return (
        <Row>
            <Col xs="auto">
                <FormGroup>
                    <Label htmlFor="published">{t('publishDateHandlerComponent.published', 'Published?')}</Label>
                    <TwoValueSwitch
                        leftLabel={t('common.no', 'No')}
                        rightLabel={t('common.yes', 'Yes')}
                        checked={hasPublishDate}
                        onChange={checked => {
                            // If we don't have a publish date when we become checked, set it to now.
                            if (checked) {
                                if (!hasPublishDate) {
                                    change({ publishDate: moment().toISOString(), });
                                }
                            } else {
                                // Clear the publish date
                                change({ publishDate: null, });
                            }
                        }} />
                </FormGroup>
            </Col>
            <ConditionalFragment showIf={hasPublishDate}>
                <Col>
                    <FormGroup>
                        <Label htmlFor="publishDate">{t('publishDateHandlerComponent.publishDate', 'Published on date')}</Label>
                        <ISODateTimeInput type="date"
                            value={model.publishDate}
                            onChange={e => change({ publishDate: e.target.value })}
                        />
                    </FormGroup>
                </Col>
            </ConditionalFragment>
        </Row>
    )
}