import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Row } from "reactstrap";
import { useCurrentUserId } from "../../api/account/useCurrentUserId";
import { useHomeViewModel } from "../../api/main/home/viewModels/useHomeViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { DocumentCard } from "../documents/DocumentCard";
import { ExternalSiteCard } from "../externalSites/ExternalSiteCard";
import { NewsCard } from "../newsItems/NewsCard";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { InstallPwaPrompt } from "../shared/pwaInstall/InstallPwaPrompt";
import "./home.scss";

/**
 * Default / home page.
 */
export const Home = () => {
    const { t } = useTranslation();

    const currentUserId = useCurrentUserId();

    // Load data for this user.
    const {
        data: {
            newsItems,
            externalSites,
            documents,
        },
        errors: loadErrors,
        isLoading,
    } = useHomeViewModel(currentUserId);

    // Carousel management.
    const [newsCarouselActiveIndex, setNewsCarouselActiveIndex] = useState<number>(0);
    // Navigate the carousel.
    const navigateNewsCarousel = useCallback((change: number) => {
        setNewsCarouselActiveIndex(prevState => {
            // If we have no news just keep the index at 0.
            if (!newsItems) {
                return 0;
            }

            // Apply the change to based on the current active index.
            const newIndex = prevState + change;

            // If we are passed the end, move back to the begining.
            if (newIndex >= newsItems.length) {
                return 0;
            }

            // If we are before the start, move back to the end.
            if (newIndex < 0) {
                return (newsItems.length - 1);
            }

            // If we get here, we are happy with newIndex so just return it.
            return newIndex;
        });
    }, [newsItems, setNewsCarouselActiveIndex, ]);

    return (
        <div className="home">
            {/* NOTE we have decided on no banners for the main pages so its not a mistake they are missing. */}
            <MainContainer color="transparent">
                <AlertOnErrors errors={[loadErrors]} />

                {/* News */}
                {
                    !!newsItems?.length ? (
                        <div className="mb-2 home-news">
                            <Row>
                                <Col xs={12} lg={2}>
                                </Col>
                                <Col>
                                    <Carousel
                                        className="home-news-carousel mb-2"
                                        activeIndex={newsCarouselActiveIndex}
                                        next={() => navigateNewsCarousel(+1)}
                                        previous={() => navigateNewsCarousel(-1)}
                                    >
                                        {/* Indicators */}
                                        <CarouselIndicators
                                            items={newsItems /* Object details here don't matter, only that we pass in any array with the right number of items. */}
                                            activeIndex={newsCarouselActiveIndex}
                                            onClickHandler={setNewsCarouselActiveIndex}
                                        />

                                        {/* Slides */}
                                        {
                                            newsItems?.map(item => (
                                                <CarouselItem key={item.id} className="home-news-carousel-item">
                                                    <div className="home-news-carousel-item-background">
                                                        <NewsCard model={item} />
                                                    </div>
                                                </CarouselItem>
                                            ))
                                        }

                                        {/* Navigation controls */}
                                        <CarouselControl
                                            direction="prev"
                                            directionText={t('home.news.previous', 'Previous')}
                                            onClickHandler={() => navigateNewsCarousel(-1)}
                                        />
                                        <CarouselControl
                                            direction="next"
                                            directionText={t('home.news.next', 'Next')}
                                            onClickHandler={() => navigateNewsCarousel(+1)}
                                        />
                                    </Carousel>

                                    <Row className="home-news-list-bottom-link">
                                        <Col></Col>
                                        <Col xs="auto">
                                            <Link to="/news">
                                                {t('home.news.viewAll', 'View the latest news')}
                                                <> </>
                                                <small><FontAwesomeIcon icon="chevron-right" /></small>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} lg={2}>
                                </Col>
                            </Row>
                        </div>
                        ) : null
                }
                

                {/* External sites (links) */}
                <div className="mb-2">
                    <h3>{t('home.links.heading', 'Links and tools')}</h3>
                    <Row>
                        {
                            externalSites?.map((item) => (
                                <Col key={item.id} xs={12} lg={6} className="mb-2">
                                    <ExternalSiteCard model={item} />
                                </Col>
                            ))
                        }
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col xs="auto">
                            <Link to="/links">
                                {t('home.links.viewAll', 'View all useful links and tools')}
                                <> </>
                                <small><FontAwesomeIcon icon="chevron-right" /></small>
                            </Link>
                        </Col>
                    </Row>
                </div>
                
                {/* Documents */}
                <div className="mb-2">
                    <h3>{t('home.documents.heading', 'Documents and FAQs')}</h3>
                    <Row>
                        {
                            documents?.map((item) => (
                                <Col key={item.id} xs={12} md={6} lg={4} xl={4} className="mb-2">
                                    <DocumentCard model={item} />
                                </Col>
                            ))
                        }
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col xs="auto">
                            <Link to="/documents">
                                {t('home.documents.viewAll', 'View all documents and FAQs')}
                                <> </>
                                <small><FontAwesomeIcon icon="chevron-right" /></small>
                            </Link>
                        </Col>
                    </Row>
                </div>

                {/* Show a flashing dot at the bottom if we are loading. */}
                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator size="sm" fullWidth />
                </ConditionalFragment>
            </MainContainer>

            <InstallPwaPrompt />
        </div>
        );
};