import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { UserAudienceFieldsFragment } from '../generated/graphql';

export const userAudienceFieldsFragment = gql`
    fragment userAudienceFields on UserAudience {
        id
        userId
        audienceId
    }
`;

export type UserAudience = Omit<UserAudienceFieldsFragment, '__typename' | 'userAudience'>;

export const userAudienceDefaultValues = (): Partial<UserAudience> => ({
    id: Guid.newGuid(),
    userId: '',
    audienceId: ''
});