import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { DocumentPartFieldsFragment } from '../generated/graphql';

export const documentPartFieldsFragment = gql`
    fragment documentPartFields on DocumentPart {
        id
        originalDocumentPartId
        heading
        hasChangedFromPreviousVersion
        contentsHtml
        documentId
        parentDocumentPartId
        displayOrder
        searchText
        archived
    }
`;

export type DocumentPart = Omit<DocumentPartFieldsFragment, '__typename' | 'documentPart'>;

/**
 * Default values.
 */
export const documentPartDefaultValues = (): Partial<DocumentPart> => ({
    id: Guid.newGuid(),
    originalDocumentPartId: Guid.empty,
    heading: '',
    hasChangedFromPreviousVersion: false,
    contentsHtml: '',
    documentId: Guid.empty,
    parentDocumentPartId: Guid.empty,
    displayOrder: moment().unix(),
    archived: false,
    searchText: '',
});