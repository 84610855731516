import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardImg, Badge } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { CardsOrTableToggle } from '../../shared/cardsOrTable/CardsOrTableToggle';
import { useExternalSitesListViewModel } from '../../../api/main/externalSites/viewModels/useExternalSiteListViewModel';
import moment from 'moment';
import { AudienceTagSelector } from '../../audiences/AudienceTagSelector';
import { TagSelector } from '../../../shared/tagSelector/TagSelector';
import { HtmlDisplay } from '../../../shared/htmlEditor';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { TagTagSelector } from '../../tags/TagTagSelector';
import { DisplayOrderGroups, displayOrderGroupsDisplayName } from '../../../api/main/models/codeOnly/DisplayOrderGroups';
import { TagSelectorCollapseWrapper, TagSelectorHideOnMdAndAbove, TagSelectorToggleButton } from '../../shared/tagSelectorCollapseWrapper/TagSelectorCollapseWrapper';


/**
 * List of external sites.
 */
export const ExternalSitesList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems, audiences, tags, }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useExternalSitesListViewModel({ pageSize: undefined, });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();
    const navigate = useNavigate();

    const [isAudienceTagSelected, toggleAudienceTag] = useToggleStateArray<string>([]);
    const [isTagTagSelected, toggleTagTag] = useToggleStateArray<string>([]);

    const items = React.useMemo(() => {

        let ret = (allItems ?? []);

        // Filter by selected audiences and tags.  The logic is the same for both:
        // If at least one tag/audience is selected, we show only items matching at least one selected tag/audience.
        // If none are selected we don't filter by audience/tag on this at all.
        const anyAudienceTagsSelected = !!audiences?.find(tag => isAudienceTagSelected(tag.id));
        if (anyAudienceTagsSelected) {
            ret = ret.filter(item => {
                // Try find a selected link.
                if (!!item.audienceLinks?.find(link => isAudienceTagSelected(link.audienceId))) {
                    return true;
                }

                // If we get here we have no matches in audience or tags so lets back out.
                return false;
            }
            );
        }

        const anyTagTagsSelected = !!tags?.find(tag => isTagTagSelected(tag.id));
        if (anyTagTagsSelected) {
            ret = ret.filter(item => {
                // Try find a selected link.
                if (!!item.tagLinks?.find(link => isTagTagSelected(link.tagId))) {
                    return true;
                }

                // If we get here we have no matches in audience or tags so lets back out.
                return false;
            }
            );
        }


        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item => item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.authorizationType.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.url.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.descriptionHtml.toLocaleLowerCase().indexOf(lowerSearch) >= 0);
        }

        return ret;

    }, [allItems, search, audiences, tags, isAudienceTagSelected, isTagTagSelected,]);

    useReplaceSearchParamsEffect({ search: search });

    // Manage the toggle button state for filters
    const [isOpen, setIsOpen] = React.useState(false);
    // Toggle the filters
    const toggleFilters = () => setIsOpen(!isOpen);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('externalSitesList.title', 'External Sites')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />

                            {/* Filters are not toggleable on anything larger than xs */}
                            <TagSelectorHideOnMdAndAbove>
                                <Row>
                                    <Col>
                                        <AudienceTagSelector tags={audiences} isSelected={tag => isAudienceTagSelected(tag.id)} toggle={tag => toggleAudienceTag(tag.id)} />
                                    </Col>
                                    <Col>
                                        <TagSelector tags={tags} isSelected={tag => isTagTagSelected(tag.id)} toggle={tag => toggleTagTag(tag.id)} />
                                    </Col>
                                </Row>
                            </TagSelectorHideOnMdAndAbove>
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('externalSitesList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />

                            <TagSelectorToggleButton isOpen={isOpen} toggle={toggleFilters} />
                        </Col>
                    </Row>

                    {/* Filters are toggleable on xs screens and hidden on all other breakpoints */}
                    <TagSelectorCollapseWrapper isOpen={isOpen} toggle={toggleFilters}>
                        <Row>
                            <Col>
                                <AudienceTagSelector tags={audiences} isSelected={tag => isAudienceTagSelected(tag.id)} toggle={tag => toggleAudienceTag(tag.id)} />
                            </Col>
                            <Col>
                                <TagSelector tags={tags} isSelected={tag => isTagTagSelected(tag.id)} toggle={tag => toggleTagTag(tag.id)} />
                            </Col>
                        </Row>
                    </TagSelectorCollapseWrapper>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        '', /* Thumbnail goes here */
                        t('externalSitesList.site', 'Site'),
                        t('externalSitesList.publishDate', 'Publish date'),
                        t('externalSitesList.authorizationType', 'Authorization type'),
                        t('externalSitesList.audiences', 'Audiences'),
                        t('externalSitesList.tags', 'Tags'),
                        t('externalSitesList.displayOrder', 'Priority group'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardImg src={item.blobReference?.url ?? ''} />
                                    <HtmlDisplay html={item.descriptionHtml} />
                                    <div className="text-muted" style={{ width: '100%' }}>
                                        {
                                            !item.publishDate ? (
                                                <Badge color="warning">{t('common.draft', 'Draft')}</Badge>
                                            )
                                                : (
                                                    <small>{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.publishDate) })}</small>
                                                )
                                        }
                                    </div>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Thumbnail
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <UploadedImagePreview size="sm" src={item.blobReference?.url ?? ''} />
                            );
                        },

                        // Site - a combination of the name and the summary.
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <>
                                    <div>
                                        <h6>{item.name}</h6>
                                    </div>
                                    <HtmlDisplay html={item.descriptionHtml} />
                                </>
                            );
                        },
                        // Publish date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            // Special handling for draft items.
                            const isDraft = !item.publishDate;
                            if (isDraft) {
                                return (<Badge color="warning">{t('common.draft', 'Draft')}</Badge>);
                            }

                            return (<>{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.publishDate) })}</>);
                        },

                        // Authorization Type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (<>{item.authorizationType}</>);
                        },

                        // Audiences
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (<AudienceTagSelector readOnly tags={audiences.filter(tag => !!item.audienceLinks?.find(link => link.audienceId === tag.id))} />);
                        },
                        // Tags
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (<TagTagSelector readOnly tags={tags.filter(tag => !!item.tagLinks?.find(link => link.tagId === tag.id))} />);
                        },
                        // DisplayOrder (priority group)
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return displayOrderGroupsDisplayName(item.displayOrder.toString() as DisplayOrderGroups, t);
                        },

                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </>
    );
};