import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Row } from 'reactstrap';

export interface IncidentTypeItemProps {
    name: string,
    details: string | undefined,
    changeDetails: (details: string) => void,

    isOn: boolean,
    toggle: (on: boolean) => void,

    readonly: boolean
}

export const IncidentTypeItem = (props: IncidentTypeItemProps) => {

    const { isOn } = props;

    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col>{props.name}</Col>
                <Col>
                    <ButtonGroup>
                        <Button color="primary" outline active={isOn} onClick={() => props.toggle(true)}>{t('common.Yes', 'Yes')}</Button>
                        <Button color="primary" outline active={!isOn} onClick={() => props.toggle(false)}>{t('common.No', 'No')}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <ConditionalFragment showIf={props.isOn}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t('', 'Incident details') }</Label>
                            <Input disabled={props.readonly} name="details" value={props.details} onChange={(e) => props.changeDetails(e.target.value)} />
                        </FormGroup>
                    </Col>
                </Row>
            </ConditionalFragment>
            <hr />
        </>
    );
};