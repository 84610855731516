import * as React from "react";
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useConfirmEmailCallback } from "../../api/account";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { ConditionalFragment } from "react-conditionalfragment";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Confirm an email address for an account using a unique link included in the email. 
 */
export const ConfirmEmail = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <MainContainer color="transparent" centerChildren="vertically">
            <Card color="">
                <CardHeader>
                    <h1>
                        {
                            confirmEmailErrors ? t('confirmEmail.confirmEmailFailureHeading', 'Sorry we cannot confirm your email using this code')
                                : emailConfirmed ? t('confirmEmail.confirmEmailSuccessHeading', 'Thanks for confirming your email')
                                    : (<LoadingIndicator />)
                        }
                    </h1>
                </CardHeader>
                <CardBody>
                    <ConditionalFragment showIf={!!confirmEmailErrors}>
                        <Alert color="danger">
                            {confirmEmailErrors}
                        </Alert>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!emailConfirmed}>
                        {/*<Alert color="success">*/}
                        {/*    {t('confirmEmail.thankYouForConfirming', 'Thank you for confirming your email.  You account setup is now complete.')}*/}
                        {/*</Alert>*/}
                        <p>
                            {t('confirmEmail.thankYouForConfirming', 'Thank you for confirming your email.  You account setup is now complete.')}
                        </p>
                        <FormButtons>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <LinkContainer to="/authentication/login">
                                        <Button color="primary">
                                            {t('confirmEmail.signInToContinue', 'Please sign in to continue')}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </FormButtons>
                    </ConditionalFragment>
                </CardBody>
            </Card>
        </MainContainer>
    );
};
