import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap";
import { LinkOpenType } from "../../api/main/models/codeOnly/LinkOpenType";
import { ExternalSite } from "../../api/main/models/ExternalSite";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { generatePrettyUrl } from "../../utilities/generatePrettyContentUrl";
import "./externalSiteCard.scss";

/**
 * ExternalSite model with the extras we need to render a card.
 */
export interface ExternalSiteForExternalSiteCard extends ExternalSite {
    blobReference?: {
        id: string,
        url: string,
    } | null | undefined,
}

export interface ExternalSiteCardProps {
    model: ExternalSiteForExternalSiteCard,
}

/**
 * Card for showing an link to an external site.
 */
export const ExternalSiteCard = (props: ExternalSiteCardProps) => {
    const {
        model,
    } = props;

    // Geneate the full version of the external URL.
    const externalUrl = useMemo(() => {
        const url = model?.url;

        if (!url) {
            return '';
        }

        if (url.indexOf('://') !== -1) {
            return url;
        }

        return `http://${url}`;
    }, [model?.url]);

    // Navigate to the right site.
    const navigate = useNavigate();
    const naviateToUrl = useCallback(() => {
        const embedUrl = generatePrettyUrl('/links/', model);

        switch (model.linkOpenType as LinkOpenType) {
            case LinkOpenType.CurrentWindow:
                window.location.href = externalUrl;
                break;
            case LinkOpenType.NewWindow:
                window.open(externalUrl);
                break
            case LinkOpenType.IFrame:
                navigate(embedUrl);
                break;
            case LinkOpenType.IFrameNewWindow:
                window.open(embedUrl);
                break;
        }
    }, [model, externalUrl, navigate, ]);

    // Get the hostname of the URL so we can show it in the tile.
    const urlHostname = useMemo(() => {
        return new URL(externalUrl).hostname;
    }, [externalUrl]);

    return (
        <Card className="external-site-card" onClick={naviateToUrl}>
            <CardBody className="external-site-card-body">
                <Row>
                    {
                        model?.blobReference?.url ? (
                            <Col xs={4}>
                                <CardImg src={model.blobReference?.url} className="external-site-card-img" />
                            </Col>
                        ): null
                    }
                    
                    <Col>
                            <CardTitle className="external-site-card-headline">
                                <h3>{model.name}</h3>
                            </CardTitle>
                            <div className="external-site-card-url">
                                {urlHostname}
                            </div>
                            <div className="external-site-card-summary">
                                <HtmlDisplay html={model.descriptionHtml} />
                            </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};