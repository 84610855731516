import { Button, Row, Col, Form, Label, FormGroup, Spinner, ButtonGroup, FormText } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useEditExternalSiteViewModel } from '../../../api/main/externalSites/viewModels/useEditExternalSiteViewModel';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { ExternalSite, externalSiteDefaultValues } from '../../../api/main/models/ExternalSite';
import { useSaveExternalSiteMutation } from '../../../api/main/externalSites/useSaveExternalSiteMutation';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { TagLink } from '../../../api/main/models/TagLink';
import { useCreateTagLinkMutation } from '../../../api/main/tagLinks/useCreateTagLinkMutation';
import { useDeleteTagLinkMutation } from '../../../api/main/tagLinks/useDeleteTagLinkMutation';
import { AudienceLink } from '../../../api/main/models/AudienceLink';
import { useCreateAudienceLinkMutation } from '../../../api/main/audienceLinks/useCreateAudienceLinkMutation';
import { useDeleteAudienceLinkMutation } from '../../../api/main/audienceLinks/useDeleteAudienceLinkMutation';
import { PublishDateHandlerComponent } from '../../shared/publishDateHandler/PublishDateHandlerComponent';
import { useEditExternalSiteSupportingData } from '../../../api/main/externalSites/viewModels/useEditExternalSiteSupportingData';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';
import { AudienceTagSelector } from '../../audiences/AudienceTagSelector';
import { Guid } from 'guid-string';
import { TagTagSelector } from '../../tags/TagTagSelector';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { AuthorizationType, authorizationTypeDisplayName } from '../../../api/main/models/codeOnly/AuthorizationType';
import { LinkOpenType, linkOpenTypeDisplayName } from '../../../api/main/models/codeOnly/LinkOpenType';
import { DisplayOrderGroups, displayOrderGroupsDisplayName } from '../../../api/main/models/codeOnly/DisplayOrderGroups';

export interface EditExternalSiteProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<ExternalSite>

}

/**
 * Component to create an ExternalSite.
 */
export const CreateExternalSite = (props: EditExternalSiteProps) => (<EditExternalSite isCreate={true} {...props} />);

/**
 * Component to edit an ExternalSite.
 */
export const EditExternalSite = (props: EditExternalSiteProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();

    // Load all data.
    const {
        data: {
            model: storeModel,
            audienceLinks,
            tagLinks,
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useEditExternalSiteViewModel(id);
    const {
        data: {
            audiences,
            tags,
        },
        errors: supportingDataErrors, isLoading: isLoadingSupportingData,
    } = useEditExternalSiteSupportingData();


    // Model (ExternalSite)
    const isLoading = _isLoading || isLoadingSupportingData;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...externalSiteDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveExternalSite, { errors: saveErrors }] = useSaveExternalSiteMutation();

    // Tags.
    const tagLinksManager = useChangesArray<TagLink, string>(tagLinks, i => i.id);
    const [createTagLink, { errors: saveTagLinkErrors, }] = useCreateTagLinkMutation();
    const [deleteTagLink, { errors: deleteTagLinkErrors, }] = useDeleteTagLinkMutation();

    // Audiences.
    const audienceLinksManager = useChangesArray<AudienceLink, string>(audienceLinks, item => item.id);
    const [createAudienceLink, { errors: saveAudienceLinkErrors, }] = useCreateAudienceLinkMutation();
    const [deleteAudienceLink, { errors: deleteAudienceLinkErrors, }] = useDeleteAudienceLinkMutation();

    const navigate = useNavigate();

    // Image uploading.
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUpload, { errors: imageUploadErrors, isExecuting: isUploadingImage, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    const [clearImage, { isExecuting: isClearingImage, }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editExternalSite.errors.name', 'Name cannot be empty') : '',
            url: () => !model?.url ? t('editExternalSite.errors.url', 'Url cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }
        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveExternalSite(model.id, { ...changes }, isCreate ?? false);

        for (let tagLink of tagLinksManager.added) createTagLink(tagLink);
        for (let tagLink of tagLinksManager.removed) deleteTagLink(tagLink.id);
        tagLinksManager.markAsSaved();

        for (let audienceLink of audienceLinksManager.removed) deleteAudienceLink(audienceLink.id);
        for (let audienceLink of audienceLinksManager.added) createAudienceLink(audienceLink);
        audienceLinksManager.markAsSaved();

        navigate(-1)

    }, [validate, saveExternalSite, model, changes, isCreate, id, tagLinksManager, createTagLink, deleteTagLink, audienceLinksManager, createAudienceLink, deleteAudienceLink]);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editExternalSite.createHeading.default', 'Add external site')
                                        : t('editExternalSite.editHeading.default', 'Edit external site')
                                }
                            </h1>
                            <h6>{model?.name}</h6>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <FormButtons top>
                                <ConditionalFragment showIf={!isLoading}>
                                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                        <FontAwesomeIcon icon="save" />
                                        <> {t('common.save', 'Save')}</>
                                    </ButtonAsync>
                                </ConditionalFragment>

                                <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                                    {t('common.cancel', 'Cancel')}
                                </Button>
                            </FormButtons>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors, supportingDataErrors,
                    saveFormErrors, saveErrors,
                    saveAudienceLinkErrors, deleteAudienceLinkErrors,
                    saveTagLinkErrors, deleteTagLinkErrors,
                    imageUploadErrors, imageLoadErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{t('editExternalSite.name.label', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md="auto">
                            <PublishDateHandlerComponent
                                model={model}
                                change={change}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="auto">
                            <FormGroup>
                                <Label htmlFor="published">{t('editExternalSite.showOnHome.label', 'Show on home page?')}</Label>
                                <TwoValueSwitch
                                    leftLabel={t('common.no', 'No')}
                                    rightLabel={t('common.yes', 'Yes')}
                                    checked={model.showOnHome}
                                    onChange={checked => change({ showOnHome: checked })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editExternalSite.audiencesLabel', 'Available for audiences')}</Label>
                                <AudienceTagSelector
                                    input
                                    tags={audiences}
                                    isSelected={tag => !!audienceLinksManager.model.find(it => it.audienceId === tag.id)}
                                    toggle={tag => {
                                        let existing = audienceLinksManager.model.find(item => item.audienceId === tag.id);
                                        if (existing) {
                                            audienceLinksManager.removeFor(existing.id);
                                        } else {
                                            audienceLinksManager.addFor({ id: Guid.newGuid(), audienceId: tag.id, targetId: model.id, targetType: 'ExternalSite', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editExternalSite.tagsLabel', 'Tags')}</Label>
                                <TagTagSelector
                                    input
                                    tags={tags}
                                    isSelected={tag => !!tagLinksManager.model.find(it => it.tagId === tag.id)}
                                    toggle={tag => {
                                        let existing = tagLinksManager.model.find(item => item.tagId === tag.id);
                                        if (existing) {
                                            tagLinksManager.removeFor(existing.id);
                                        } else {
                                            tagLinksManager.addFor({ id: Guid.newGuid(), tagId: tag.id, targetId: model.id, targetType: 'ExternalSite', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <FormGroup>
                        <Label for="url">{t('editExternalSite.url.label', 'URL')}</Label>
                        <ValidatedInput name="url" type="url"
                            placeholder={t('common.urlPlaceholder', 'https://')}
                            value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} onBlur={e => validate('url')} validationErrors={validationErrors['url']} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="linkOpenType">{t('editExternalSite.linkOpenType', 'Open link in')}</Label>
                                <ValidatedInput name="linkOpenType" type="select" value={model?.linkOpenType ?? ""} onChange={e => change({ linkOpenType: e.currentTarget.value })} onBlur={e => validate('linkOpenType')} validationErrors={validationErrors['linkOpenType']}>
                                    {
                                        Object.keys(LinkOpenType).map(key => {
                                            const value = LinkOpenType[key as keyof typeof LinkOpenType]
                                            return (
                                                <option key={key} value={value}>
                                                    {linkOpenTypeDisplayName(value, t)}
                                                </option>
                                            );
                                        })
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="authorizationType">{t('editExternalSite.authorizationType', 'Authorization and SSO integration')}</Label>
                                <ValidatedInput name="authorizationType" type="select" value={model?.authorizationType ?? ""} onChange={e => change({ authorizationType: e.currentTarget.value })} onBlur={e => validate('authorizationType')} validationErrors={validationErrors['authorizationType']}>
                                    {
                                        Object.keys(AuthorizationType).map(key => {
                                            const value = AuthorizationType[key as keyof typeof AuthorizationType]
                                            return (
                                                <option key={key} value={value}>
                                                    {authorizationTypeDisplayName(value, t)}
                                                </option>
                                            );
                                        })
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="displayOrder">{t('editExternalSite.displayOrder.label', 'Priority group')}</Label>
                                <ValidatedInput name="displayOrder" type="select" value={model?.displayOrder ?? ""} onChange={e => change({ displayOrder: parseInt(e.currentTarget.value) })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']}>
                                    {
                                        Object.keys(DisplayOrderGroups).map(key => {
                                            const value = DisplayOrderGroups[key as keyof typeof DisplayOrderGroups]
                                            return (
                                                <option key={key} value={value}>
                                                    {displayOrderGroupsDisplayName(value, t)}
                                                </option>
                                            );
                                        })
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="name">{t('editExternalSite.image.label', 'Thumbnail image')}</Label>
                        <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                        <Row>
                            <Col>
                                <ButtonGroup>
                                    <FileUploadButton
                                        color={`primary`}
                                        isExecuting={isUploadingImage}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}
                                        onUpload={onUpload}
                                        outline={false}>
                                        {t('editExternalSite.uploadButtonText', 'Upload an image')}
                                    </FileUploadButton>
                                    <ButtonAsync color="primary"
                                        outline
                                        isExecuting={isClearingImage}
                                        type="button"
                                        onClick={clearImage}
                                        executingChildren={<><Spinner size="sm" /> {t('editExternalSite.clearingImage', 'Clearing image...')}</>}>
                                        {t('editExternalSite.clearImageButton', 'Clear image')}
                                    </ButtonAsync>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Label for="content">{t('editExternalSite.descriptionHtml.label', 'Thumbnail summary')}</Label>
                        <HtmlEditor
                            size="sm"
                            value={model?.descriptionHtml ?? ''}
                            onChange={(value: string) => change({ descriptionHtml: value })}
                        />
                        <FormText>
                            {t('editExternalSite.descriptionHtml.hint', 'This is a short description of the services available on the linked site.')}
                        </FormText>
                    </FormGroup>
                </Form>
            </MainContainer>
        </>
    );
}
