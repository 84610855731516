import * as React from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, ButtonGroup, FormText } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useEditDocumentViewModel } from '../../../api/main/documents/viewModels/useEditDocumentViewModel';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { Document, documentDefaultValues } from '../../../api/main/models/Document';
import { Guid } from 'guid-string';
import { DocumentPart } from '../../../api/main/generated/graphql';
import { useSaveDocumentPartMutation } from '../../../api/main/documentParts/useSaveDocumentPartMutation';
import { useDeleteDocumentPartMutation } from '../../../api/main/documentParts/useDeleteDocumentPartMutation';
import { useSaveDocumentMutation } from '../../../api/main/documents/useSaveDocumentMutation';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { DocumentPartComponent } from './DocumentParts/DocumentPartComponent';
import { useDisplayOrder } from '../../shared/useDisplayOrder/useDisplayOrder';
import { documentPartDefaultValues } from '../../../api/main/models/DocumentPart';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { TagLink } from '../../../api/main/models/TagLink';
import { useDeleteTagLinkMutation } from '../../../api/main/tagLinks/useDeleteTagLinkMutation';
import { useCreateTagLinkMutation } from '../../../api/main/tagLinks/useCreateTagLinkMutation';
import { AudienceLink } from '../../../api/main/models/AudienceLink';
import { useCreateAudienceLinkMutation } from '../../../api/main/audienceLinks/useCreateAudienceLinkMutation';
import { useDeleteAudienceLinkMutation } from '../../../api/main/audienceLinks/useDeleteAudienceLinkMutation';
import { PublishDateHandlerComponent } from '../../shared/publishDateHandler/PublishDateHandlerComponent';
import { useEditDocumentSupportingData } from '../../../api/main/documents/viewModels/useEditDocumentSupportingData';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { TagTagSelector } from '../../tags/TagTagSelector';
import { AudienceTagSelector } from '../../audiences/AudienceTagSelector';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { DisplayOrderGroups, displayOrderGroupsDisplayName } from '../../../api/main/models/codeOnly/DisplayOrderGroups';
import { ContentsType, contentsTypeDisplayName } from '../../../api/main/models/codeOnly/ContentsType';
import { UploadedFilePreview } from '../../shared/uploadedFilePreview/UploadedFilePreview';

export interface EditDocumentProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Document>

}

export const CreateDocument = (props: EditDocumentProps) => (<EditDocument isCreate={true} {...props} />);

export const EditDocument = (props: EditDocumentProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: {
        model: storeModel,
        documentParts: storeDocumentParts,
        audienceLinks,
        tagLinks,
    }, isLoading: _isLoading, errors: loadErrors } = useEditDocumentViewModel(id);
    const {
        data: {
            audiences,
            tags,
        },
        errors: supportingDataErrors, isLoading: isLoadingSupportingData,
    } = useEditDocumentSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // model management.
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...documentDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveDocumentMutation();

    // DocumentPart management.
    const documentPartManager = useChangesArray<DocumentPart, string>(storeDocumentParts, item => item.id);
    const [saveDocumentPart] = useSaveDocumentPartMutation();
    const [removeDocumentPart] = useDeleteDocumentPartMutation();

    // TagLink management.
    const tagLinksManager = useChangesArray<TagLink, string>(tagLinks, x => x.id);
    const [deleteTagLink, { errors: deleteTagLinkErrors }] = useDeleteTagLinkMutation();
    const [createTagLink, { errors: saveTagLinkErrors }] = useCreateTagLinkMutation();

    // AudienceLink management.
    const audienceLinksManager = useChangesArray<AudienceLink, string>(audienceLinks, i => i.id);
    const [createAudienceLink, { errors: saveAudienceLinkErrors }] = useCreateAudienceLinkMutation();
    const [deleteAudienceLink, { errors: deleteAudienceLinkErrors }] = useDeleteAudienceLinkMutation();

    const navigate = useNavigate();

    // Image uploading.
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    const [clearImage, { isExecuting: isClearingImage, }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Contents file uploading.
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.contentsBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ contentsBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ contentsBlobReferenceId: null });
    }, [change]);


    const filterItems = React.useCallback((currentPart: DocumentPart) => {
        if (currentPart.parentDocumentPartId === Guid.empty) {
            return true;
        } else {
            return false;
        }

    }, []);

    // Order the document parts so they show in and can be managed by displayOrder.
    const [orderedItems, {
        canMoveUp: canMovePartUp,
        moveUp: movePartUp,
        canMoveDown: canMovePartDown,
        moveDown: movePartDown,
    }] = useDisplayOrder(documentPartManager, filterItems);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editDocument.name.validation.required', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Validate document parts
    const [validateDocumentPart, documentPartValidationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            // Nothing to validate.
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        // Save the main model.
        await save(model.id, changes, !!isCreate);

        // Save the document parts
        for (const item of documentPartManager.added) { await saveDocumentPart(item.id, documentPartManager.changesFor(item.id), true); }
        for (const item of documentPartManager.updated) { await saveDocumentPart(item.id, documentPartManager.changesFor(item.id), false); }
        for (const item of documentPartManager.removed) { await removeDocumentPart(item.id); }
        documentPartManager.markAsSaved();

        // Save changes to the tagLinks.
        for (let i of tagLinksManager.removed) deleteTagLink(i.id);
        for (let i of tagLinksManager.added) createTagLink(i);

        // Save changes to the audienceLinks.
        for (let i of audienceLinksManager.removed) deleteAudienceLink(i.id);
        for (let i of audienceLinksManager.added) createAudienceLink(i);

        navigate(-1)

    }, [validate, save, model, changes, isCreate, documentPartManager, saveDocumentPart, removeDocumentPart, tagLinksManager,
        createTagLink, deleteTagLink, id, audienceLinksManager, createAudienceLink, deleteAudienceLink]);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editDocument.createHeading.default', 'Add document')
                                        : t('editDocument.editHeading.default', 'Edit document')
                                }
                            </h1>
                            <h6>{model?.name}</h6>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <FormButtons top>
                                <ConditionalFragment showIf={!isLoading}>
                                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                        <FontAwesomeIcon icon="save" />
                                        <> {t('common.save', 'Save')}</>
                                    </ButtonAsync>
                                </ConditionalFragment>

                                <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                                    {t('common.cancel', 'Cancel')}
                                </Button>
                            </FormButtons>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors, supportingDataErrors,
                    saveFormErrors, saveErrors,
                    saveAudienceLinkErrors, deleteAudienceLinkErrors,
                    saveTagLinkErrors, deleteTagLinkErrors,
                    imageUploadErrors, imageLoadErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{t('editDocument.name.label', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md="auto">
                            <PublishDateHandlerComponent
                                model={model}
                                change={change}
                            />
                        </Col>
                    </Row>


                    <Row>
                        <Col xs="12" md="auto">
                            <FormGroup>
                                <Label htmlFor="published">{t('editDocument.showOnHome.label', 'Show on home page?')}</Label>
                                <TwoValueSwitch
                                    leftLabel={t('common.no', 'No')}
                                    rightLabel={t('common.yes', 'Yes')}
                                    checked={model.showOnHome}
                                    onChange={checked => change({ showOnHome: checked })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editDocument.audiencesLabel', 'Available for audiences')}</Label>
                                <AudienceTagSelector
                                    input
                                    tags={audiences}
                                    isSelected={tag => !!audienceLinksManager.model.find(it => it.audienceId === tag.id)}
                                    toggle={tag => {
                                        let existing = audienceLinksManager.model.find(item => item.audienceId === tag.id);
                                        if (existing) {
                                            audienceLinksManager.removeFor(existing.id);
                                        } else {
                                            audienceLinksManager.addFor({ id: Guid.newGuid(), audienceId: tag.id, targetId: model.id, targetType: 'Document', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{t('editDocument.tagsLabel', 'Tags')}</Label>
                                <TagTagSelector
                                    input
                                    tags={tags}
                                    isSelected={tag => !!tagLinksManager.model.find(it => it.tagId === tag.id)}
                                    toggle={tag => {
                                        let existing = tagLinksManager.model.find(item => item.tagId === tag.id);
                                        if (existing) {
                                            tagLinksManager.removeFor(existing.id);
                                        } else {
                                            tagLinksManager.addFor({ id: Guid.newGuid(), tagId: tag.id, targetId: model.id, targetType: 'Document', });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="version">{t('editDocument.version', 'Version')}</Label>
                                <ValidatedInput name="version" type="text" value={model.version ?? ''} onChange={e => change({ version: e.currentTarget.value })} onBlur={e => validate('version')} validationErrors={validationErrors['version']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="displayOrder">{t('editExternalSite.displayOrder.label', 'Priority group')}</Label>
                                <ValidatedInput name="displayOrder" type="select" value={model?.displayOrder ?? ""} onChange={e => change({ displayOrder: parseInt(e.currentTarget.value) })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']}>
                                    {
                                        Object.keys(DisplayOrderGroups).map(key => {
                                            const value = DisplayOrderGroups[key as keyof typeof DisplayOrderGroups]
                                            return (
                                                <option key={key} value={value}>
                                                    {displayOrderGroupsDisplayName(value, t)}
                                                </option>
                                            );
                                        })
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <FormGroup>
                        <Label for="versionChanges">{t("editDocument.versionChanges", "Change summary for this version")}</Label>
                        <HtmlEditor onChange={(e) => change({ versionChangesHtml: e })} value={model?.versionChangesHtml ?? ""}></HtmlEditor>
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="name">{t('editDocument.image.label', 'Cover image')}</Label>
                        <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                        <Row>
                            <Col>
                                <ButtonGroup>
                                    <FileUploadButton
                                        color={`primary`}
                                        isExecuting={isUploadingImage}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}
                                        onUpload={onUploadImage}
                                        outline={false}>
                                        {t('editDocument.uploadButtonText', 'Upload an image')}
                                    </FileUploadButton>
                                    <ButtonAsync color="primary"
                                        outline
                                        isExecuting={isClearingImage}
                                        type="button"
                                        onClick={clearImage}
                                        executingChildren={<><Spinner size="sm" /> {t('editDocument.clearingImage', 'Clearing image...')}</>}>
                                        {t('editDocument.clearImageButton', 'Clear image')}
                                    </ButtonAsync>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Label for="content">{t('editDocument.descriptionHtml.label', 'Cover summary')}</Label>
                        <HtmlEditor
                            size="sm"
                            value={model?.descriptionHtml ?? ''}
                            onChange={(value: string) => change({ descriptionHtml: value })}
                        />
                        <FormText>
                            {t('editDocument.descriptionHtml.hint', 'This is a short summary to be shown on the document along with the headline and thumbnail image.  The full contents of the document should be written in document parts.')}
                        </FormText>
                    </FormGroup>


                    <FormGroup>
                        <Label for="content">{t('editDocument.contentsType.label', 'Type of document')}</Label>
                        <ValidatedInput name="contentsType" type="select" value={model?.contentsType ?? ''} onChange={e => change({ contentsType: e.currentTarget.value })} onBlur={e => validate('contentsType')} validationErrors={validationErrors['contentsType']}>
                            {
                                Object.keys(ContentsType).map(item => (
                                    <option key={item} value={item}>{contentsTypeDisplayName(item as ContentsType, t)}</option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>

                    {
                        model?.contentsType === ContentsType.Html ? (
                            <>
                                <FormGroup>
                                    <Label htmlFor="tableOfContentLevels">{t('editDocument.tableOfContentLevels.label', 'Maximum levels of sections to include in the generated table of contents')}</Label>
                                    <ValidatedInput name="tableOfContentLevels" type="number" value={model.tableOfContentLevels ?? ''} onChange={e => change({ tableOfContentLevels: e.currentTarget.valueAsNumber })} onBlur={e => validate('tableOfContentLevel')} validationErrors={validationErrors['tableContextLevel']} />
                                    <FormText>
                                        {t('editDocument.tableOfContentLevels.hint', 'When the user views this document we will automatically generate a table of contents for them to make navigating larger documents easier.  If you do not want a table of contents generating you can set this to zero.')}
                                    </FormText>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="name">{t('editDocument.contentsBlob.label.link', 'Link to document or download')}</Label>
                                    <UploadedFilePreview blobReference={contentsFile} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editDocument.uploadFileButtonText', 'Upload a document or file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editDocument.deletingFile', 'Deleting...')}</>}>
                                                    {t('editDocument.clearFileButton', 'Delete document or file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <FormText>
                                        {t('editDocument.contentsBlob.hint.link', 'You can upload and link a document or file here if you would like to give the reader a download a full copy of the document in its original format (e.g. a PDF document).')}
                                    </FormText>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="originalSourceUrl">{t('editDocument.originalSourceUrl.label', 'Download external URL')}</Label>
                                    <ValidatedInput name="originalSourceUrl" type="url"
                                        placeholder={t('common.urlPlaceholder', 'https://')}
                                        value={model?.originalSourceUrl ?? ''} onChange={e => change({ originalSourceUrl: e.currentTarget.value })} onBlur={e => validate('originalSourceUrl')} validationErrors={validationErrors['originalSourceUrl']} />
                                    <FormText>
                                        {t('editDocument.originalSourceUrl.hint', 'You can link an external URL here if you would like to give the reader a download a full copy of the document in its original format (e.g. a PDF document) from an external source without uploading it on to the app itself.')}
                                    </FormText>
                                </FormGroup>

                                <hr />

                                <FormGroup>
                                    <h5>
                                        <Label htmlFor="sections">
                                            {
                                                t('editDocument.sections.label', 'Content of the document')
                                            }
                                        </Label>
                                    </h5>
                                    <FormText>
                                        {t('editDocument.sections.label', "Here you can add the content of the document itself arranged into hierarchical sections.  From the sections you enter we'll be able to generate a table of contents and allow the document to be searched and navigated by the readers.  For small documents you may choose to organise the document contents into a single section.  For larger documents we recommend splitting the document into logical sections with headings to make navigating and updating the document easier for yourself and the readers.")}
                                    </FormText>
                                    <div className="mt-2">
                                        {
                                            // Means the item isnt a sub item and therefore should be shown here
                                            orderedItems.filter(it => it.parentDocumentPartId === Guid.empty).map(item => {
                                                return (
                                                    <DocumentPartComponent key={item.id}
                                                        model={item}
                                                        documentId={model.id}
                                                        change={changes => documentPartManager.changeFor(item.id, changes)}
                                                        orderedItems={orderedItems}
                                                        documentPartManager={documentPartManager}

                                                        moveUp={() => movePartUp(item.id)} canMoveUp={canMovePartUp(item.id)}
                                                        moveDown={() => movePartDown(item.id)} canMoveDown={canMovePartDown(item.id)}
                                                        isOpenInitially={!!documentPartManager.added.find(it => it.id === item.id)}

                                                        validate={validateDocumentPart}
                                                        validationErrors={documentPartValidationErrors}
                                                    />
                                                );
                                            })
                                        }
                                    </div>

                                    <Button color="primary" outline onClick={() => documentPartManager.addFor({
                                        ...documentPartDefaultValues(),
                                        documentId: model?.id
                                    })}>
                                        {t('editDocument.addSection', ' Add section')}
                                    </Button>
                                </FormGroup>
                            </>
                        )
                            : model?.contentsType === ContentsType.Document ? (
                                <>
                                    <FormGroup>
                                        <Label for="name">{t('editDocument.contentsBlob.label.main', 'Document or file')}</Label>
                                        <UploadedFilePreview blobReference={contentsFile} />
                                        <Row>
                                            <Col>
                                                <ButtonGroup>
                                                    <FileUploadButton
                                                        color={`primary`}
                                                        isExecuting={isUploadingContentsFile}
                                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Uploading...')}</>}
                                                        onUpload={onUploadContentsFile}
                                                        outline={false}>
                                                        {t('editDocument.uploadFileButtonText', 'Upload a document or file')}
                                                    </FileUploadButton>
                                                    <ButtonAsync color="primary"
                                                        outline
                                                        isExecuting={isClearingContentsFile}
                                                        type="button"
                                                        onClick={clearContentsFile}
                                                        executingChildren={<><Spinner size="sm" /> {t('editDocument.deletingFile', 'Deleting...')}</>}>
                                                        {t('editDocument.clearFileButton', 'Delete document or file')}
                                                    </ButtonAsync>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        <FormText>
                                            {t('editDocument.contentsBlob.hint.main', 'You can upload and link document or file here in its original format (e.g. a PDF document) which will open directly when the user clicks on the document.')}
                                        </FormText>
                                    </FormGroup>
                                </>
                            )
                                : null
                    }

                </Form>


            </MainContainer>

            
        </>
    );
}
