import { Guid } from "guid-string";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DocumentPart } from "../../../api/main/models/DocumentPart";
import { generatePrettyUrl } from "../../../utilities/generatePrettyContentUrl";

export interface TableOfContentsProps {
    documentParts: Array<DocumentPart>,
    maxDepth: number,
    documentRoutePath: string,
}

/**
 * Component for generating and displaying a table of contents for a document.
 */
export const TableOfContents = (props: TableOfContentsProps) => {
    const {
        documentParts,
        maxDepth,
        documentRoutePath,
    } = props;

    const { t } = useTranslation();

    // Get all the parts that should be displayed at the top level.
    const topLevelParts = useMemo(() => documentParts.filter(item => !item.parentDocumentPartId || item.parentDocumentPartId === Guid.empty), [documentParts]);


    // If there are no top level children, we render nothing as we don't want an empty "Table of cotnetns" heading.
    if (!topLevelParts.length) {
        return null;
    }

    // Use TableOfContentsEntry to display our top level navigation, with recursive use for navigation under it
    // down to maxDepth.
    return (
        <>
            <h6>{t('tableOfContents.title', 'Table of contents')}</h6>
            <ul>
                {
                    topLevelParts.map((item) => (
                        <TableOfContentsEntry
                            key={item.id}
                            model={item}
                            allDocumentParts={documentParts}
                            currentDepth={1}
                            maxDepth={maxDepth}
                            documentRoutePath={documentRoutePath}
                            />
                        ))
                }
                </ul>
            </>
        );
};

export interface TableOfContentsEntryProps {
    model: DocumentPart,
    allDocumentParts: Array<DocumentPart>,
    currentDepth: number,
    maxDepth: number,
    documentRoutePath: string,
}

/**
 * Component for a single TableOfContentsEntry that expects to be used recursivly.
 */
const TableOfContentsEntry = (props: TableOfContentsEntryProps) => {
    const {
        model,
        allDocumentParts,
        currentDepth,
        maxDepth,
        documentRoutePath,
    } = props;

    // Get my children.
    const myChildParts = useMemo(() => allDocumentParts.filter(it => it.parentDocumentPartId === model.id), [model, allDocumentParts]);

    // If we have reached beyond the max depth, render nothing.
    if (currentDepth > maxDepth) {
        return null;
    }

    // If we have no heading, we don't show.
    if (!model.heading) {
        return null;
    }

    // Generate a TOC entry for ourselves and our children.
    return (
        <li>
            <Link to={generatePrettyUrl(`${documentRoutePath}/`, { id: model.id, name: model.heading, })}>
                {model.heading}
            </Link>
            {
                !!myChildParts.length ? (
                    <ul>
                        {
                            myChildParts.map((item) => (
                                <TableOfContentsEntry
                                    key={item.id}
                                    model={item}
                                    allDocumentParts={allDocumentParts}
                                    currentDepth={currentDepth + 1}
                                    maxDepth={maxDepth}
                                    documentRoutePath={documentRoutePath}
                                />
                            ))
                        }
                    </ul>
                ): null
            }
        </li>
        );
};