import { RouteEntry } from "../../../shared/routes";
import { FormsList } from "./FormsList";
import { DeleteForm } from "./DeleteForm";
import { CreateForm, EditForm} from "./EditForm";

export const formsAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/forms/add', element: <CreateForm />, authorize: true },
    { path: '/administration/forms/edit/:id', element: <EditForm />, authorize: true },
    { path: '/administration/forms/delete/:id', element: <DeleteForm />, authorize: true },
    { path: '/administration/forms', element: <FormsList />, authorize: true },
];
