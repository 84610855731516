import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap";
import { LinkOpenType } from "../../api/main/models/codeOnly/LinkOpenType";
import { Form } from "../../api/main/models/Form";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { generatePrettyUrl } from "../../utilities/generatePrettyContentUrl";
import "./formCard.scss";

/**
 * Form model with the extras we need to render a card.
 */
export interface FormForFormCard extends Form {
    //The query item includes blobReference
    blobReference?: {
        id: string,
        url: string,
    } | null | undefined,
}

export interface FormCardProps {
    model: FormForFormCard,
}

/**
 * Card for showing an link to an external site.
 */
export const FormCard = (props: FormCardProps) => {
    const {
        model,
    } = props;

    // Navigate to the right site.
    const navigate = useNavigate();
    
    return (
        <Card className="form-card" onClick={(e) => navigate(`/forms/${model.name}/${model.id}`)}>
            <CardBody className="form-card-body">
                <Row>
                    <Col>
                            <CardTitle className="form-card-headline">
                                <h3>{model.name}</h3>
                            </CardTitle>
                        
                            <div className="form-card-summary">
                                <HtmlDisplay html={model.descriptionHtml} />
                            </div>
                    </Col>
                    {
                        model?.blobReference?.url ? (
                            <Col xs={4}>
                                <CardImg src={model.blobReference?.url} className="form-card-img" />
                            </Col>
                        ) : null
                    }
                </Row>
            </CardBody>
        </Card>
    );
};