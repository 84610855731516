import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateAudienceMutation } from './useUpdateAudienceMutation';
import { Audience } from '../models/Audience';
import { useCreateAudienceMutation } from './useCreateAudienceMutation';
import { CreateAudienceInput, AudienceUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Audience in the store using either a create or update.
 */
export function useSaveAudienceMutation(options: SaveInStoreOptions<Audience, string> = {}) {
    const [_create, createStatus] = useCreateAudienceMutation();
    const create = React.useCallback((model: Partial<Audience>) => _create(model as CreateAudienceInput), [_create]);

    const [_update, updateStatus] = useUpdateAudienceMutation();
    const update = React.useCallback((id: string, changes: Partial<Audience>) => _update(id, changes as AudienceUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
