import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { HomeViewModelQuery, HomeViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { newsItemFieldsFragment } from '../../models/NewsItem';
import { Guid } from 'guid-string';
import { externalSiteFieldsFragment } from '../../models/ExternalSite';
import { documentFieldsFragment } from '../../models/Document';

/**
 * Get all data needed to show on the Home page for a user.
 * @param userId
 * @param options
 */
export function useHomeViewModel(userId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<HomeViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<HomeViewModelQuery, HomeViewModelQueryVariables>(
        gql`
        query homeViewModelQuery ($userId: ID!) {
            newsItems: newsItemsForUser (userId: $userId, showOnHome: true) {
                ...newsItemFields

                blobReference {
                    id
                    url
                }

                contentsBlobReference {
                    id
                    url
                }
            }

            externalSites: externalSitesForUser (userId: $userId, showOnHome: true) {
                ...externalSiteFields

                blobReference {
                    id
                    url
                }
            }

            documents: documentsForUser (userId: $userId, showOnHome: true) {
                ...documentFields

                blobReference {
                    id
                    url
                }
                
                contentsBlobReference {
                    id
                    url
                }
            }
        }

        ${newsItemFieldsFragment}
        ${externalSiteFieldsFragment}
        ${documentFieldsFragment}
        `,
        {

            variables: {
                userId: userId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}
