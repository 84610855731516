import * as React from "react";
import { AsyncLoadResult, AsyncLoadOptions } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAccountService } from "./useAccountService";
import { SamlConfiguration } from "./AccountService";

/**
 * Use the api to return the SAML configuration for this application.
 */
export function useSamlConfiguration(options: AsyncLoadOptions = {}): AsyncLoadResult<{ samlConfiguration: SamlConfiguration | undefined }> {
    const [samlConfiguration, setSamlConfiguration] = React.useState<SamlConfiguration | undefined>();

    const accountService = useAccountService();

    const [refresh, { isExecuting: isLoading, errors }] = useAsyncCallback(async () => {
        let result = await accountService.getSamlConfiguration();
        setSamlConfiguration(result);
    }, [accountService, setSamlConfiguration]);

    React.useEffect(() => {
        if (!options.lazy) {
            refresh();
        }
    }, [refresh, options.lazy]);

    // TODO handle options.pollInterval.

    return {
        data: { samlConfiguration },
        refresh: refresh,
        isLoading: isLoading,
        errors: errors
    };
}
