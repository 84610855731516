import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AudienceFieldsFragment } from "../generated/graphql";

export const audienceFieldsFragment = gql`
    fragment audienceFields on Audience {
        id
        name
        jobTitles
        archived
    }
`;


export type Audience = Omit<AudienceFieldsFragment, '__typename' | 'audience'>;

/**
 * Default values.
 */
export const audienceDefaultValues = (): Partial<Audience> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    jobTitles: '',
});