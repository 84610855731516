import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Collapse, Row } from "reactstrap";

// These three components are to be used together to achieve the hiding of filters on various screens, the children can be different depending on the page we are viewing
// Using three components as the content in its original form is spread out across the ui, so I thought adding each part here would avoid having to remember to wrap things in bootstrap classes.
// For usage you can see:
// NCT - Colleague App\NctColleagueApp\ClientApp\src\components\newsItems\administration\ListNewsItems.tsx
// NCT - Colleague App\NctColleagueApp\ClientApp\src\components\externalSites\administration\ExternalSitesList.tsx
// NCT - Colleague App\NctColleagueApp\ClientApp\src\components\externalSites\administration\DocumentsList.tsx
// NCT - Colleague App\NctColleagueApp\ClientApp\src\components\forms\administration\FormsList.tsx
// NCT - Colleague App\NctColleagueApp\ClientApp\src\components\users\UsersList.tsx

export interface TagSelectorCollapseWrapperProps {
    isOpen: boolean,
    toggle: () => void,
    children: any,
}

/**
 * A wrapper component used to hide the filters on xs and sm displays.
 * @param props
 * @returns
 */
export const TagSelectorCollapseWrapper = (props: TagSelectorCollapseWrapperProps) => {
    const {
        isOpen,
        toggle,
        children,
    } = props;

    // Render the UI
    //
    return (
        <Collapse isOpen={isOpen} toggle={toggle}>
            <Row className="d-block d-md-none">
                <Col>
                    {children}
                </Col>
            </Row>
        </Collapse>
    );
};

export interface TagSelectorHideOnMdAndAboveProps {
    children: any,
}

/**
 * Wrapper component used to hide the filters on md displays and up
 * @param props
 * @returns
 */
export const TagSelectorHideOnMdAndAbove = (props: TagSelectorHideOnMdAndAboveProps) => {
    const {
        children,
    } = props;

    // Render the UI
    //
    return (
        <div className="d-none d-md-block d-lg-block">
            {children}
        </div>
    );
};

export interface TagSelectorToggleButtonProps {
    isOpen: boolean,
    toggle: () => void,
}

/**
 * Button component that hides on md displays and up.
 * @param props
 * @returns
 */
export const TagSelectorToggleButton = (props: TagSelectorToggleButtonProps) => {
    const {
        isOpen,
        toggle,
    } = props;

    // Render the UI
    //
    return (
        <div className="d-inline-block d-md-none">
            <Button color="primary" outline={!isOpen} onClick={toggle}>
                <FontAwesomeIcon icon="filter" />
            </Button>
        </div>
    );
};