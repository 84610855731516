import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Card, CardBody, CardImg, CardTitle } from "reactstrap";
import { ContentsType } from "../../api/main/models/codeOnly/ContentsType";
import { NewsItem } from "../../api/main/models/NewsItem";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { generatePrettyUrl } from "../../utilities/generatePrettyContentUrl";
import "./newsCard.scss";

/**
 * NewsItem model with the extras we need to render a card.
 */
export interface NewsItemForNewsCard extends NewsItem {
    blobReference?: {
        id: string,
        url: string,
    } | null | undefined,

    contentsBlobReference?: {
        id: string,
        url: string,
    } | null | undefined,
}

export interface NewsCardProps {
    model: NewsItemForNewsCard,
}

/**
 * Card for showing a news article.
 */
export const NewsCard = (props: NewsCardProps) => {
    const {
        model,
    } = props;

    const { t } = useTranslation();

    const url = useMemo(() => generatePrettyUrl('/news/', model), [model]);

    // Link Wapper is either using routing or an a link.
    const LinkWrapper = useCallback((props: { children: React.ReactNode }) => {
        if (model.contentsType === ContentsType.Document && !!model.contentsBlobReference?.url) {
            return (
                <a href={model.contentsBlobReference?.url} target="_blank" rel="noreferrer" className="news-card-a-no-style">
                    {props.children}
                </a>
            );
        }

        // Otherwise render the article as an app route.
        return (
            <LinkContainer to={url}>
                {props.children}
            </LinkContainer>
        );
    }, [model, url]);

    return (
        <LinkWrapper>
            <Card className="news-card">
                <CardImg src={model.blobReference?.url} className="news-card-img" />
                <CardBody className="news-card-body">
                    <CardTitle className="news-card-headline">
                        <h3>{model.name}</h3>
                    </CardTitle>
                    <div className="news-card-summary">
                        <HtmlDisplay html={model.descriptionHtml} />
                    </div>
                    <div className="news-card-date">
                        <FontAwesomeIcon icon={['far', 'clock']} />
                        <> </>
                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model.publishDate) })}
                    </div>
                </CardBody>
            </Card>
        </LinkWrapper>
    );
};