import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';
import { NavLinkTrackActive } from '../../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Primary navigation menu for the app. 
 */
export const HeaderNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/home">
                        <FontAwesomeIcon icon="home" className="nav-icon"  />
                        <span>{t('navigation.home', 'Home')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/news">
                        <FontAwesomeIcon icon="newspaper" className="nav-icon" />
                        <span>{t('navigation.news', 'News')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/links">
                        <FontAwesomeIcon icon="link" className="nav-icon" />
                        <span>{t('navigation.links', 'Links')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/documents">
                        <FontAwesomeIcon icon="file" className="nav-icon" />
                        <span>{t('navigation.documents', 'Documents')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/forms">
                        <FontAwesomeIcon icon="pen-to-square" className="nav-icon" />
                        <span>{t('navigation.forms', 'Forms')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLinkTrackActive to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <span>{t('navigation.administration', 'Administration')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
