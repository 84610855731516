import * as React from 'react';
import { Button, Row, Col, Label, FormGroup, Card, Collapse, CardBody, ButtonGroup, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModelArrayChanges } from '../../../../shared/useChanges';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../../../shared/formButtons/FormButtons';
import { DocumentPart } from '../../../../api/main/generated/graphql';
import { CardHeaderCollapse } from '../../../shared/cardHeaderCollapse/CardHeaderCollapse';
import { useToggleState } from 'use-toggle-state';
import { HtmlEditor } from '../../../../shared/htmlEditor';
import { ValidationErrors } from 'pojo-validator';
import { documentPartDefaultValues } from '../../../../api/main/models/DocumentPart';
import { useDisplayOrder } from '../../../shared/useDisplayOrder/useDisplayOrder';

export interface DocumentPartComponentProps {
    model: DocumentPart,
    documentId: string,
    change: (changes: Partial<DocumentPart>) => void,
    isOpenInitially?: boolean,

    documentPartManager: ModelArrayChanges<DocumentPart, string>,
    orderedItems: Array<DocumentPart>
    
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * A document part that forms part of the Document.
 * @param props
 */
export const DocumentPartComponent = (props: DocumentPartComponentProps) => {
    const {
        model,
        documentId,
        change,
        isOpenInitially = false,

        documentPartManager,
        orderedItems,

        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        validate,
        validationErrors,
        
    } = props

    const { t } = useTranslation();

    const filterItems = React.useCallback((currentPart: DocumentPart) => {
        if (currentPart.parentDocumentPartId === model.id) {
            return true;
        } else {
            return false;
        }
    }, [model.id]);

    // Order the document parts so they show in and can be managed by displayOrder.
    const [orderedSubItems, {
        canMoveUp: canMovePartUp,
        moveUp: movePartUp,
        canMoveDown: canMovePartDown,
        moveDown: movePartDown,
    }] = useDisplayOrder(documentPartManager, filterItems);

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    return (
        <>
            <Card style={{ marginBottom: "10px" }}>
                <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                    <Row>
                        <Col>
                            {model.heading}
                        </Col>
                        <Col xs="auto">
                            
                        </Col>
                    </Row>
                </CardHeaderCollapse>

                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="heading">{t('editDocument.heading.label', 'Section heading')}</Label>
                                    <ValidatedInput name="heading" type="text" value={model.heading ?? ''} onChange={e => change({ heading: e.currentTarget.value })} onBlur={e => validate('heading')} validationErrors={validationErrors['heading']} />
                                    <FormText>
                                        {t('editDocument.heading.hint', "You can leave the section heading blank if you don't want to show a heading and don't need this section in the table of contents.")}
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col md="auto">
                                <FormGroup>
                                    <Label htmlFor="buttons">&nbsp;</Label>
                                    <FormButtons noPadding className="mb-1">
                                        <ButtonGroup>
                                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                                <FontAwesomeIcon icon="caret-up" />
                                                <> </>
                                                {t('documentPartComponent.up', 'Up')}
                                            </Button>
                                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                                <FontAwesomeIcon icon="caret-down" />
                                                <> </>
                                                {t('documentPartComponent.down', 'Down')}
                                            </Button>
                                            <Button color="danger" outline onClick={() => documentPartManager.removeFor(model.id)}>
                                                <FontAwesomeIcon icon="trash" />
                                                <> </>
                                                {t('documentPartComponent.delete', 'Remove section')}
                                            </Button>
                                        </ButtonGroup>
                                    </FormButtons>
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="contents">{t("documentPartComponent.contents", "Secton contents")}</Label>
                            <HtmlEditor onChange={(e) => change({ contentsHtml: e })} value={model?.contentsHtml ?? ""}></HtmlEditor>
                        </FormGroup>

                        <hr />

                        <FormGroup>
                            <Label htmlFor="subSections">
                                {
                                    t('editDocument.subSections', 'Sub-Sections')
                                }
                            </Label>

                            {
                                /* To keep the component performing acceptably, only render children for expanded parents */
                                isOpen ? (
                                    <div>
                                        {
                                            orderedSubItems.map(item => {
                                                return (
                                                    <DocumentPartComponent key={item.id}
                                                        documentId={documentId}
                                                        model={item}
                                                        change={changes => documentPartManager.changeFor(item.id, changes)}
                                                        orderedItems={orderedItems}
                                                        documentPartManager={documentPartManager}

                                                        moveUp={() => movePartUp(item.id)} canMoveUp={canMovePartUp(item.id)}
                                                        moveDown={() => movePartDown(item.id)} canMoveDown={canMovePartDown(item.id)}
                                                        isOpenInitially={!!documentPartManager.added.find(it => it.id === item.id)}

                                                        validate={validate}
                                                        validationErrors={validationErrors}
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                ): null
                            }

                            <Button color="primary" outline onClick={() => documentPartManager.addFor({
                                ...documentPartDefaultValues(),
                                documentId: documentId,
                                parentDocumentPartId: model?.id
                            })}>
                                {
                                    !!model.heading ?
                                        t('editDocument.addSubSection.withHeading', ' Add sub-section to "{{heading}}"', { heading: model.heading,  })
                                        : t('editDocument.addSubSection.noHeading', ' Add sub-section')
                                }
                            </Button>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </Card>
        </>
    )

}