import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Input, InputGroup } from 'reactstrap';

export interface AudienceJobTitlesProps {
	jobTitlesString: string,
	changeJobTitleString: (val: string) => void
}

export const AudienceJobTitles = (props: AudienceJobTitlesProps) => {

	const {
		changeJobTitleString,
		jobTitlesString
	} = props;

	const [array, setArray] = React.useState<Array<string>>(jobTitlesString.split(';').map(i => i.trim()));

	const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		let newArray = [];

		let isNew = index > array.length;

		if (isNew) {
			newArray = [...array];
			newArray.push(e.currentTarget.value);
			setArray(newArray);
			return;
		}

		for (let i = 0; i < array.length; ++i) {
			if (i === index) {
				//This is the item we are updating
				newArray.push(e.currentTarget.value);
				continue;
			}

			newArray.push(array[i]);
		}

		setArray(newArray);

		let arrayAsString = newArray
			.filter(item => !!item)
			.reduce((previousValue, currentValue, index, arra) => previousValue + "; " + currentValue);
		changeJobTitleString(arrayAsString);
	}, [setArray, array, changeJobTitleString]);

	const increaseArray = React.useCallback(() => {
		let newArray = [...array];
		newArray.push('');
		setArray(newArray);
	}, [array, setArray]);

	const removeItem = React.useCallback((index: number) => {
		let newArray = [];

		for (let i = 0; i < array.length; ++i) {
			if (i === index) {
				continue;
			}

			newArray.push(array[i]);
		}

		setArray(newArray);

		changeJobTitleString(
			newArray
				.filter(item => !!item)
				.reduce((prev, current, index, arr) => `${prev}; ${current}`)
		);
	}, [array, setArray, changeJobTitleString]);

	return (
		<div>
			{
				array.map((item, index) => {
					return (
						<div style={{ paddingBottom: 5 }}>
							<InputGroup>
								<Input name="jobTitles" type="text" value={item} onChange={(e) => onChange(e, index)} />
								<Button color="danger" onClick={(e) => removeItem(index)}>
									<FontAwesomeIcon icon="trash" />
								</Button>
							</InputGroup>
						</div>
					)
				})
			}

			<div>
				<Button onClick={(e) => increaseArray()} color="primary">
					<FontAwesomeIcon icon="plus" />
				</Button>
			</div>
		</div>
	);
};