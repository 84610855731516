import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, Col, Nav, NavItem, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { generatePrettyUrl } from "../../../utilities/generatePrettyContentUrl";
import { NavLinkTrackActive } from "../../shared/navLinkTrackActive/NavLinkTrackActive";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./tagFilterAndSearch.scss";

export interface TagFilterAndSearchProps {
    tags: Array<{ id: string, name: string, }>,
    tagLinkPrefix: string,
    staticLinksBefore?: Array<TagFilterAndSearchStaticLink>,
    staticLinksAfter?: Array<TagFilterAndSearchStaticLink>,

    searchText: string,
    setSearchText: (value: string) => void,
}

export interface TagFilterAndSearchStaticLink {
    text: string,
    url: string,
}

/**
 * Navigation bar for navigating between tag categories or searching.
 */
export const TagFilterAndSearch = (props: TagFilterAndSearchProps) => {
    const {
        tags,
        tagLinkPrefix,
        staticLinksBefore,
        staticLinksAfter,
        searchText,
        setSearchText,
    } = props;

    const { t } = useTranslation();

    // Toggle the visiblity of the search bar.
    const [_searchVisible, toggleSearchVisible] = useToggleState(false);
    const searchVisible = _searchVisible || searchText; // Auto treat the search as visible if we have search text.

    return (
        <div className="tag-filter-and-search">
            <Row>
                <Col>
                    <Nav pills>
                        {/* Any static links to go before */}
                        {
                            !!staticLinksBefore ? (
                                staticLinksBefore?.map((link, index) => (
                                    <NavItem key={index}>
                                        <NavLinkTrackActive to={link.url} end>
                                            {link.text}
                                        </NavLinkTrackActive>
                                    </NavItem>
                                ))
                            ) : null
                        }

                        {/* Followed by the tags. */}
                        {
                            tags?.map(tag => (
                                <NavItem key={tag.id}>
                                    <NavLinkTrackActive to={generatePrettyUrl(tagLinkPrefix, tag)} end>
                                        {tag.name}
                                    </NavLinkTrackActive>
                                </NavItem>
                            ))
                        }

                        {/* Then any static links to go after */}
                        {
                            !!staticLinksAfter ? (
                                staticLinksAfter?.map((link, index) => (
                                    <NavItem key={index}>
                                        <NavLinkTrackActive to={link.url} end>
                                            {link.text}
                                        </NavLinkTrackActive>
                                    </NavItem>
                                ))
                            ) : null
                        }

                    </Nav>
                </Col>
                <Col xs="auto">
                    <Button
                        color={searchVisible ? 'primary' : 'transparent' /* transparent isn't an actual color value, but passing it has the desired effect and is what we are going for logically so we've used this fake value. */}
                        onClick={() => {
                            if (searchVisible) {
                                setSearchText('');
                                toggleSearchVisible(false);
                            } else {
                                toggleSearchVisible(true);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon="search" />
                        <span className="visually-hidden">
                            {t('common.search', 'Search')}
                        </span>
                    </Button>
                </Col>
            </Row>
            {
                searchVisible ? (
                    <div className="mt-1">
                        <SearchInput value={searchText} onChange={e => setSearchText(e.currentTarget.value)} />
                    </div>
                ): null
            }
        </div>
        );
};