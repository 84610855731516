import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavTitle>
                    {t('', 'Content Management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/news-items">
                        <FontAwesomeIcon icon="newspaper" className="nav-icon" />
                        {t('administrationNavigation.newsItems', 'News')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/external-sites">
                        <FontAwesomeIcon icon="link" className="nav-icon" />
                        {t('administrationNavigation.externalSites', 'External sites (links)')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/documents">
                        <FontAwesomeIcon icon="file" className="nav-icon" />
                        {t('administrationNavigation.documents', 'Documents')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/forms">
                        <FontAwesomeIcon icon="pen-to-square" className="nav-icon" />
                        {t('administrationNavigation.forms', 'Forms')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/form-submissions-report">
                        <FontAwesomeIcon icon="gauge" className="nav-icon" />
                        {t('administrationNavigation.formSubmissionsReport', 'Form submissions report')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administrationNavigation.lookupsTitle', 'Lookups management')}
                </NavTitle>

                <NavItem>
                    <NavLinkTrackActive to="/administration/audiences">
                        <FontAwesomeIcon icon="masks-theater" className="nav-icon" />
                        {t('administrationNavigation.audiences', 'Audiences')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/tags">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('administrationNavigation.tags', 'Tags')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administrationNavigation.usersTitle', 'User management')}
                </NavTitle>

                <NavItem>
                    <NavLinkTrackActive to="/administration/users">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        {t('administrationNavigation.administrators', 'Users')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/usersWithoutAudiences">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        {t('administrationNavigation.usersWithoutAudiences', 'Users without audiences')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
