import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateDocumentMutation, CreateDocumentMutationVariables, DocumentCreateInput } from '../generated/graphql';
import { documentFieldsFragment } from '../models/Document';

/**
 * Get a callback to create a Profile in the store.
 */
export function useCreateDocumentMutation(): [(model: DocumentCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
        gql`
            mutation createDocumentMutation ($model: DocumentCreateInput!) {
                createDocument(model: $model) {
                    ...documentFields
                }
            }

            ${documentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: DocumentCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
