import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { userRoutes } from "../components/users/routes";
import { PageNotFound } from "../components/pageNotFound/PageNotFound";
import { newsItemsAdministrationRoutes } from "../components/newsItems/administration/routes";
import { newsItemsRoutes } from "../components/newsItems/routes";
import { externalSiteAdministrationRoutes } from "../components/externalSites/administration/routes";
import { externalSitesRoutes } from "../components/externalSites/routes";
import { documentAdministrationRoutes } from "../components/documents/administration/routes";
import { documentRoutes } from "../components/documents/routes";
import { formsAdministrationRoutes } from "../components/forms/administration/routes";
import { formsRoutes } from '../components/forms/routes';
import { formSubmissionsReportRoutes } from "../components/formSubmissionsReport/routes";

/**
 * All routes used by the application.
 */
export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,

    ...newsItemsRoutes,
    ...externalSitesRoutes,
    ...documentRoutes,

    ...administrationRoutes,
    ...newsItemsAdministrationRoutes,
    ...externalSiteAdministrationRoutes,
    ...documentAdministrationRoutes,
    ...formsAdministrationRoutes,
    ...userRoutes,
    ...formsRoutes,

    ...formSubmissionsReportRoutes,

    { path: '*', element: <PageNotFound /> }
];

/**
 * Routes used to populate the sidebar.
 */
export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
    { path: '*', element: <></>}
];