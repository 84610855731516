import * as React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Button, Col, Row } from "reactstrap";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";

/**
 * Complete the SAML2 login/logout process.
 */
export const Saml2Complete = () => {
    const { t } = useTranslation();

    const { error, redirectUrl, debugRequestXml } = useSearchParams();
    

    // If we have a signOnUrl, do a full page redirect for the user to that URL.
    React.useEffect(() => {
        // If we have an error or got no redirect url do nothing.
        if (!!error || !redirectUrl) {
            return;
        }

        // If we have debugging of requests enabled, don't auto redirect, let it happen on click by the user.
        if (!!debugRequestXml) {
            return;
        }

        // Redirect to the redirectUrl.
        window.location.href = redirectUrl;
    }, [error, redirectUrl, debugRequestXml]);
 
    // Render the UI.
    //
    return (
        <>
            <MainContainer color={!!error ? "transparent" : undefined}>
                <ConditionalFragment showIf={!!error}>
                    <Alert color="danger">
                        {error}
                    </Alert>

                    <LinkContainer to="/account/login">
                        <Button color="primary">
                            {t('saml2Complete.tryAgain', 'Login another way')}
                        </Button>
                    </LinkContainer>
                </ConditionalFragment>

                <ConditionalFragment showIf={!error && !debugRequestXml}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!!debugRequestXml}>
                    <ConditionalFragment showIf={!!redirectUrl}>
                        <Row className="mt-2">
                            <Col>
                                {t('saml2Complete.debugRedirect.text', 'Because SAML request debugging is enabled, we haven\'t redirected you to "{{url}}" automatically as we would do in production so you can review the request.')}
                            </Col>
                            <Col xs="auto">
                                <Button color="primary" onClick={() => { window.location.href = redirectUrl ?? '/'; }}>
                                    {t('saml2Complete.debugRedirect.button', 'Redirect now')}
                                </Button>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    

                    <div className="mt-2" style={{ width: '100%', overflow: 'auto', backgroundColor: 'lightgray', }}>
                        {debugRequestXml}
                    </div>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
