import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateFormMutation } from './useUpdateFormMutation';
import { Form } from '../models/Form';
import { useCreateFormMutation } from './useCreateFormMutation';
import { CreateFormInput, FormUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Form in the store using either a create or update.
 */
export function useSaveFormMutation(options: SaveInStoreOptions<Form, string> = {}) {
    const [_create, createStatus] = useCreateFormMutation();
    const create = React.useCallback((model: Partial<Form>) => _create(model as CreateFormInput), [_create]);

    const [_update, updateStatus] = useUpdateFormMutation();
    const update = React.useCallback((id: string, changes: Partial<Form>) => _update(id, changes as FormUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
