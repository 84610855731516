import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AdministrationIndex } from "./AdministrationIndex";
import { AudiencesList } from "../audiences/AudiencesList";
import { TagsList } from '../tags/TagsList';
import { CreateAudience, EditAudience } from "../audiences/EditAudience";
import { audienceDefaultValues } from "../../api/main/models/Audience";
import { tagDefaultValues } from "../../api/main/models/Tag";
import { CreateTag, EditTag } from "../tags/EditTag";
import { DeleteAudience } from "../audiences/DeleteAudience";
import { DeleteTag } from "../tags/DeleteTag";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration/audiences', element: (<AudiencesList />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/audiences/new', element: (<CreateAudience isCreate={true} onCreateDefaultValues={audienceDefaultValues} />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/audiences/edit/:id', element: (<EditAudience />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/audiences/delete/:id', element: (<DeleteAudience />), authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration/tags', element: (<TagsList />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/new', element: (<CreateTag isCreate={true} onCreateDefaultValues={tagDefaultValues} />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/edit/:id', element: (<EditTag />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/tags/delete/:id', element: (<DeleteTag />), authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration', element: <AdministrationIndex />, authorize: true, requireRole: IdentityRoles.Administration },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration/*', element: (<AdministrationNavigation />), authorize: true, requireRole: IdentityRoles.Administration },
];
