import { TFunction } from "i18next";

/**
 * Groups for the DisplayOrder field rather than have people do micro-management of the display order, as with multiple audiences that
 * could get complex fast.
 */
export enum DisplayOrderGroups {
    GoldGroup = '10',
    SilverGroup = '20',
    BronzeGroup = '30',
    StandardGroup = '200',
}

/**
 * Return the display text for a DisplayOrderGroups or the DisplayOrder field in general.
 * @param t
 */
export function displayOrderGroupsDisplayName(value: DisplayOrderGroups, t: TFunction): string {
    switch (value) {
        case DisplayOrderGroups.GoldGroup: return t('displayOrderGroupsDisplayName.goldGroup', 'Gold');
        case DisplayOrderGroups.SilverGroup: return t('displayOrderGroupsDisplayName.silverGroup', 'Silver');
        case DisplayOrderGroups.BronzeGroup: return t('displayOrderGroupsDisplayName.bronzeGroup', 'Bronze');
        case DisplayOrderGroups.StandardGroup: return t('displayOrderGroupsDisplayName.standardGroup', 'Standard');
    }
}
