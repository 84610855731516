import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateDocumentPartMutation } from './useUpdateDocumentPartMutation';
import { DocumentPart } from '../models/DocumentPart';
import { useCreateDocumentPartMutation } from './useCreateDocumentPartMutation';
import { DocumentPartCreateInput, DocumentPartUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a DocumentPart in the store using either a create or update.
 */
export function useSaveDocumentPartMutation(options: SaveInStoreOptions<DocumentPart, string> = {}) {
    const [_create, createStatus] = useCreateDocumentPartMutation();
    const create = React.useCallback((model: Partial<DocumentPart>) => _create(model as DocumentPartCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateDocumentPartMutation();
    const update = React.useCallback((id: string, changes: Partial<DocumentPart>) => _update(id, changes as DocumentPartUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
