import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateTagLinkMutation } from './useUpdateTagLinkMutation';
import { Profile } from '../models/Profile';
import { useCreateTagLinkMutation } from './useCreateTagLinkMutation';
import { TagLinkCreateInput, TagLinkUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Profile in the store using either a create or update.
 */
export function useSaveTagLinkMutation(options: SaveInStoreOptions<Profile, string> = {}) {
    const [_create, createStatus] = useCreateTagLinkMutation();
    const create = React.useCallback((model: Partial<Profile>) => _create(model as TagLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateTagLinkMutation();
    const update = React.useCallback((id: string, changes: Partial<Profile>) => _update(id, changes as TagLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
