import { ApiFetch } from "apifetch-json";

/**
 * Class that provides a service for managing an account on the server.
 * 
 * NOTE: not to be confused with AuthoriazationService which manages the authorised state of the current user.
 * 
 * The login() and logout() method here are usually invoked through components used by AuthorizationService's routing to share the authorisation state with the server.
 */
export class AccountService {
    api: ApiFetch;

    constructor(api?: ApiFetch) {
        this.api = api ?? new ApiFetch();
    }

    /**
     * Attempt to login to the account with an email and password.
     */
    loginWithPassword = async (request: LoginWithPasswordRequest, returnUrl: string = ''): Promise<LoginResult> => {
        const result = await this.api.post<LoginResult>(
            `/api/account/login?returnUrl=${encodeURIComponent(returnUrl ?? '')}`,
            request,
            {
                credentials: 'include',
            });

        return result;
    }

    /**
     * Attempt to login to the account as a driver.
     */
    loginAsDriver = async (request: LoginAsDriverRequest, returnUrl: string = ''): Promise<LoginResult> => {
        const result = await this.api.post<LoginResult>(
            `/api/account/driverLogin?returnUrl=${encodeURIComponent(returnUrl ?? '')}`,
            request,
            {
                credentials: 'include',
            });

        return result;
    }

    loginAsEngineer = async (request: LoginAsDriverRequest, returnUrl: string = ''): Promise<LoginResult> => {
        const result = await this.api.post<LoginResult>(
            `/api/account/engineerLogin?returnUrl=${encodeURIComponent(returnUrl ?? '')}`,
            request,
            {
                credentials: 'include',
            });

        return result;
    }

    /**
     * Register the user with a new password based account.
     */
    register = async (request: RegisterRequest, returnUrl: string = ''): Promise<RegisterResult> => {
        const result = await this.api.post<RegisterResult>(
            `/api/account/register?returnUrl=${encodeURIComponent(returnUrl || defaultRegisterReturnUrl)}`,
            request,
            {
                credentials: 'include'
            });

        return result;
    }

    /**
     * Get a list of all external authentication schemes registered for use with accounts on this server.
     */
    getExternalAuthenticationSchemes = async (): Promise<Array<ExternalAuthenticationScheme>> => {
        const result = await this.api.get<Array<ExternalAuthenticationScheme>>('/api/account/getExternalAuthenticationSchemes');
        return result;
    }

    /**
     * Start the process of logging in with an external provider.
     */
    startExternalLogin = (provider: string, returnUrl: string = '') => {
        // We want to post the whole page to the action as it needs to return a redirect.  To acheive this we create and post a form.
        let form = document.createElement('form');
        form.action = `/api/account/startExternalLogin?provider=${encodeURIComponent(provider)}&returnUrl=${encodeURIComponent(returnUrl ?? '')}`;
        form.method = 'post';
        document.body.append(form);
        form.submit();
    }

    /**
     * Complete the external login process.
     */
    completeExternalLogin = async (returnUrl: string = '', remoteError: string = '') => {
        const result = await this.api.get<CompleteExternalLoginResult>(`/api/account/completeExternalLogin?returnUrl=${encodeURIComponent(returnUrl ?? '')}&remoteError=${encodeURIComponent(remoteError ?? '')}`);
        return result;
    }

    /**
     * Creates an account linked to the current external login with the additional details passed in.
     */
    createAccountForExternalLogin = async (model: CreateExternalLoginAccountDetails, returnUrl: string = ''): Promise<RegisterResult> => {
        const result = await this.api.post<RegisterResult>(
            `/api/account/createAccountForExternalLogin?returnUrl=${encodeURIComponent(returnUrl ?? '')}`,
            model,
            {
                credentials: 'include'
            });
        return result;
    }

    /**
     * Send a password reset email.
     */
    sendPasswordResetEmail = async (email: string) => {
        const result = await this.api.post<boolean>(`/api/account/sendPasswordResetEmail`, { email: email });
        return result;
    }

    /**
     * Reset password (using an emailed code as varification).
     */
    resetPassword = async (model: ResetPasswordRequest) => {
        const result = await this.api.post<boolean>(`/api/account/resetPassword`, model);
        return result;
    }

    /**
     * Confirm email (using an emailed code as varification).
     */
    confirmEmail = async (model: ConfirmEmailRequest) => {
        const result = await this.api.post<boolean>(`/api/account/confirmEmail`, model);
        return result;
    }

    /**
     * Confirm email change (using an emailed code as varification).
     */
    confirmEmailChange = async (model: ConfirmEmailChangeRequest) => {
        const result = await this.api.post<boolean>(
            `/api/account/confirmEmailChange`,
            model,
            {
                credentials: 'include'
            });
        return result;
    }

    /**
     * Returns the IdentityRoles names of the current user.
     */
    currentUserRoles = async (): Promise<CurrentUserRolesResult> => {
        const result = await this.api.get<CurrentUserRolesResult>(
            '/api/account/currentUserRoles',
            {
                credentials: 'include'
            });

        return result;
    }

    /**
     * Change password of the logged in user.
     */
    changePassword = async (model: ChangePasswordRequest) => {
        const result = await this.api.post<boolean>(
            `/api/account/changePassword`,
            model,
            {
                credentials: 'include'
            });
        return result;
    }

    /**
     * Change email of the logged in user.
     */
    changeEmail = async (model: ChangeEmailRequest) => {
        const result = await this.api.post<boolean>(
            `/api/account/changeEmail`,
            model,
            {
                credentials: 'include'
            });
        return result;
    }

    /**
     * Resent the email verification email.
     */
    resendConfirmEmail = async (email: string) => {
        const result = await this.api.post<boolean>(
            `/api/account/resendConfirmEmail`,
            { email: email }
        );
        return result;
    }


    /**
     * Change email of the logged in user.
     */
    invite = async (model: InviteRequest) => {
        const result = await this.api.post<InviteResult>(
            `/api/account/invite`,
            model,
            {
                credentials: 'include'
            }
        );
        return result;
    }

    /**
     * Resent the invite email.
     */
    resendInviteEmail = async (email: string) => {
        const result = await this.api.post<boolean>(
            `/api/account/resendInviteEmail`,
            { email: email }
        );
        return result;
    }

    /**
     * Confirm an invite and set a password (will also perform a login).
     */
    confirmInvite = async (model: ConfirmInviteRequest, returnUrl: string = '') => {
        const result = await this.api.post<RegisterResult>(
            `/api/account/confirmInvite?returnUrl=${encodeURIComponent(returnUrl || defaultRegisterReturnUrl)}`,
            model
        );
        return result;
    }

    /**
     * Change email of another user (as an administrator)
     */
    changeAccountEmail = async (model: ChangeAccountEmailRequest) => {
        const result = await this.api.post<boolean>(
            `/api/account/changeAccountEmail`,
            model,
            {
                credentials: 'include'
            });
        return result;
    }

    /**
     * Lockout a user.
     */
    lockout = async (model: LockoutRequest) => {
        const result = await this.api.post<boolean>(
            `/api/account/lockout`,
            model,
            {
                credentials: 'include'
            }
        );
        return result;
    }

    /**
     * Returns the SAML2 configuration if it is enabled.
     * @returns
     */
    getSamlConfiguration = async () => {
        const result = await this.api.get<SamlConfiguration>(
            `/saml2/configuration`,
            {
                credentials: 'include'
            }
        );
        return result;
    }

    /**
     * Checks the connection to the server (usually on first page load) to see if we need to reauthenticate.
     */
    isAuthenticatedOnServer = async () => {
        const result = await this.api.get<boolean>(
            `/api/account/isAuthenticated`,
            {
                credentials: 'include'
            }
        );
        return result;
    }
}

const defaultRegisterReturnUrl = `/authentication/login?returnUrl=${encodeURIComponent(window.origin + '/')}`;

export interface LoginWithPasswordRequest
{
    email: string,
    password: string,
    rememberMe: boolean,
}

export interface LoginAsDriverRequest {
    surname: string,
    secret: string,
    rememberMe: boolean,
}

export interface LoginResult {
    succeeded: boolean,
    requiresTwoFactor: boolean,
    requiresEmailConfirmation: boolean,
    returnUrl: string
}

export interface RegisterResult extends LoginResult {
    userId: string,
}

export interface ExternalAuthenticationScheme {
    name: string,
    displayName: string
}

export interface Claim {
    type: string,
    properties: { [id: string]: string },
    originalIssuer: string,
    issuer: string,
    valueType: string,
    value: string,
}

export interface CompleteExternalLoginResult {
    isNewUser: boolean,
    returnUrl: string,
    loginProvider: string,
    claims: Array<Claim>,
}

export interface CreateExternalLoginAccountDetails {
    email: string
}

export interface RegisterRequest {
    email: string,
    password: string,
}

export interface ResetPasswordRequest {
    userId: string,
    code: string,
    password: string,
}

export interface ConfirmEmailRequest {
    userId: string,
    code: string,
}

export interface CurrentUserRolesResult {
    userId: string,
    roles: Array<string>,
}

export interface ChangePasswordRequest {
    currentPassword: string,
    newPassword: string,
}

export interface ChangeEmailRequest {
    newEmail: string,
}

export interface ConfirmEmailChangeRequest {
    userId: string,
    code: string,
    email: string,
}

export interface InviteRequest {
    email: string,
    sendEmail: boolean,
}

export interface InviteResult {
    userId: string,
}


export interface ChangeAccountEmailRequest {
    currentEmail: string,
    newEmail: string,
}

export interface ConfirmInviteRequest {
    userId: string,
    code: string,
    password: string,
}

export interface LockoutRequest {
    email: string,
    endDate?: string,
    archive?: boolean,
}

export interface SamlConfiguration {
    enabled: boolean,
    version: string,
    signOnRedirectUrl: string,
}
