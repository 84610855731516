import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Tag } from "../../api/main/generated/graphql"
import { tagDefaultValues } from "../../api/main/models/Tag";
import { useSaveTagMutation } from "../../api/main/tags/useSaveTagMutation";
import { useTag } from "../../api/main/tags/useTag";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";

export interface EditTagProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Tag>
}

export const CreateTag = (props: EditTagProps) => (<EditTag isCreate={true} {...props}></EditTag>)

export const EditTag = (props: EditTagProps) => {

    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();

    const { isCreate, onCreateDefaultValues } = props;
    const { t } = useTranslation();
    const { data: { model: storeModel }, isLoading, errors } = useTag(id);

    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...tagDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);

    const [save, { errors: saveErrors, isExecuting: isSaving }] = useSaveTagMutation();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('tagEdit.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { errors: saveFormErrors }] = useAsyncCallback(async () => {
        await save(id ?? '', changes, isCreate ?? false);
        navigate(-1);
    }, [model, isCreate, save]);


    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editTag.createHeading.default', 'Add tag')
                                    : t('editTag.editHeading.default', 'Edit tag')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[errors, saveFormErrors, saveErrors, ]} />
                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editAudience.name', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}