import { RouteEntry } from "../../shared/routes";
import { Documents } from "./Documents";
import { ViewDocument } from "./viewDocument/ViewDocument";

export const documentRoutes: Array<RouteEntry> = [
    { path: '/documents', element: <Documents />, authorize: true },

    // View Documents by tag.
    { path: '/documents/category/:ssoFriendly/:tagId', element: <Documents />, authorize: true },
    { path: '/documents/category/:tagId', element: <Documents />, authorize: true },

    // View Document with a basic, and more SSO friendly url.
    { path: '/documents/:ssoFriendly/:id', element: <ViewDocument />, authorize: true },
    { path: '/documents/:id', element: <ViewDocument />, authorize: true },

    // View Document in a specific section (document part) with a basic, and more SSO friendly url.
    { path: '/documents/:ssoFriendly/:id/:ssoFriendlySection/:documentPartId', element: <ViewDocument />, authorize: true },
    { path: '/documents/:ssoFriendly/:id/:documentPartId', element: <ViewDocument />, authorize: true },
];
