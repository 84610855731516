import { RouteEntry } from "../../../shared/routes";
import { DeleteNewsItem } from "./DeleteNewsItem";
import { CreateNewsItem, EditNewsItem } from "./EditNewsItem";
import { NewsItemsList } from "./ListNewsItems";

export const newsItemsAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/news-items', element: <NewsItemsList />, authorize: true },
    { path: '/administration/news-items/new', element: <CreateNewsItem isCreate={ true } />, authorize: true },
    { path: '/administration/news-items/edit/:id', element: <EditNewsItem />, authorize: true },
    { path: '/administration/news-items/delete/:id', element: <DeleteNewsItem />, authorize: true },

];
