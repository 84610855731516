import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateFormSubmissionMutation } from './useUpdateFormSubmissionMutation';
import { FormSubmission } from '../models/FormSubmission';
import { useCreateFormSubmissionMutation } from './useCreateFormSubmissionMutation';
import { CreateFormSubmissionInput, FormSubmissionUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Form in the store using either a create or update.
 */
export function useSaveFormSubmissionMutation(options: SaveInStoreOptions<FormSubmission, string> = {}) {
    const [_create, createStatus] = useCreateFormSubmissionMutation();
    const create = React.useCallback((model: Partial<FormSubmission>) => _create(model as CreateFormSubmissionInput), [_create]);

    const [_update, updateStatus] = useUpdateFormSubmissionMutation();
    const update = React.useCallback((id: string, changes: Partial<FormSubmission>) => _update(id, changes as FormSubmissionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
