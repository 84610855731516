import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateExternalSiteMutation } from './useUpdateExternalSiteMutation';
import { ExternalSite } from '../models/ExternalSite';
import { useCreateExternalSiteMutation } from './useCreateExternalSiteMutation';
import { ExternalSiteCreateInput, ExternalSiteUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ExternalSite in the store using either a create or update.
 */
export function useSaveExternalSiteMutation(options: SaveInStoreOptions<ExternalSite, string> = {}) {
    const [_create, createStatus] = useCreateExternalSiteMutation();
    const create = React.useCallback((model: Partial<ExternalSite>) => _create(model as ExternalSiteCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateExternalSiteMutation();
    const update = React.useCallback((id: string, changes: Partial<ExternalSite>) => _update(id, changes as ExternalSiteUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
