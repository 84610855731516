import { AppRoutes } from './components/shared/appRoutes/AppRoutes';
import { createServiceProvider } from './configure/configureServices';
import authService from './api/api-authorization/AuthorizeService';
import { purgeRecoilPersist } from './configure/recoilPersistConfig';
import { apolloCachePersistor, apolloClient } from './configure/configureApollo';
import { configurei18n } from './configure/i18n';
import { configureErrorMessages } from './configure/errorMessageConfig';
import { initializeIcons } from './configure/iconsConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ServiceProviderContext } from 'inject-typesafe-react';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { LoadingIndicator } from './components/shared/loadingIndicator/LoadingIndicator';
import { I18nextProvider } from 'react-i18next';
import { Layout } from './components/layout/Layout';
import { appRoutes } from './configure/routeConfig';
import { Suspense } from 'react';
import { appDetails } from './configure/appDetails';


// Configure services.
const serviceProvider = createServiceProvider();


// When the authorization state changes, clear the right parts of our client side caches.
authService.subscribe(() => {
    purgeRecoilPersist();
    apolloCachePersistor.purge();
});

// If we are returning to the page and we no longer have authorisation with the server (e.g. the cookie has expired), also logout of the client.
const accountService = serviceProvider.createScope().services().accountService();
accountService.isAuthenticatedOnServer().then(isServerAuthenticated => {
    if (!isServerAuthenticated) {
        authService.isAuthenticated().then(isClientAuthenticated => {
            if (isClientAuthenticated) {
                authService.signOut({});
            }
        });
    }
});


// Configure icons.
initializeIcons();

// Configure i18n
const i18n = configurei18n();

// Configure server and 3rd party error messages to make them friendly and translatable.
configureErrorMessages();



/**
 * App component.
 */
export const App = () => {
    return (
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <RecoilRoot>
                    <ServiceProviderContext.Provider value={serviceProvider}>
                        <ApolloProvider client={apolloClient}>
                            <Suspense fallback={<LoadingIndicator fullWidth={true} />}>
                                <Layout appRoutes={appRoutes}>
                                    <Helmet defaultTitle={i18n.t('app.defaultTitle', '{{name}}', { name: appDetails.name })} titleTemplate={i18n.t('app.titleTemplate', '%s | {{name}}', { name: appDetails.name })}>
                                    </Helmet>
                                    <AppRoutes routes={appRoutes} />
                                </Layout>
                            </Suspense>
                        </ApolloProvider>
                    </ServiceProviderContext.Provider>
                </RecoilRoot>
            </I18nextProvider>
        </HelmetProvider>
    );
};
